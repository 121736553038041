<template>
  <v-container fluid v-if="employee">
    <v-row>
      <v-col cols="12" sm="10">
        <h1 class="text-h4">{{ employee.name }}</h1>
      </v-col>
    </v-row>
    <v-row class="mb-4">
      <v-col cols="auto py-0">
        <v-checkbox
          class="mr-4"
          v-model="employee.is_plannable"
          label="Relevant für Planung"
          color="primary"
          disabled
        ></v-checkbox>
      </v-col>
      <v-col cols="auto py-0">
        <v-checkbox
          class="mr-4"
          v-model="employee.is_manager"
          label="Manager"
          color="primary"
          disabled
        ></v-checkbox>
      </v-col>
      <v-col cols="auto py-0">
        <v-checkbox
          class="mr-4"
          v-model="employee.is_backoffice"
          label="Backoffice"
          color="primary"
          disabled
        ></v-checkbox>
      </v-col>
      <v-col cols="auto py-0">
        <v-checkbox
          class="mr-4"
          v-model="employee.is_support"
          label="Support"
          color="primary"
          disabled
        ></v-checkbox>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="4">
        <v-card outlined>
          <v-card-title class="card-title-grey"> Personalien </v-card-title>
          <v-card-text>
            <v-row>
              <v-col cols="auto" v-if="employee.avatar">
                <img :src="employee.avatar" class="avatar" />
              </v-col>
              <v-col>
                <v-text-field
                  v-model="employee.name"
                  label="Name"
                  type="text"
                  :maxlength="255"
                  dense
                  outlined
                  readonly
                ></v-text-field>
                <v-text-field
                  v-model="employee.email"
                  label="E-Mail"
                  type="text"
                  :maxlength="255"
                  dense
                  outlined
                  readonly
                ></v-text-field>
                <v-text-field
                  v-model="employee.acronym"
                  label="Kürzel"
                  type="text"
                  :maxlength="255"
                  dense
                  outlined
                  readonly
                ></v-text-field>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" md="8">
        <v-card outlined>
          <v-card-title class="card-title-grey">Aufgaben</v-card-title>
          <v-card-text>
            <template v-if="employee.tasks.length === 0">
              Derzeit keine vorhanden
            </template>
            <v-simple-table v-else class="day-details-table">
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left">Titel</th>
                    <th class="text-left">Projekt</th>
                    <th class="text-left">Start</th>
                    <th class="text-left">Ende</th>
                    <th class="text-left">Erfasst (Std)</th>
                    <th class="text-left">Geplant (Std)</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="task in employee.tasks" :key="task.id">
                    <td>
                      <strong>{{ task.name }}</strong>
                    </td>
                    <td>
                      <router-link :to="`/projects/${task.project_id}`">
                        {{ task.project_name }}
                      </router-link>
                    </td>
                    <td>{{ task.starts_at | formatDate }}</td>
                    <td>{{ task.ends_at | formatDate }}</td>
                    <td>{{ task.time_used | formatHours }}</td>
                    <td>{{ task.time_expected | formatHours }}</td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapMutations } from "vuex";

export default {
  components: {},

  data: () => ({
    employee: null,
    init: false,
  }),

  computed: {
    employeeId() {
      return this.$route.params.id;
    },
  },

  watch: {
    employee: {
      deep: true,
      handler(value) {
        if (this.init) {
          this.updateFlags(value);
        }
        this.init = true;
      },
    },
  },

  created() {
    this.fetchEmployee();
  },

  methods: {
    ...mapMutations("snackbar", ["showErrorSnackbar", "showSuccessSnackbar"]),

    fetchEmployee() {
      this.$api.http
        .get(`api/employees/${this.employeeId}`)
        .then((response) => {
          this.employee = response.data;
        });
    },

    updateFlags(employee) {
      this.$api.http
        .put(`api/employees/${this.employeeId}`, {
          is_plannable: employee.is_plannable,
          is_manager: employee.is_manager,
          is_backoffice: employee.is_backoffice,
        })
        .then(() => this.$toast.open("Erfolgreich geändert."));
    },
  },
};
</script>

<style lang="scss" scoped>
.card-title-grey {
  background-color: #eee;
  padding: 10px 15px;
  font-size: 16px;
  margin-bottom: 20px;
}

.avatar {
  width: 173px;
}

.day-details-table {
  a {
    text-decoration: none;
    font-weight: bold;
    color: var(--v-accent-base);
  }
}
</style>
