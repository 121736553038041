<template>
  <div class="resource-planning">
    <v-app-bar color="primary" dark flat class="rp-app-bar">
      <tooltip-button
        icon="close"
        text="Schliessen"
        color="primary lighten-1"
        @click="closeAndReturn()"
        classNames="elevation-0 mr-3"
        position="bottom"
        size="small"
      />
      <v-toolbar-title>Ressourcenplanung</v-toolbar-title>
      <v-spacer></v-spacer>
      <div style="margin-top: 2px" class="mr-3">
        <date-picker
          v-model="filterStart"
          label="Von"
          outlined
          dense
          hide-details
          :allowed-dates="allowedDatesFrom"
        />
      </div>
      <div style="margin-top: 2px" class="mr-3">
        <date-picker
          v-model="filterEnd"
          label="Bis"
          outlined
          dense
          hide-details
          :allowed-dates="allowedDatesTo"
        />
      </div>
      <div style="margin-top: 2px" class="mr-3">
        <v-btn
          elevation="0"
          @click="setDateRange()"
          :color="idleDateRange ? 'accent' : 'primary'"
          :disabled="!idleDateRange"
        >
          OK
        </v-btn>
      </div>
      <v-spacer></v-spacer>
      <v-select
        v-model="currentView"
        :items="views"
        item-text="text"
        item-value="value"
        label="Ansicht"
        outlined
        dense
        class="mt-7 select-view"
        cols="auto"
      ></v-select>
      <tooltip-button
        :icon="showWorkloads ? 'visibility' : 'visibility_off'"
        :text="
          showWorkloads ? 'Auslastung ausblenden' : 'Auslastung einblenden'
        "
        color="primary"
        @click="toggleShowWorkloads()"
        classNames="elevation-0 ml-3"
        position="bottom"
        size="small"
      />
      <tooltip-button
        :icon="active ? 'fullscreen_exit' : 'fullscreen'"
        text="Vollbildmodus"
        color="primary"
        @click="toggleFullscreen()"
        classNames="elevation-0 ml-3"
        position="bottom"
        size="small"
      />
      <tooltip-button
        icon="filter_list"
        text="Filtereinstellungen"
        color="primary"
        @click="openFilterDialog()"
        classNames="elevation-0 ml-3"
        :disabled="hasChanges"
        position="bottom"
        size="small"
      />
      <tooltip-button
        icon="save"
        text="Speichern"
        :color="hasChanges ? 'accent' : 'primary'"
        @click="sync()"
        classNames="elevation-0 ml-3"
        :disabled="!hasChanges"
        position="bottom"
        size="small"
      />
    </v-app-bar>
    <component
      :is="ganttComponent"
      @rerender="rerender()"
      :key="componentKey"
      ref="ganttComponent"
      @hasChanges="setGanttHasChanges"
    />
    <filter-dialog ref="filterDialog" @rerender="load()" />
    <app-snackbar />
  </div>
</template>

<script>
import moment from "moment";
import { mapState, mapMutations } from "vuex";
import GanttProjects from "@/components/rp/projects/GanttProjects";
import GanttEmployees from "@/components/rp/employees/GanttEmployees";
import TooltipButton from "@/components/forms/input/TooltipButton";
import FilterDialog from "@/components/rp/dialogs/FilterDialog";
import AppSnackbar from "@/components/app/AppSnackbar";
import DatePicker from "@/components/forms/input/DatePicker";

export default {
  data: () => ({
    componentKey: 0,
    ganttComponent: null,
    hasChanges: false,

    views: [
      { text: "Projektansicht", value: "projects" },
      { text: "Mitarbeiteransicht", value: "employees" },
    ],
    idleDateRange: false,
  }),

  components: {
    TooltipButton,
    FilterDialog,
    AppSnackbar,
    DatePicker,
  },

  computed: {
    ...mapState("rp", [
      "view",
      "listExpanded",
      "periodLevel",
      "showWorkloads",
      "startDate",
      "endDate",
    ]),
    ...mapState("fullscreen", ["active"]),

    currentView: {
      get() {
        return this.view;
      },
      set(value) {
        this.setView(value);
        this.setComponent(value);
      },
    },

    filterStart: {
      get() {
        return this.startDate;
      },
      set(value) {
        this.setStartDate(value);
      },
    },

    filterEnd: {
      get() {
        return this.endDate;
      },
      set(value) {
        this.setEndDate(value);
      },
    },
  },

  watch: {
    startDate(value) {
      this.idleDateRange = true;
    },
    endDate(value) {
      this.idleDateRange = true;
    },
  },

  async beforeCreate() {
    await this.$store.commit("rp/initStore");
  },

  created() {
    this.setComponent(this.view);
    document.addEventListener(
      "keydown",
      (e) => {
        if (
          (window.navigator.platform.match("Mac") ? e.metaKey : e.ctrlKey) &&
          e.keyCode == 83
        ) {
          e.preventDefault();
          this.sync();
        }
      },
      false
    );
  },

  methods: {
    ...mapMutations("rp", [
      "setView",
      "setShowWorkloads",
      "setStartDate",
      "setEndDate",
    ]),
    ...mapMutations("fullscreen", ["toggleFullscreen"]),

    setComponent(view) {
      switch (view) {
        case "projects":
          this.ganttComponent = GanttProjects;
          break;

        case "employees":
          this.ganttComponent = GanttEmployees;
          break;
      }
      this.rerender();
    },

    closeAndReturn() {
      this.$router.push("/");
    },

    rerender() {
      this.componentKey += 1;
    },

    setDateRange() {
      this.$refs.ganttComponent.setTimeSpan(this.startDate._d, this.endDate._d);
      this.idleDateRange = false;
      this.$refs.ganttComponent.load();
    },

    load() {
      this.$refs.ganttComponent.load();
    },

    sync() {
      this.$refs.ganttComponent.sync();
    },

    setGanttHasChanges(value) {
      this.hasChanges = value;
    },

    openFilterDialog() {
      this.$refs.filterDialog.open();
    },

    toggleShowWorkloads() {
      const showWorkloads = !this.showWorkloads;
      this.setShowWorkloads(showWorkloads);
    },

    /**
     *
     * @param value
     * @returns {boolean}
     */
    allowedDatesFrom(value) {
      return moment(value, "YYYY-MM-DD").format("E") === "1";
    },

    /**
     *
     * @param value
     * @returns {boolean}
     */
    allowedDatesTo(value) {
      return moment(value, "YYYY-MM-DD").format("E") === "7";
    },
  },
};
</script>

<style lang="scss" scoped>
.resource-planning {
  height: 100%;

  .rp-app-bar {
    z-index: 10;
  }

  .select-view {
    width: 300px;
    flex: none;
  }
}
</style>
