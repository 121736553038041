<template>
  <div id="gantt-employees">
    <workloads v-if="showWorkloads" ref="workloads" />
    <div
      :class="
        showWorkloads ? 'chart-with-workloads' : 'chart-without-workloads'
      "
      ref="chart"
    />
    <task-dialog ref="taskDialog" @saved="load()" />
    <delete-dialog ref="deleteDialog" @deleted="load()" />
    <complete-dialog ref="completeDialog" @completed="load()" />
  </div>
</template>

<script>
import "@tingo-gmbh/bryntum-gantt/gantt.material.min.css";
import "../custom.locale.De";
import moment from "moment";
import { Gantt, StringHelper, TaskTooltip } from "@tingo-gmbh/bryntum-gantt";
import { mapState, mapMutations } from "vuex";
import Workloads from "@/components/rp/employees/Workloads";
import TaskDialog from "@/components/dialogs/TaskDialog";
import CompleteDialog from "@/components/dialogs/CompleteDialog";
import DeleteDialog from "@/components/dialogs/DeleteDialog";
import EmployeesModel from "@/components/rp/models/EmployeesModel";

export default {
  name: "gantt-projects",

  components: {
    Workloads,
    TaskDialog,
    DeleteDialog,
    CompleteDialog,
  },

  data: () => ({
    loaded: false,
    projectModel: null,
    ganttEngine: null,
  }),

  computed: {
    ...mapState("rp", [
      "projectStatusFilter",
      "projectTemplatesFilter",
      "projectsFilter",
      "customersFilter",
      "employeeTasksFilter",
      "employeeManagersFilter",
      "statusFilter",
      "startDate",
      "endDate",
      "scheduledFilter",
      "showWorkloads",
    ]),
  },

  watch: {
    showWorkloads(value) {
      if (value) {
        this.$refs.workloads.reload();
      }
    },
  },

  mounted() {
    this.projectModel = EmployeesModel;
    this.projectModel.listeners = {
      beforeSend: ({ params, config }) => {
        if (config.type === "load") {
          if (this.projectStatusFilter.length > 0) {
            params["_projects_status_filter"] =
              this.projectStatusFilter.join(",");
          }
          if (this.projectTemplatesFilter.length > 0) {
            params["_projects_templates_filter"] =
              this.projectTemplatesFilter.join(",");
          }
          if (this.projectsFilter.length > 0) {
            params["_projects_filter"] = this.projectsFilter.join(",");
          }
          if (this.customersFilter.length > 0) {
            params["_customers_filter"] = this.customersFilter.join(",");
          }
          if (this.employeeTasksFilter.length > 0) {
            params["_employee_tasks_filter"] =
              this.employeeTasksFilter.join(",");
          }
          if (this.employeeManagersFilter.length > 0) {
            params["_employee_managers_filter"] =
              this.employeeManagersFilter.join(",");
          }
          if (this.statusFilter) {
            params.status = this.statusFilter;
          }
          if (this.scheduledFilter) {
            params.scheduled = this.scheduledFilter;
          }
        }
      },
    };

    const ganttConfig = {
      project: this.projectModel,
      rowHeight: 60,
      startDate: this.startDate._d,
      endDate: this.endDate._d,
      features: {
        projectLines: {
          disabled: true,
        },
        dependencies: {
          disabled: true,
        },
        percentBar: {
          allowResize: false,
        },
        taskTooltip: {
          template(data) {
            const me = this,
              { taskRecord } = data;

            if (taskRecord.type !== "task") {
              return TaskTooltip.defaultConfig.template.call(me, data);
            }
            // Return the result of the feature's default template, with custom markup appended.
            const startsAt = moment(taskRecord.originalData.startDate).format(
              "DD.MM.YYYY"
            );
            const endsAt = moment(taskRecord.originalData.endDate).format(
              "DD.MM.YYYY"
            );
            return `
              <h4>${taskRecord.originalData.name}</h4>
              <table border="0" cellspacing="0" cellpadding="0" style="width:100%;">
                <tr>
                  <td>Von:</td><td style="text-align: right;padding-left:20px;">${startsAt}</td>
                </tr>
                <tr>
                  <td>Bis:</td><td style="text-align: right;padding-left:20px;">${endsAt}</td>
                </tr>
                <tr>
                  <td>Aufwand:</td><td style="text-align: right;padding-left:20px;">${taskRecord.originalData.time_expected} Stunden</td>
                </tr>
                <tr>
                  <td>Erledigt:</td><td style="text-align: right;padding-left:20px;">${taskRecord.originalData.percentDone}%</td>
                </tr>
              </table>
              `;
          },
        },
        taskMenu: {
          items: {
            add: false,
            subtask: false,
            indent: false,
            outdent: false,
            convertToMilestone: false,
            copyRow: false,
            cutRow: false,
            createTask: {
              text: "Erstellen",
              weight: 100,
              icon: "b-icon b-icon-add",
              onItem: ({ taskRecord }) => {
                this.openCreateDialog(taskRecord.employee_id);
              },
            },
            editTask: {
              text: "Bearbeiten",
              weight: 110,
              onItem: ({ taskRecord }) => {
                this.openEditDialog(
                  taskRecord.task_id,
                  taskRecord.budget_id,
                  taskRecord.project_id
                );
              },
            },
            completeTask: {
              text: "Erledigt",
              weight: 115,
              icon: "b-icon b-icon-check",
              onItem: ({ taskRecord }) => {
                this.openCompleteDialog(taskRecord.task_id, taskRecord.name);
              },
            },
            deleteTask: {
              text: "Löschen",
              weight: 120,
              onItem: ({ taskRecord }) => {
                this.openDeleteDialog(taskRecord.task_id, taskRecord.name);
              },
            },
          },
          processItems({ taskRecord, items }) {
            if (taskRecord.id.startsWith("e")) {
              items.editTask.hidden = true;
              items.deleteTask.hidden = true;
              items.completeTask.hidden = true;
            }
            if (taskRecord.id.startsWith("t")) {
              items.createTask.hidden = true;
            }
          },
        },
      },
      listeners: {
        beforeTaskEdit: ({ taskRecord, taskEdit, taskElement }) => {
          if (!taskRecord.type || taskRecord.type === "task") {
            this.openEditDialog(
              taskRecord.task_id,
              taskRecord.budget_id,
              taskRecord.project_id
            );
          }

          // Prevent built in editor
          return false;
        },
      },

      columns: [
        {
          type: "name",
          field: "name",
          text: "Mitarbeiter",
          width: 250,
          editor: false,
          sortable(el1, el2) {
            return el1.name.toLowerCase() < el2.name.toLowerCase() ? -1 : 1;
          },
        },
      ],

      taskRenderer({ taskRecord, renderData }) {
        let style = "";
        switch (taskRecord.type) {
          case "employee":
            style += "background:#697dc2;";
            break;
          case "task":
            style += "background:#f5487f;";
            break;
          default:
            style += "background:#f5487f;";
            break;
        }
        renderData.style = style;
        if (!taskRecord.type || taskRecord.type === "task") {
          return [
            {
              tag: "div",
              class: "task-name",
              html: StringHelper.encodeHtml(
                taskRecord.name +
                  (taskRecord.time_expected > 0
                    ? ` (${taskRecord.time_expected} Stunden)`
                    : "")
              ),
            },
            ...taskRecord.resources.map((resource) => ({
              tag: "img",
              src: resource.avatar,
              class: "task-avatar",
              dataset: {
                resourceId: resource.id,
              },
            })),
          ];
        } else if (taskRecord.type === "employee") {
          return [
            {
              tag: "div",
              class: "task-employee",
              html:
                moment(taskRecord.startDate).format("DD.MM.YYYY") +
                " - " +
                moment(taskRecord.endDate).format("DD.MM.YYYY"),
            },
          ];
        }
      },
    };

    const gantt = (this.ganttEngine = new Gantt(ganttConfig));
    gantt.render(this.$refs.chart);

    this.projectModel.on("hasChanges", (crud) => {
      if (this.loaded) {
        this.$emit("hasChanges", true);
      }
    });

    this.load();
  },

  beforeDestroy() {
    if (this.ganttEngine) {
      this.ganttEngine.destroy();
    }
  },

  methods: {
    ...mapMutations("snackbar", ["showSuccessSnackbar"]),

    /**
     * Load data.
     */
    load() {
      this.loaded = false;
      this.projectModel.load().then(() => {
        this.loaded = true;
        this.$refs.workloads.reload();
      });
    },

    /**
     * Sync data.
     */
    sync() {
      this.projectModel.sync().then(() => {
        this.$emit("hasChanges", false);
        this.showSuccessSnackbar("Änderungen gespeichert");
        this.$refs.workloads.reload();
      });
    },

    /**
     * Set the time span.
     */
    setTimeSpan(startDate, endDate) {
      this.ganttEngine.setTimeSpan(startDate, endDate);
    },

    /**
     * Open create dialog
     */
    openCreateDialog(employeeId) {
      this.$refs.taskDialog.open("create", null, null, null, employeeId);
    },

    /**
     * Open edit dialog
     */
    openEditDialog(taskId, budgetId, projectId) {
      this.$refs.taskDialog.open("edit", taskId, projectId, budgetId);
    },

    /**
     * Open complete dialog
     */
    openCompleteDialog(id, name) {
      this.$refs.completeDialog.open(id, name);
    },

    /**
     * Open delete dialog
     */
    openDeleteDialog(id, name) {
      this.$refs.deleteDialog.open(id, name, "tasks");
    },
  },
};
</script>

<style lang="scss">
#gantt-employees {
  height: calc(100% - 64px);

  .chart-with-workloads {
    height: calc(100% - 140px);
  }
  .chart-without-workloads {
    height: 100%;
  }

  .b-gantt-task-wrap.b-gantt-task-parent .b-gantt-task {
    max-height: 2em;
  }

  .b-sch-dayheadercell-0,
  .b-sch-dayheadercell-6 {
    background: rgb(206, 206, 206) !important;
  }

  .b-sch-dayheadercell-today {
    background: #1d2a3f !important;
    color: white;
  }

  .b-gantt-task {
    overflow: visible;
    .b-task-percent-bar-outer {
      overflow: visible;
    }
  }

  .b-gantt-task-content {
    font-size: 14px;
    overflow: visible;

    .task-employee {
      padding: 0.5em 0 1em;
    }

    .task-name {
      margin-left: 5px;
      font-size: 1.1em;
      font-weight: bold;
      overflow: hidden;
      width: 80%;
    }

    .task-avatar {
      position: absolute;
      top: 3px;
      right: 3px;
      width: 2.5em;
      height: 2.5em;
      border-radius: 100%;
      transition: transform 0.2s;
      cursor: default;

      &:hover {
        transform: scale(2);
        z-index: 1;
      }
    }
  }
}
</style>
