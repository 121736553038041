<template>
  <div
    class="workload-item"
    :class="getWorkloadItemClasses()"
    :style="{ width: 100 / total + '%' }"
    @click="showDayProjects(workload.date)"
  >
    <div
      class="workload-item-percentage"
      :style="getWorkloadItemPercentageStyle(workload.time_expected)"
    ></div>
    <div class="workload-date" v-if="workload.startOfMonth">
      <div class="workload-date-title">{{ workload.startOfMonth }}</div>
    </div>
    <div class="workload-overlay"></div>
    <div class="details-overlay">
      <div>{{ workload.date | formatDate }}</div>
      <div>{{ workload.time_expected | formatHours }} Stunden</div>
    </div>
  </div>
</template>

<script>
import moment from "moment";

export default {
  props: {
    workload: {
      required: true,
    },
    total: {
      required: true,
    },
    peakMin: {
      required: true,
    },
    peakMax: {
      required: true,
    },
  },

  methods: {
    /**
     *  Get workload classes.
     */
    getWorkloadItemClasses() {
      let percentage =
        (this.workload.time_expected / this.workload.time_available) * 100;
      let classes = ["workload-item"];
      if (this.workload.is_weekend) {
        classes.push("weekend");
      } else if (percentage > 100) {
        classes.push("crazy");
      } else if (percentage > 80) {
        classes.push("alert");
      } else if (percentage > 50) {
        classes.push("critical");
      } else {
        classes.push("normal");
      }

      if (this.workload.date === moment().format("YYYY-MM-DD")) {
        classes.push("today");
      }

      return classes.join(" ");
    },

    /**
     * Returns the appropriate styles.
     */
    getWorkloadItemPercentageStyle(timeExpected) {
      let styles = {};

      const coeff = this.peakMax > 0 ? 100 / this.peakMax : 0;
      const height = coeff * timeExpected;
      styles.height = (height > 100 ? 100 : height) + "px";

      return styles;
    },

    showDayProjects(date) {
      if (this.workload.is_weekend) {
        return;
      }
      this.$emit("showDayProjects", this.workload);
    },
  },
};
</script>

<style lang="scss" scoped>
.workload-item {
  display: inline-block;
  height: 100px;
  cursor: pointer;
  position: relative;

  &.alert {
    background: var(--v-error-darken2);
    border-left: 1px solid var(--v-error-darken1);

    .workload-item-percentage {
      position: absolute;
      width: 100%;
      height: 100px;
      bottom: 0;
      background: var(--v-error-base);
    }
  }

  &.critical {
    background: var(--v-warning-darken2);
    border-left: 1px solid var(--v-warning-darken1);

    .workload-item-percentage {
      position: absolute;
      width: 100%;
      height: 100px;
      bottom: 0;
      background: var(--v-warning-base);
    }
  }

  &.normal {
    background: #209e53;
    border-left: 1px solid #25b35d;

    .workload-item-percentage {
      position: absolute;
      width: 100%;
      height: 100px;
      bottom: 0;
      background: #3ce47f;
    }
  }

  &.crazy {
    background: var(--v-error-darken4);
    border-left: 1px solid var(--v-error-darken3);

    .workload-item-percentage {
      position: absolute;
      width: 100%;
      height: 100px;
      bottom: 0;
      background: var(--v-error-darken2);
    }
  }

  &.weekend {
    cursor: default;
    background: var(--v-primary-base);
    border-left: 1px solid var(--v-primary-darken1);
  }

  &.today::after {
    content: "";
    width: 100%;
    height: 100%;
    color: white;
    position: absolute;
    background-color: rgba(0, 0, 0, 0.2);
  }

  .workload-date {
    position: absolute;
    min-width: 140px;
    z-index: 10;
    left: -1px;
    top: -30px;

    .workload-date-title {
      border-left: 1px solid rgba(255, 255, 255, 0.658);
      padding: 5px 8px;
      font-size: 15px;
      line-height: 20px;
      color: #fff;
    }
  }

  .workload-overlay {
    display: block;
    position: absolute;
    height: 100px;
    z-index: 7;
    width: 100%;
  }

  .details-overlay {
    display: none;
    position: absolute;
    background: #000000c0;
    color: white;
    top: -52px;
    padding: 6px 8px;
    font-size: 13px;
    z-index: 20;
  }

  &:hover:not(.weekend) {
    .workload-overlay {
      background-color: #00000023;
      display: block;
    }

    .details-overlay {
      display: block;
    }
  }
}
</style>
