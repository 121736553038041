import Vue from "vue";

Vue.filter("formatCurrency", function (value) {
  let parsedNumber = 0;

  if ((parsedNumber = parseFloat(value))) {
    return parsedNumber.toLocaleString("de-CH", { minimumFractionDigits: 2 });
  }

  return parsedNumber.toLocaleString("de-CH", { minimumFractionDigits: 2 });
});

Vue.filter("formatHighNumber", function (value) {
  return value.toLocaleString("de-CH");
});

Vue.filter("formatHours", function (value) {
  return value.toFixed(1);
});
