const snackbar = {
  namespaced: true,

  state: {
    open: false,
    color: "info",
    sMessage: "",
  },

  getters: {},

  mutations: {
    /**
     * Open success snackbar
     * @param {*} state
     * @param {*} sMessage
     */
    showSuccessSnackbar(state, sMessage) {
      state.open = true;
      state.color = "success";
      state.sMessage = sMessage;
    },

    /**
     * Open error snackbar
     * @param {*} state
     * @param {*} sMessage
     */
    showErrorSnackbar(state, sMessage) {
      state.open = true;
      state.color = "error";
      state.sMessage = sMessage;
    },

    /**
     * Open info snackbar
     * @param {*} state
     * @param {*} sMessage
     */
    showInfoSnackbar(state, sMessage) {
      state.open = true;
      state.color = "info";
      state.sMessage = sMessage;
    },

    /**
     * Close the snackbar
     * @param {*} state
     */
    closeSnackbar(state) {
      state.open = false;
    },
  },
};

export default snackbar;
