<template>
  <v-form ref="form" v-model="valid">
    <v-row>
      <v-col cols="12" md="6" class="py-0">
        <project-select
          v-model="selectedProject"
          label="Projekt"
          :items="allProjects"
          :required="true"
          :readonly="mode === 'read'"
          @selected="projectSelected"
        ></project-select>
      </v-col>

      <v-col cols="12" md="6" class="py-0">
        <single-select
          v-model="selectedBudget"
          label="Budget"
          :items="budgets"
          :required="true"
          :readonly="mode === 'read'"
          @selected="budgetSelected"
        ></single-select>
      </v-col>

      <v-col cols="12" md="6" class="py-0">
        <task-select
          v-model="selectedTask"
          label="Aufgabe"
          :items="availableTasks"
          :readonly="mode === 'read'"
          @selected="taskSelected"
        ></task-select>
      </v-col>

      <v-col cols="12" md="6" class="py-0">
        <single-select
          v-model="selectedCategory"
          label="Kategorie"
          :items="availableActivities"
          :required="true"
          :readonly="mode === 'read'"
        ></single-select>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12" md="3" class="py-0">
        <number-input
          v-model="formData.billable_rate"
          label="Verrechenbar"
          :required="true"
          :readonly="mode === 'read'"
        >
        </number-input
      ></v-col>

      <v-col cols="12" md="3" class="py-0">
        <date-input
          v-model="formData.date"
          label="Datum"
          :required="true"
          :readonly="mode === 'read'"
        ></date-input>
      </v-col>

      <v-col cols="12" md="3" class="py-0">
        <time-input
          v-model="formData.start_time"
          label="Start"
          :required="true"
          :readonly="mode === 'read'"
        ></time-input>
      </v-col>

      <v-col cols="12" md="3" class="py-0">
        <time-input
          v-model="formData.end_time"
          label="Ende"
          :required="true"
          :readonly="mode === 'read'"
        ></time-input>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12" md="6" class="py-0">
        <text-area
          v-model="formData.description"
          label="Beschreibung"
          :readonly="mode === 'read'"
          :rows="$vuetify.breakpoint.mdAndUp ? 3 : 2"
        >
        </text-area>
      </v-col>
      <v-col cols="12" md="6" class="py-0">
        <text-area
          v-model="formData.description_intern"
          label="Beschreibung Intern"
          :readonly="mode === 'read'"
          :rows="$vuetify.breakpoint.mdAndUp ? 3 : 2"
        >
        </text-area>
      </v-col>
    </v-row>

    <v-row class="action">
      <v-col cols="auto" v-if="timeEntryId">
        <v-btn text color="error" @click="remove" v-if="mode !== 'read'">
          Löschen
        </v-btn>
      </v-col>
      <v-col cols="auto">
        <v-btn class="cancel" text @click="cancel">Abbrechen</v-btn>
      </v-col>
      <v-col cols="auto">
        <v-btn
          v-if="mode !== 'read'"
          class="commit"
          color="primary"
          :disabled="!valid"
          @click="
            mode === 'create' || mode === 'duplicate' ? create() : update()
          "
          >{{ getCommitLabel }}</v-btn
        >
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import { mapState } from "vuex";
import moment from "moment";
import SingleSelect from "@/components/forms/common/SingleSelect";
import ProjectSelect from "@/components/forms/common/ProjectSelect";
import TaskSelect from "@/components/forms/common/TaskSelect";
import TimeInput from "@/components/forms/common/TimeInput";
import DateInput from "@/components/forms/common/DateInput";
import NumberInput from "@/components/forms/common/NumberInput";
import TextArea from "@/components/forms/common/TextArea";

export default {
  components: {
    SingleSelect,
    ProjectSelect,
    TaskSelect,
    TimeInput,
    DateInput,
    NumberInput,
    TextArea,
  },

  props: {
    mode: {
      required: true,
      type: String,
    },
    input: {
      required: true,
    },
    taskInput: {
      default: null,
    },
    duration: {
      default: null,
    },
    allProjects: {
      required: true,
      type: Array,
    },
    allMyTasks: {
      required: true,
      type: Array,
    },
  },

  data() {
    return {
      valid: false,
      timeEntryId: null,

      // dropdown
      selectedProject: "",
      selectedBudget: "",
      selectedTask: "",
      selectedCategory: "",

      formData: {
        date: null,
        start_time: null,
        end_time: null,
        billable_rate: 100,
        description: "",
        description_intern: "",
      },
    };
  },

  computed: {
    ...mapState("app", ["configs", "activities"]),
    ...mapState("user", ["user"]),

    getCommitLabel() {
      if (this.mode === "edit") {
        return "Änderung Speichern";
      }
      return "Erstellen";
    },

    // TODO TT-28 Replace magic number with ActivityIdRoles
    availableActivities() {
      const items = [];
      this.activities.forEach((a) => {
        if (
          a.project_time_coverage_type !== 5 &&
          a.project_time_coverage_type !== 7
        ) {
          items.push({
            id: a.remote_id,
            name: a.description,
            disabled: !(
              a.project_time_coverage_type === 0 ||
              a.project_time_coverage_type === 3
            ),
          });
        }
      });
      return items;
    },

    budgets() {
      if (this.selectedProject) {
        const project = this.allProjects.find(
          (p) => p.id === this.selectedProject
        );

        if (project !== undefined) {
          return project.budgets.map((b) => {
            return {
              id: b.id,
              name: b.name,
              disabled: b.is_locked,
            };
          });
        }
      }
      return [];
    },

    availableTasks() {
      let items = [...this.allMyTasks];
      if (this.allMyTasks) {
        if (this.input && this.input.task) {
          // In case the task is already completed or deleted,
          // Manually add the task here for display purposes
          const existing = this.allMyTasks.find(
            (t) => t.id === this.input.task.id
          );
          if (!existing) {
            items.push({
              ...this.input.task,
              budget: this.input.budget,
            });
          }
        }
      }
      return items;
    },
  },

  mounted() {
    this.reset(this.input, "create");

    if (this.taskInput) {
      this.selectedTask = this.taskInput.id;
      this.taskSelected(this.taskInput.id);
    }
  },

  watch: {
    input(value) {
      this.reset(value, this.mode);
    },

    duration(value) {
      if (value) {
        this.formData = {
          ...this.formData,
          date: value.date,
          start_time: value.start_time,
          end_time: value.end_time,
        };
      }
    },

    mode(value) {
      this.reset(this.input, value);
    },
  },

  methods: {
    create() {
      const payload = this.getPayload();

      this.$api.http
        .post(`api/time-entries`, payload)
        .then(() => {
          this.$toast.open("Zeit erfasst.");
          this.reset(null, "create");
          this.$emit("saved");
        })
        .catch(() => {
          this.$toast.open({
            message: "Etwas ist schiefgelaufen.",
            type: "error",
          });
        });
    },

    update() {
      const payload = this.getPayload();

      this.$api.http
        .put(`api/time-entries/${this.timeEntryId}`, payload)
        .then(() => {
          this.$toast.open("Zeiteintrag geändert.");
          this.reset(null, "create");
          this.$emit("saved");
        })
        .catch(() => {
          this.$toast.open({
            message: "Etwas ist schiefgelaufen.",
            type: "error",
          });
        });
    },

    remove() {
      this.$api.http
        .delete(`api/time-entries/${this.timeEntryId}`)
        .then(() => {
          this.$toast.open("Zeiteintrag wurde gelöscht.");
          this.reset(null, "create");
          this.$emit("deleted");
        })
        .catch(() => {
          this.$toast.open({
            message: "Etwas ist schiefgelaufen.",
            type: "error",
          });
        });
    },

    reset(value, mode) {
      if (mode === "create") {
        this.timeEntryId = null;

        this.selectedProject = null;
        this.selectedBudget = null;
        this.selectedTask = null;
        this.selectedCategory = null;

        this.formData = {
          date: null,
          start_time: null,
          end_time: null,
          billable_rate: 100,
          description: "",
          description_intern: "",
        };
      } else if (value) {
        let startTime = null;
        let endTime = null;

        if (mode === "edit" || mode === "read") {
          this.timeEntryId = value.id;
          startTime = moment(value.start_time).format("HH:mm");
          endTime = moment(value.end_time).format("HH:mm");
        } else if (mode === "duplicate") {
          this.timeEntryId = null;
        }

        this.selectedProject = value.project?.id ?? null;
        this.selectedBudget = value.budget?.id ?? null;
        this.selectedTask = value.task?.id ?? null;
        this.selectedCategory = value.remote_category_id;

        this.formData = {
          date: value.date,
          start_time: startTime,
          end_time: endTime,
          billable_rate: value.billable_rate.toString(),
          description: value.description,
          description_intern: value.description_intern,
        };
      }

      this.$refs.form.resetValidation();
    },

    cancel() {
      this.reset(this.input, this.mode);
      this.$emit("cancel");
    },

    getPayload() {
      let payload = { ...this.formData };

      payload.employee_id = this.user.employee.id;
      payload.project_id = this.selectedProject;
      payload.budget_id = this.selectedBudget;
      payload.task_id = this.selectedTask;
      payload.remote_category_id = this.selectedCategory;

      payload.date = moment(this.formData.date).format("YYYY-MM-DD");
      payload.start_time = `${payload.date} ${this.formData.start_time}:00`;
      payload.end_time = this.formData.end_time
        ? `${payload.date} ${this.formData.end_time}:00`
        : null;

      payload.todo = false;

      return payload;
    },

    projectSelected() {
      this.selectedBudget = null;
      this.selectedTask = null;
    },

    budgetSelected() {
      this.selectedTask = null;
    },

    taskSelected(taskId) {
      const task = this.allMyTasks.find((t) => t.id === taskId);

      if (task) {
        this.selectedProject = task.budget.project.id;
        this.selectedBudget = task.budget.id;
        this.selectedCategory = task.remote_category_id;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.action {
  justify-content: end;

  .cancel {
    width: 100%;
  }

  .commit {
    width: 100%;
  }
}
</style>
