<template>
  <v-card class="tt-card">
    <v-card-title class="header">
      <div class="grow">Support {{ calenderWeek() }}</div>
      <v-btn v-if="!edit" @click="edit = true">Bearbeiten</v-btn>
    </v-card-title>
    <v-card-text>
      <support-form
        :edit="edit"
        :input="selectedSupport"
        :selectedWeek="selectedWeek"
        @cancel="cancel"
        @saved="saved"
      ></support-form>
    </v-card-text>
  </v-card>
</template>

<script>
import moment from "moment";
import SupportForm from "@/components/forms/SupportForm";

export default {
  components: {
    SupportForm,
  },

  props: {
    selectedSupport: {
      default: null,
    },
    selectedWeek: {
      required: true,
    },
  },

  data() {
    return {
      edit: false,
    };
  },

  mounted() {
    this.checkIfSupportHasMain(this.selectedSupport);
  },

  watch: {
    selectedSupport(value) {
      this.checkIfSupportHasMain(value);
    },
  },

  methods: {
    calenderWeek() {
      const year = moment(this.selectedWeek).format("YYYY");
      const kw = moment(this.selectedWeek).format("W");
      return year + " - KW " + kw;
    },

    checkIfSupportHasMain(value) {
      if (!(value && value.main)) {
        this.edit = true;
      } else {
        this.edit = false;
      }
    },

    cancel() {
      this.edit = false;
    },

    saved() {
      this.$emit("refresh");
      this.edit = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.action {
  justify-content: end;

  .cancel {
    width: 100%;
  }

  .commit {
    width: 100%;
  }
}
</style>
