<template>
  <v-dialog v-model="isOpen" scrollable width="500">
    <v-card class="tt-card">
      <v-card-title class="header">
        <span class="subtitle-1">Kalendereintrag erfassen</span>
      </v-card-title>

      <v-card-text class="mt-3">
        <p>Lorem ipsum</p>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="default" text @click="close()"> Abbrechen </v-btn>
        <v-btn color="primary" text @click="save()"> Erstellen </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapMutations } from "vuex";

export default {
  data: () => ({
    isOpen: false,
  }),

  methods: {
    ...mapMutations("snackbar", ["showErrorSnackbar"]),

    open() {
      this.isOpen = true;
    },

    close() {
      this.isOpen = false;
    },

    save() {
      this.open = false;
    },
  },
};
</script>
