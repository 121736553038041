<template>
  <div>
    <v-card color="default" outlined class="tt-card">
      <v-card-title class="header">
        <div>Deine Aufgaben</div>
      </v-card-title>

      <v-card-text>
        <remote-data-table
          :url="`api/tasks?employee_id=${user.employee.id}`"
          :headers="headers"
          :sort-by="'name'"
          ref="myTasksDatatable"
          class="dashboard-tasks-table"
          @dblclick="openEditTaskDialog"
        >
          <template v-slot:[`item.name`]="{ item }">
            <span style="white-space: nowrap; font-weight: bold">
              {{ item.name }}
            </span>
          </template>
          <template v-slot:[`item.budget.project.name`]="{ item }">
            <span class="text-caption" style="white-space: nowrap">
              {{ item.budget.project.name }}
            </span>
          </template>
          <template v-slot:[`item.starts_at`]="{ item }">
            <span class="text-caption" style="white-space: nowrap">
              {{ item.starts_at | formatDate }}
            </span>
          </template>
          <template v-slot:[`item.ends_at`]="{ item }">
            <span class="text-caption" style="white-space: nowrap">
              {{ item.ends_at | formatDate }}
            </span>
          </template>
          <template v-slot:[`item.action`]="{ item }">
            <v-menu bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn x-small v-bind="attrs" v-on="on" text fab>
                  <v-icon>more_vert</v-icon>
                </v-btn>
              </template>
              <v-list>
                <v-list-item @click="openCreateTimeEntryDialog(item)">
                  Zeit erfassen
                </v-list-item>
                <v-list-item @click="openEditTaskDialog(item)">
                  Bearbeiten
                </v-list-item>
                <v-list-item
                  v-if="item.status !== 'completed'"
                  @click="openCompleteTaskDialog(item.id, item.name)"
                >
                  Erledigt
                </v-list-item>
              </v-list>
            </v-menu>
          </template>
        </remote-data-table>
      </v-card-text>
    </v-card>

    <time-entry-dialog
      ref="timeEntryDialog"
      @changed="$emit('savedTimeEntry')"
    ></time-entry-dialog>
    <task-dialog ref="taskDialog"></task-dialog>
    <complete-dialog ref="completeDialog"></complete-dialog>
  </div>
</template>

<script>
import { mapState } from "vuex";
import RemoteDataTable from "@/components/RemoteDataTable";
import TimeEntryDialog from "@/components/dialogs/TimeEntryDialog";
import CompleteDialog from "@/components/dialogs/CompleteDialog";
import TaskDialog from "@/components/dialogs/TaskDialog";

export default {
  components: {
    RemoteDataTable,
    TimeEntryDialog,
    CompleteDialog,
    TaskDialog,
  },

  props: {
    allProjects: {
      required: true,
    },
    allMyTasks: {
      required: true,
    },
  },

  computed: {
    ...mapState("user", ["user"]),
  },

  data: () => ({
    headers: [
      { text: "Titel", align: "left", value: "name" },
      {
        text: "Projekt",
        align: "left",
        value: "budget.project.name",
        sortable: false,
      },
      { text: "Start", align: "left", value: "starts_at" },
      { text: "Ende", align: "left", value: "ends_at" },
      { text: "", align: "right", value: "action" },
    ],
  }),

  methods: {
    openCreateTimeEntryDialog(task) {
      this.$refs.timeEntryDialog.open(
        this.allProjects,
        this.allMyTasks,
        null,
        task
      );
    },

    openCompleteTaskDialog(id, name) {
      this.$refs.completeDialog.open(id, name);
    },

    openEditTaskDialog(task) {
      this.$refs.taskDialog.open(
        "edit",
        task.id,
        task.budget.project.id,
        task.budget.id,
        task.employee.id
      );
    },
  },
};
</script>

<style lang="scss" scoped></style>
