<template>
  <v-dialog v-model="isOpen" scrollable width="500">
    <v-card>
      <v-card-title :class="['primary', 'white--text']">
        <span class="subtitle-1">Aufgabe</span>
      </v-card-title>

      <v-card-text class="mt-3" v-if="request">
        <v-row class="mb-1">
          <v-col cols="3" class="py-0">Mitarbeiter</v-col>
          <v-col class="py-0">
            <strong>{{ employee }}</strong>
          </v-col>
        </v-row>
        <v-row class="mb-1">
          <v-col cols="3" class="py-0">Typ</v-col>
          <v-col class="py-0">
            <strong>
              {{ getAbsenceType(request.type) }}
            </strong>
          </v-col>
        </v-row>
        <v-row class="mb-1">
          <v-col cols="3" class="py-0">Info</v-col>
          <v-col class="py-0">
            <strong>
              {{ request.info }}
            </strong>
          </v-col>
        </v-row>
        <v-row class="mb-1">
          <v-col cols="3" class="py-0">Von</v-col>
          <v-col class="py-0">
            <strong>
              {{ formatDate(request.start) }}
            </strong>
          </v-col>
        </v-row>
        <v-row class="mb-1">
          <v-col cols="3" class="py-0">Bis und mit</v-col>
          <v-col class="py-0">
            <strong>
              {{ formatDate(request.end) }}
            </strong>
          </v-col>
        </v-row>

        <v-row class="mb-1">
          <v-col cols="3" class="py-0">Istzustand</v-col>
          <v-col class="py-0">
            <budget-time-diagram
              v-if="overview"
              :tracked="overview.holiday_taken_year"
              :planned="overview.holiday_planned_year"
              :offered="overview.holiday_balance_year"
              mode="holiday"
            ></budget-time-diagram>
          </v-col>
        </v-row>

        <h3 class="mt-4">Wurde die Aufgabe in Comatic eingetragen?</h3>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="default" text @click="close()" class="ml-6 px-4">
          Abbrechen
        </v-btn>
        <v-btn
          color="error"
          @click="completeAbsenceProcess()"
          class="ml-6 px-4"
        >
          Fertig Eingetragen
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import moment from "moment";
import { mapState } from "vuex";
import BudgetTimeDiagram from "../../diagram/BudgetTimeDiagram.vue";

export default {
  components: {
    BudgetTimeDiagram,
  },

  data: () => ({
    isOpen: false,
    id: null,
    employee: "",
    request: null,
    overview: null,
  }),

  computed: {
    ...mapState("app", ["configs"]),
  },

  methods: {
    open(id, employee, overview) {
      this.isOpen = true;
      this.id = id;
      this.employee = employee;
      this.overview = overview;

      this.$absenceRequestService
        .getAbsence(id)
        .then((res) => {
          this.request = res.data;
        })
        .catch((err) =>
          this.$toast({
            message: "Absenz wurde nicht gefunden.",
            type: "error",
          })
        );
    },

    getAbsenceType() {
      return this.$absenceRequestService.getAbsenceType(
        this.configs.absence_types,
        this.request.type
      );
    },

    formatDate(date) {
      return moment(date).format("DD.MM.YYYY");
    },

    close() {
      this.reset();
      this.isOpen = false;
    },

    reset() {
      this.id = null;
    },

    completeAbsenceProcess() {
      this.$absenceRequestService
        .changeOfficeTaskState(this.id)
        .then(() => {
          this.$toast.open("Aufgabe erfolgreicht abgeschlossen.");
          this.$emit("done");
          this.close();
        })
        .catch(() => {
          this.$toast.open({
            message: "Eintrag konnte nicht abgeschlossen werden.",
            type: "error",
          });
          this.close();
        });
    },
  },
};
</script>
