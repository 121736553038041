<template>
  <v-form ref="form" v-model="valid">
    <v-row>
      <v-col cols="12" md="6" class="py-0">
        <single-select
          v-model="formData.type"
          label="Absenztyp"
          :items="configs.absence_types"
          :required="true"
        ></single-select>
      </v-col>

      <v-col cols="12" md="6" class="py-0">
        <text-input v-model="formData.info" label="Bemerkung"> </text-input>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12" md="6" class="py-0">
        <date-input
          v-model="formData.start"
          label="Von"
          :required="true"
        ></date-input>
      </v-col>

      <v-col cols="12" md="6" class="py-0">
        <date-input
          v-model="formData.end"
          label="Bis und mit"
          :required="true"
        ></date-input>
      </v-col>
    </v-row>

    <v-row class="action">
      <v-col cols="auto">
        <v-btn class="cancel" text @click="cancel">Abbrechen</v-btn>
      </v-col>
      <v-col cols="auto">
        <v-btn
          class="commit"
          color="primary"
          :disabled="!valid"
          @click="
            mode === 'create' || mode === 'duplicate' ? create() : update()
          "
          >{{ getCommitLabel }}</v-btn
        >
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import { mapState } from "vuex";
import moment from "moment";
import DateInput from "@/components/forms/common/DateInput";
import SingleSelect from "@/components/forms/common/SingleSelect";
import TextInput from "@/components/forms/common/TextInput";

export default {
  components: {
    DateInput,
    SingleSelect,
    TextInput,
  },

  props: {
    mode: {
      required: true,
      type: String,
    },
    input: {
      required: true,
    },
    duration: {
      default: null,
    },
  },

  data() {
    return {
      valid: false,
      absenceRequestId: null,

      formData: {
        type: null,
        info: "",
        start: null,
        end: null,
        manager_id: null,
        office_id: null,
      },
    };
  },

  computed: {
    ...mapState("app", ["configs"]),
    ...mapState("user", ["user"]),

    getCommitLabel() {
      if (this.mode === "edit") {
        return "Änderung Speichern";
      }
      return "Beantragen";
    },
  },

  mounted() {
    this.reset(this.input, "create");
  },

  watch: {
    input(value) {
      this.reset(value, this.mode);
    },

    duration(value) {
      if (value) {
        this.formData = {
          ...this.formData,
          start: value.start,
          end: value.end,
        };
      }
    },

    mode(value) {
      this.reset(this.input, value);
    },
  },

  methods: {
    create() {
      const payload = this.getPayload();

      this.$api.http
        .post(`api/absences`, payload)
        .then(() => {
          this.$toast.open("Absenz beantragt.");
          this.reset(null, "create");
          this.$emit("saved");
        })
        .catch(() => {
          this.$toast.open({
            message: "Etwas ist schiefgelaufen.",
            type: "error",
          });
        });
    },

    update() {
      const payload = this.getPayload();

      this.$api.http
        .put(`api/absences/${this.absenceRequestId}`, payload)
        .then(() => {
          this.$toast.open("Absenzantragt geändert.");
          this.reset(null, "create");
          this.$emit("saved");
        })
        .catch(() => {
          this.$toast.open({
            message: "Etwas ist schiefgelaufen.",
            type: "error",
          });
        });
    },

    reset(value, mode) {
      if (mode === "create") {
        this.absenceRequestId = null;

        this.formData = {
          type: null,
          info: "",
          start: null,
          end: null,
        };
      } else if (value) {
        this.absenceRequestId = value.id;

        this.formData = {
          type: value.type,
          info: value.info,
          start: value.start,
          end: value.end,
        };
      }

      this.$refs.form.resetValidation();
    },

    cancel() {
      this.reset(this.input);
      this.$emit("cancel");
    },

    getPayload() {
      let payload = { ...this.formData };

      payload.employee_id = this.user.employee.id;
      payload.start = moment(this.formData.start).format("YYYY-MM-DD");
      payload.end = moment(this.formData.end).format("YYYY-MM-DD");

      return payload;
    },
  },
};
</script>

<style lang="scss" scoped>
.action {
  justify-content: end;

  .cancel {
    width: 100%;
  }

  .commit {
    width: 100%;
  }
}
</style>
