<template>
  <div class="main-container">
    <v-container fluid>
      <v-row style="align-items: center" class="mb-4">
        <v-col>
          <h1 class="text-h4">Kalender</h1>
        </v-col>
        <v-col cols="auto">
          <tooltip-button
            icon="add"
            text="Task erstellen"
            color="primary"
            @click="openCreateDialog()"
            position="top"
          />
        </v-col>
      </v-row>
      <v-card color="default" outlined class="elevation-0">
        <v-card-text>
          <v-sheet tile height="54" class="d-flex">
            <v-btn icon class="ma-2" @click="$refs.calendar.prev()">
              <v-icon>chevron_left</v-icon>
            </v-btn>
            <v-spacer></v-spacer>
            <h3 class="mt-3">{{ formattedDate }}</h3>
            <v-spacer></v-spacer>
            <v-btn icon class="ma-2" @click="$refs.calendar.next()">
              <v-icon>chevron_right</v-icon>
            </v-btn>
          </v-sheet>
          <v-sheet height="800">
            <v-calendar
              ref="calendar"
              v-model="date"
              :weekdays="weekday"
              :type="type"
              :events="events"
              :event-overlap-mode="mode"
              :event-overlap-threshold="30"
              :event-color="getEventColor"
              @change="getEvents"
            ></v-calendar>
          </v-sheet>
        </v-card-text>
      </v-card>
    </v-container>
    <calendar-dialog ref="calendarDialog" />
  </div>
</template>

<script>
import moment from "moment";
import TooltipButton from "@/components/forms/input/TooltipButton";
import CalendarDialog from "@/components/dialogs/CalendarDialog";

export default {
  name: "calendar",

  components: {
    CalendarDialog,
    TooltipButton,
  },

  data: () => ({
    type: "month",
    types: ["month", "week", "day", "4day"],
    mode: "stack",
    modes: ["stack", "column"],
    weekday: [0, 1, 2, 3, 4, 5, 6],
    weekdays: [
      { text: "Sun - Sat", value: [0, 1, 2, 3, 4, 5, 6] },
      { text: "Mon - Sun", value: [1, 2, 3, 4, 5, 6, 0] },
      { text: "Mon - Fri", value: [1, 2, 3, 4, 5] },
      { text: "Mon, Wed, Fri", value: [1, 3, 5] },
    ],
    date: new Date(),
    events: [],
  }),

  computed: {
    formattedDate() {
      return moment(this.date).format("MMMM YYYY");
    },
  },

  methods: {
    getEvents({ start, end }) {
      this.$api.http
        .get(`api/calendar?start=${start.date}&end=${end.date}`)
        .then((response) => {
          this.events = response.data.map((item) => {
            return {
              name: item.name,
              start: new Date(item.start),
              end: new Date(item.end),
              color: item.type === "task" ? "accent" : "indigo",
              timed: true,
            };
          });
        });
    },

    getEventColor(event) {
      return event.color;
    },

    openCreateDialog() {
      this.$refs.calendarDialog.open();
    },
  },
};
</script>
