<template>
  <div id="gantt-projects">
    <workloads v-if="showWorkloads" ref="workloads" />
    <div
      :class="
        showWorkloads ? 'chart-with-workloads' : 'chart-without-workloads'
      "
      ref="chart"
    />
    <task-dialog ref="taskDialog" @saved="load()" />
    <delete-dialog ref="deleteDialog" @deleted="load()" />
    <complete-dialog ref="completeDialog" @completed="load()" />
  </div>
</template>

<script>
import moment from "moment";
import "@tingo-gmbh/bryntum-gantt/gantt.material.min.css";
import "../custom.locale.De";
import { Gantt, StringHelper, TaskTooltip } from "@tingo-gmbh/bryntum-gantt";
import { mapState, mapMutations } from "vuex";
import Workloads from "@/components/rp/projects/Workloads";
import TaskDialog from "@/components/dialogs/TaskDialog";
import DeleteDialog from "@/components/dialogs/DeleteDialog";
import CompleteDialog from "@/components/dialogs/CompleteDialog";
import ProjectsModel from "@/components/rp/models/ProjectsModel";
import BudgetTimeService from "@/services/BudgetTimeService";

export default {
  name: "gantt-projects",

  components: {
    Workloads,
    TaskDialog,
    DeleteDialog,
    CompleteDialog,
  },

  data: () => ({
    loaded: false,
    projectModel: null,
    ganttEngine: null,
  }),

  computed: {
    ...mapState("app", ["configs"]),
    ...mapState("rp", [
      "projectStatusFilter",
      "projectTemplatesFilter",
      "projectsFilter",
      "customersFilter",
      "employeeTasksFilter",
      "employeeManagersFilter",
      "statusFilter",
      "scheduledFilter",
      "startDate",
      "endDate",
      "showWorkloads",
    ]),
  },

  watch: {
    showWorkloads(value) {
      if (value) {
        this.$refs.workloads.reload();
      }
    },
  },

  mounted() {
    this.projectModel = ProjectsModel;
    this.projectModel.listeners = {
      beforeSend: ({ params, config }) => {
        if (config.type === "load") {
          if (this.projectStatusFilter.length > 0) {
            params["_projects_status_filter"] =
              this.projectStatusFilter.join(",");
          }
          if (this.projectTemplatesFilter.length > 0) {
            params["_projects_templates_filter"] =
              this.projectTemplatesFilter.join(",");
          }
          if (this.projectsFilter.length > 0) {
            params["_projects_filter"] = this.projectsFilter.join(",");
          }
          if (this.customersFilter.length > 0) {
            params["_customers_filter"] = this.customersFilter.join(",");
          }
          if (this.employeeTasksFilter.length > 0) {
            params["_employee_tasks_filter"] =
              this.employeeTasksFilter.join(",");
          }
          if (this.employeeManagersFilter.length > 0) {
            params["_employee_managers_filter"] =
              this.employeeManagersFilter.join(",");
          }
          if (this.statusFilter) {
            params.status = this.statusFilter;
          }
          if (this.scheduledFilter) {
            params.scheduled = this.scheduledFilter;
          }
        }
      },
    };

    const ganttConfig = {
      project: this.projectModel,
      rowHeight: 72,
      startDate: this.startDate._d,
      endDate: this.endDate._d,
      features: {
        projectLines: {
          disabled: true,
        },
        dependencies: {
          disabled: true,
        },
        percentBar: {
          allowResize: false,
        },
        taskTooltip: {
          template(data) {
            const me = this,
              { taskRecord } = data;

            if (taskRecord.type !== "task") {
              return TaskTooltip.defaultConfig.template.call(me, data);
            }
            // Return the result of the feature's default template, with custom markup appended.
            const startsAt = moment(taskRecord.originalData.startDate).format(
              "DD.MM.YYYY"
            );
            const endsAt = moment(taskRecord.originalData.endDate).format(
              "DD.MM.YYYY"
            );
            return `
              <h4>${taskRecord.originalData.name}</h4>
              <table border="0" cellspacing="0" cellpadding="0" style="width:100%;">
                <tr>
                  <td>Von:</td><td style="text-align: right;padding-left:20px;">${startsAt}</td>
                </tr>
                <tr>
                  <td>Bis:</td><td style="text-align: right;padding-left:20px;">${endsAt}</td>
                </tr>
                <tr>
                  <td>Aufwand:</td><td style="text-align: right;padding-left:20px;">${taskRecord.time_expected} Stunden</td>
                </tr>
                <tr>
                  <td>Erledigt:</td><td style="text-align: right;padding-left:20px;">${taskRecord.originalData.percentDone}%</td>
                </tr>
              </table>
              `;
          },
        },
        taskMenu: {
          items: {
            add: false,
            subtask: false,
            indent: false,
            outdent: false,
            convertToMilestone: false,
            copyRow: false,
            cutRow: false,
            createTask: {
              text: "Erstellen",
              weight: 100,
              icon: "b-icon b-icon-add",
              onItem: ({ taskRecord }) => {
                this.openCreateDialog(
                  taskRecord.project_id,
                  taskRecord.budget_id
                );
              },
            },
            editTask: {
              text: "Bearbeiten",
              weight: 110,
              onItem: ({ taskRecord }) => {
                this.openEditDialog(
                  taskRecord.task_id,
                  taskRecord.budget_id,
                  taskRecord.project_id
                );
              },
            },
            deleteTask: {
              text: "Löschen",
              weight: 120,
              onItem: ({ taskRecord }) => {
                this.openDeleteDialog(taskRecord.task_id, taskRecord.name);
              },
            },
            completeTask: {
              text: "Erledigt",
              weight: 115,
              icon: "b-icon b-icon-check",
              onItem: ({ taskRecord }) => {
                this.openCompleteDialog(taskRecord.task_id, taskRecord.name);
              },
            },
          },
          processItems({ taskRecord, items }) {
            if (taskRecord.id.startsWith("p")) {
              items.editTask.hidden = true;
              items.deleteTask.hidden = true;
              items.completeTask.hidden = true;
              items.createTask.hidden = true;
            }
            if (taskRecord.id.startsWith("b")) {
              items.editTask.hidden = true;
              items.deleteTask.hidden = true;
              items.completeTask.hidden = true;
            }
            if (taskRecord.id.startsWith("t")) {
              items.createTask.hidden = true;
            }
          },
        },
      },
      listeners: {
        beforeTaskEdit: ({ taskRecord, taskEdit, taskElement }) => {
          if (!taskRecord.type || taskRecord.type === "task") {
            this.openEditDialog(
              taskRecord.task_id,
              taskRecord.budget_id,
              taskRecord.project_id
            );
          }

          // Prevent built in editor
          return false;
        },
      },

      columns: [
        {
          type: "name",
          field: "name",
          text: "Projekt",
          width: 300,
          editor: false,
          sortable(el1, el2) {
            return el1.name.toLowerCase() < el2.name.toLowerCase() ? -1 : 1;
          },
          renderer: ({ record }) => {
            switch (record.type) {
              case "project":
                return {
                  children: [
                    {
                      tag: "div",
                      class: "project-item",
                      children: [
                        {
                          tag: "div",
                          class: "project-title",
                          html: record.name,
                        },
                        {
                          tag: "div",
                          class: "customer-title",
                          html: record.customer,
                        },
                      ],
                    },
                  ],
                };
              case "budget":
                return {
                  children: [
                    {
                      tag: "div",
                      class: "project-item",
                      children: [
                        {
                          tag: "div",
                          class: "project-title",
                          html: record.name,
                        },
                      ],
                    },
                  ],
                };
              default:
                return {
                  children: [
                    {
                      tag: "div",
                      class: "task-item",
                      children: [
                        {
                          tag: "div",
                          class: "project-title",
                          html: record.name,
                        },
                        {
                          tag: "div",
                          class: "status-item",
                          children: [
                            {
                              tag: "div",
                              class: "label-item",
                              html: record.statusName,
                            },
                          ],
                        },
                      ],
                    },
                  ],
                };
            }
          },
        },
        {
          type: "template",
          field: "status",
          text: "Status",
          width: 160,
          editor: false,
          sortable(el1, el2) {
            return el1.status.toLowerCase() < el2.status.toLowerCase() ? -1 : 1;
          },
          template: ({ record }) => {
            switch (record.type) {
              case "project":
                return (
                  `<div class="status-item">` +
                  `<div class="label-item">${this.getProjectStatusName(
                    record.status
                  )}</div>` +
                  `</div>`
                );
              case "budget":
                return `<div class="status-item"><div class="hours-item">${record.time_used} von ${record.time_offered} Stunden</div></div>`;
              case "task":
                return `<div class="status-item"><div class="hours-item">${record.time_used} von ${record.time_expected} Stunden</div></div>`;
              default:
                return "";
            }
          },
        },
        {
          type: "template",
          field: "manager",
          text: "Projektleiter / Stv.",
          width: 160,
          editor: false,
          sortable(el1, el2) {
            return el1.manager.toLowerCase() < el2.manager.toLowerCase()
              ? -1
              : 1;
          },
          template: ({ record }) => {
            switch (record.type) {
              case "project":
                return (
                  `<div class="manager-item"><div class="manager-title">${record.manager}</div>` +
                  (record.deputy
                    ? `<div class="deputy-title">Stv: ${record.deputy}</div>`
                    : ``) +
                  `</div>`
                );
              case "task":
                return `<div class="manager-item"><div class="manager-title">${record.employee}</div></div>`;
              default:
                return ``;
            }
          },
        },
        {
          type: "template",
          text: "Planungszustand",
          width: 350,
          editor: false,
          template: ({ record }) => {
            const tracked = record.time_used;
            const planned = record.time_expected;
            const offered = record.time_offered;

            const budgetTimeService = new BudgetTimeService(
              tracked,
              planned,
              offered
            );

            const style = budgetTimeService.getCssStyle();
            const label = budgetTimeService.getLabel();

            const trackedEl =
              tracked !== 0
                ? `<div style="${style.tracked}">${label.tracked}</div>`
                : "";

            const plannedEl =
              planned !== 0
                ? `<div style="${style.planned}">${label.planned}</div>`
                : "";

            switch (record.type) {
              case "project":
                return (
                  `<div class="planning-item">` +
                  `<div>${record.time_offered} Stunden budgetiert</div>` +
                  `<div style="${style.box}">` +
                  `${trackedEl}` +
                  `${plannedEl}` +
                  `<div style="${style.left}">${label.left}</div>` +
                  `</div>` +
                  `</div>`
                );
              default:
                return ``;
            }
          },
        },
      ],

      taskRenderer({ taskRecord, renderData }) {
        let style = "";
        switch (taskRecord.type) {
          case "project":
            style += "background:#697dc2;";
            break;
          case "budget":
            style += "background:#de97c9;";
            break;
          case "task":
            style += "background:#f5487f;";
            break;
          default:
            style += "background:#f5487f;";
            break;
        }
        renderData.style = style;
        if (!taskRecord.type || taskRecord.type === "task") {
          return [
            {
              tag: "div",
              class: "task-name",
              html: StringHelper.encodeHtml(
                taskRecord.name +
                  (taskRecord.time_expected > 0
                    ? ` (${taskRecord.time_expected} Stunden)`
                    : "")
              ),
            },
            ...taskRecord.resources.map((resource) => ({
              tag: "img",
              src: resource.avatar,
              class: "task-avatar",
              dataset: {
                resourceId: resource.id,
              },
            })),
          ];
        }

        if (taskRecord.type === "budget") {
          return [
            {
              tag: "div",
              class: "budget-name",
              html: StringHelper.encodeHtml(
                `${taskRecord.time_offered} Stunden`
              ),
            },
          ];
        }
      },
    };

    const gantt = (this.ganttEngine = new Gantt(ganttConfig));
    gantt.render(this.$refs.chart);

    this.projectModel.on("hasChanges", (crud) => {
      if (this.loaded) {
        this.$emit("hasChanges", true);
      }
    });

    this.load();
  },

  beforeDestroy() {
    if (this.ganttEngine) {
      this.ganttEngine.destroy();
    }
  },

  methods: {
    ...mapMutations("snackbar", ["showSuccessSnackbar"]),

    /**
     * Load data.
     */
    load() {
      this.loaded = false;
      this.projectModel.load().then(() => {
        this.loaded = true;
        this.$refs.workloads.reload();
      });
    },

    /**
     * Sync data.
     */
    sync() {
      this.projectModel.sync().then(() => {
        this.$emit("hasChanges", false);
        this.showSuccessSnackbar("Änderungen gespeichert");
        this.$refs.workloads.reload();
      });
    },

    getProjectStatusName(statusId) {
      if (this.configs) {
        const status = this.configs.project_statuses.find(
          (s) => s.remote_id === statusId
        );
        if (status) {
          return status.name;
        }
      }
      return "Unknown";
    },

    /**
     * Set the time span.
     */
    setTimeSpan(startDate, endDate) {
      this.ganttEngine.setTimeSpan(startDate, endDate);
    },

    /**
     * Open create dialog
     */
    openCreateDialog(projectId, budgetId) {
      this.$refs.taskDialog.open("create", null, projectId, budgetId);
    },

    /**
     * Open edit dialog
     */
    openEditDialog(taskId, budgetId, projectId) {
      this.$refs.taskDialog.open("edit", taskId, projectId, budgetId);
    },

    /**
     * Open complete dialog
     */
    openCompleteDialog(id, name) {
      this.$refs.completeDialog.open(id, name);
    },

    /**
     * Open delete dialog
     */
    openDeleteDialog(id, name) {
      this.$refs.deleteDialog.open(id, name, "tasks");
    },
  },
};
</script>

<style lang="scss">
#gantt-projects {
  height: calc(100% - 64px);

  .chart-with-workloads {
    height: calc(100% - 140px);
  }
  .chart-without-workloads {
    height: 100%;
  }

  .project-item {
    .project-title {
      font-size: 16px;
      color: black;
    }
    .customer-title {
      font-size: 13px;
    }
  }

  .task-item {
    display: flex;

    .status-item {
      margin-left: 8px;

      .label-item {
        background: var(--v-accent-darken1);
      }
    }
  }

  .status-item {
    width: 100%;

    .label-item {
      display: inline-block;
      background: var(--v-primary-base);
      color: white;
      padding: 3px 6px;
      border-radius: 5px;
      font-size: 14px;
      width: 100%;
      text-align: center;
    }
  }

  .manager-item {
    .manager-title {
      font-size: 16px;
      color: black;
      font-weight: 400;
    }
    .deputy-title {
      font-size: 13px;
    }
  }

  .planning-item {
    width: 100%;
    text-align: end;
    padding-top: 8px;
  }

  .b-gantt-task-wrap.b-gantt-task-parent .b-gantt-task {
    max-height: 2em;
  }

  .b-sch-dayheadercell-0,
  .b-sch-dayheadercell-6 {
    background: rgb(206, 206, 206) !important;
  }

  .b-sch-dayheadercell-today {
    background: #1d2a3f !important;
    color: white;
  }

  .b-gantt-task {
    overflow: visible;
    .b-task-percent-bar-outer {
      overflow: visible;
    }
  }

  .b-gantt-task-content {
    font-size: 14px;
    overflow: visible;

    .budget-name {
      padding: 0.5em 0 1em;
    }

    .task-name {
      font-size: 1.1em;
      font-weight: bold;
      overflow: hidden;
      width: 80%;
      margin-left: 5px;
    }

    .task-avatar {
      position: absolute;
      top: 3px;
      right: 3px;
      width: 2.5em;
      height: 2.5em;
      border-radius: 100%;
      transition: transform 0.2s;
      cursor: default;

      &:hover {
        transform: scale(2);
        z-index: 1;
      }
    }
  }
}
</style>
