const user = {
  namespaced: true,

  state: {
    token: null,
    user: null,
  },

  getters: {
    isLoggedIn: (state) => !!state.token,

    isManagerOrBackoffice(state) {
      return (
        state.user &&
        (state.user.employee.is_admin ||
          state.user.employee.is_manager ||
          state.user.employee.is_backoffice)
      );
    },

    isManager(state) {
      return (
        state.user &&
        (state.user.employee.is_admin || state.user.employee.is_manager)
      );
    },

    isBackoffice(state) {
      return (
        state.user &&
        (state.user.employee.is_admin || state.user.employee.is_backoffice)
      );
    },
  },

  mutations: {
    setToken(state, token) {
      state.token = token;
    },
    setUser(state, user) {
      state.user = user;
    },
  },

  actions: {
    setToken({ commit }, token) {
      commit("setToken", token);
    },
    deleteToken({ commit }) {
      commit("setToken", null);
    },
    setUser({ commit }, user) {
      commit("setUser", user);
    },
  },
};

export default user;
