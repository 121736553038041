<template>
  <v-dialog v-model="isOpen" scrollable width="500">
    <v-card>
      <v-card-title :class="['primary', 'white--text']">
        <span class="subtitle-1">Abrechnungsperioden berechnen</span>
      </v-card-title>

      <v-card-text class="mt-3">
        <p>
          Sollen die Abrechnungsperioden für
          {{ employee ? employee.name : "" }} neu berechnet werden?
        </p>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="default" text @click="close()">Nein</v-btn>
        <v-btn color="primary" text @click="confirm()">Ja</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapMutations } from "vuex";

export default {
  data: () => ({
    isOpen: false,
    employee: null,
    year: null,
  }),

  methods: {
    ...mapMutations("snackbar", ["showErrorSnackbar"]),

    open(employee, year) {
      this.isOpen = true;
      this.employee = employee;
      this.year = year;
    },

    close() {
      this.isOpen = false;
      this.reset();
    },

    reset() {
      this.employee = null;
      this.year = null;
    },

    confirm() {
      this.isOpen = false;
      this.$api.http
        .post(
          `api/accounting-periods/employee/${this.employee.id}/${this.year}-01-01/calc`
        )
        .then(() => {
          this.$toast.open("Erfolgreich neu berechnet.");
          this.reset();
          this.$emit("refresh");
        })
        .catch(() => {
          this.$toast.open({
            message: "Etwas ist schiefgelaufen",
            type: "error",
          });
        });
    },
  },
};
</script>
