<template>
  <div class="main-container">
    <v-container fluid>
      <v-row>
        <v-col cols="12" sm="10">
          <h1 class="text-h4">Hallo {{ user.name }}</h1>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="6">
          <task-table-overview
            :allProjects="allProjects"
            :allMyTasks="allMyTasks"
          ></task-table-overview>
        </v-col>

        <v-col cols="12" md="6">
          <project-table-overview></project-table-overview>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { mapState } from "vuex";
import TaskTableOverview from "@/components/tables/TaskTableOverview";
import ProjectTableOverview from "@/components/tables/ProjectTableOverview";

export default {
  name: "dashboard",

  components: {
    TaskTableOverview,
    ProjectTableOverview,
  },

  computed: {
    ...mapState("app", ["configs"]),
    ...mapState("user", ["user"]),
  },

  data() {
    return {
      allProjects: [],
      allMyTasks: [],
    };
  },

  mounted() {
    this.fetchProjects();
    this.fetchMyTasks();
  },

  methods: {
    fetchProjects() {
      let projectFilter = "";
      this.configs.project_settings.time_track.forEach((s) => {
        projectFilter += `status[]=${s}&`;
      });

      this.$api.http
        .get(`api/projects/list?${projectFilter}hideLockedBudget=false`)
        .then((res) => {
          this.allProjects = res.data;
        })
        .catch(() => console.error("Unhandled Exception"));
    },

    fetchMyTasks() {
      this.$api.http
        .get(`api/tasks?employee_id=${this.user.employee.id}`)
        .then((res) => {
          this.allMyTasks = res.data.data;
        })
        .catch(() => console.error("Unhandled Exception"));
    },
  },
};
</script>
