<template>
  <v-card class="tt-card">
    <v-card-title class="header">
      <div class="grow">
        {{ getTitle }}
        <v-tooltip bottom v-if="mode === 'read'">
          <template v-slot:activator="{ on }">
            <v-icon v-on="on" color="white" class="ml-4">info_outline</v-icon>
          </template>
          <span>Monat bereits abgeschlossen</span>
        </v-tooltip>
      </div>
      <v-btn
        v-if="mode === 'edit'"
        class="action"
        @click="mode = 'create'"
        :small="!$vuetify.breakpoint.mdAndUp"
        :fab="!$vuetify.breakpoint.mdAndUp"
      >
        <v-icon :left="$vuetify.breakpoint.mdAndUp">add</v-icon>
        {{ $vuetify.breakpoint.mdAndUp ? "Neuen Eintrag" : "" }}
      </v-btn>
      <v-btn
        v-if="mode === 'edit'"
        class="action"
        @click="mode = 'duplicate'"
        :small="!$vuetify.breakpoint.mdAndUp"
        :fab="!$vuetify.breakpoint.mdAndUp"
      >
        <v-icon :left="$vuetify.breakpoint.mdAndUp">content_copy</v-icon>
        {{ $vuetify.breakpoint.mdAndUp ? "Eintrag Duplizieren" : "" }}
      </v-btn>
    </v-card-title>
    <v-card-text class="pt-4">
      <time-entry-form
        :mode="mode"
        :input="selectedEntry"
        :taskInput="selectedTask"
        :duration="durationSelection"
        :allProjects="allProjects"
        :allMyTasks="allMyTasks"
        @deleted="handleChange"
        @saved="handleChange"
        @cancel="cancel"
      ></time-entry-form>
    </v-card-text>
  </v-card>
</template>

<script>
import TimeEntryForm from "@/components/forms/TimeEntryForm";

export default {
  components: {
    TimeEntryForm,
  },

  props: {
    selectedEntry: {
      required: true,
    },
    selectedTask: {
      default: null,
    },
    durationSelection: {
      default: null,
    },
    allProjects: {
      required: true,
    },
    allMyTasks: {
      required: true,
    },
    notConcluded: {
      default() {
        return [];
      },
    },
  },

  computed: {
    getTitle() {
      if (this.mode === "create") {
        return "Zeiteintrag erfassen";
      } else if (this.mode === "edit") {
        return "Zeiteintrag bearbeiten";
      } else if (this.mode === "duplicate") {
        return "Zeiteintrag dupliziert";
      } else {
        return "Zeiteintrag";
      }
    },
  },

  mounted() {
    if (this.selectedEntry) {
      this.mode = "edit";
    }
  },

  data() {
    return {
      mode: "create", // Modes available: create, edit, duplicate, read
    };
  },

  watch: {
    selectedEntry(value) {
      if (value) {
        const isEditable = this.$timeEntryService.isEditable(
          this.notConcluded,
          value
        );
        this.mode = isEditable ? "edit" : "read";
      }
    },
  },

  methods: {
    handleChange() {
      this.mode = "create";
      this.$emit("changed");
    },

    cancel() {
      this.mode = "create";
      this.$emit("cancel");
    },
  },
};
</script>
