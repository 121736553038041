var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',{staticClass:"tt-card",attrs:{"color":"default","outlined":""}},[_c('v-card-title',{staticClass:"header",staticStyle:{"position":"relative"}},[_c('v-menu',{attrs:{"bottom":"","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"left":""}},[_vm._v("keyboard_arrow_down")]),_vm._v(" "+_vm._s(_vm.selectedYear)+" ")],1)]}}])},[_c('v-list',_vm._l((_vm.years),function(year){return _c('v-list-item',{key:year,on:{"click":function($event){_vm.selectedYear = year}}},[_vm._v(" "+_vm._s(year)+" ")])}),1)],1),_c('div',{staticClass:"ml-4 grow"},[_vm._v("Abrechnungsperioden")]),(!_vm.edit)?_c('div',[_c('v-btn',{on:{"click":_vm.openRecalcDialog}},[_vm._v(" Neu berechnen ab "+_vm._s(_vm.selectedYear)+" ")]),_c('v-btn',{staticClass:"ml-4",on:{"click":function($event){_vm.edit = true}}},[_vm._v("Bearbeiten")])],1):_c('div',[_c('v-btn',{attrs:{"text":"","color":"white"},on:{"click":_vm.cancel}},[_vm._v("Abbrechen")]),_c('v-btn',{staticClass:"ml-4",on:{"click":_vm.save}},[_vm._v("Speichern")])],1)],1),_c('v-card-text',[_c('remote-data-table',{ref:"accountingPeriodDataTable",staticClass:"table",attrs:{"url":_vm.listUrl,"headers":_vm.headers},scopedSlots:_vm._u([{key:"item.month",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-caption",staticStyle:{"white-space":"nowrap"}},[_vm._v(" "+_vm._s(_vm.getMonthName(item.start_date))+" ")])]}},{key:"item.time_target",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-caption",staticStyle:{"white-space":"nowrap"}},[_vm._v(" "+_vm._s(item.time_target.toFixed(2))+" ")])]}},{key:"item.time_clocked",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-caption",staticStyle:{"white-space":"nowrap"}},[_vm._v(" "+_vm._s(item.time_clocked.toFixed(2))+" ")])]}},{key:"item.time_difference",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-caption",staticStyle:{"white-space":"nowrap"}},[_vm._v(" "+_vm._s(item.time_difference.toFixed(2))+" ")])]}},{key:"item.time_balance",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-caption",staticStyle:{"white-space":"nowrap"}},[_vm._v(" "+_vm._s(item.time_balance.toFixed(2))+" ")])]}},{key:"item.time_change",fn:function(ref){
var item = ref.item;
return [_c('div',[(!_vm.edit)?_c('div',[_vm._v(" "+_vm._s(item.time_change.toFixed(2))+" ")]):_c('v-text-field',{style:({ maxWidth: '80px' }),attrs:{"dense":"","type":"number","value":item.time_change},on:{"input":function (value) { return _vm.handleInput(value, item, 'time_change'); }}})],1)]}},{key:"item.holiday_taken",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-caption",staticStyle:{"white-space":"nowrap"}},[_vm._v(" "+_vm._s(item.holiday_taken.toFixed(2))+" ")])]}},{key:"item.holiday_balance",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-caption",staticStyle:{"white-space":"nowrap"}},[_vm._v(" "+_vm._s(item.holiday_balance.toFixed(2))+" ")])]}},{key:"item.holiday_target_hours",fn:function(ref){
var item = ref.item;
return [_c('div',[(!_vm.edit)?_c('div',[_vm._v(" "+_vm._s(item.holiday_target_hours.toFixed(2))+" ")]):_c('v-text-field',{style:({ maxWidth: '80px' }),attrs:{"dense":"","type":"number","value":item.holiday_target_hours},on:{"input":function (value) { return _vm.handleInput(value, item, 'holiday_target_hours'); }}})],1)]}},{key:"item.holiday_change",fn:function(ref){
var item = ref.item;
return [_c('div',[(!_vm.edit)?_c('div',[_vm._v(" "+_vm._s(item.holiday_change.toFixed(2))+" ")]):_c('v-text-field',{style:({ maxWidth: '80px' }),attrs:{"dense":"","type":"number","value":item.holiday_change},on:{"input":function (value) { return _vm.handleInput(value, item, 'holiday_change'); }}})],1)]}}],null,true)})],1)],1),_c('confirm-recalc-dialog',{ref:"confirmRecalcDialog",on:{"refresh":_vm.refresh}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }