import moment from "moment";

export default class HolidayService {
  url = "api/holidays";
  DAYS = ["mon", "tue", "wed", "thu", "fri", "sat", "sun"];

  constructor(api) {
    this.api = api;
  }

  getHolidaysByYear(year) {
    return this.api.http.get(`${this.url}/year/${year}`);
  }

  getHolidaysByWeek(week) {
    return this.api.http.get(`${this.url}/week/${week}`);
  }

  deleteHoliday(id) {
    return this.api.http.delete(`${this.url}/${id}`);
  }

  updateHolidays(holidays) {
    return this.api.http.put(`${this.url}`, holidays);
  }

  getFCClassName(date) {
    const day = moment(date).isoWeekday() - 1;
    return `fc-day-${this.DAYS[day]}`;
  }

  parseHolidays(holidays) {
    return holidays.map((holiday) => {
      return {
        id: holiday.id,
        title: holiday.name,
        start: moment(holiday.date).format("YYYY-MM-DD"),
        end: moment(holiday.date).format("YYYY-MM-DD"),
        color: "var(--v-special-base)",
        allDay: true,
      };
    });
  }
}
