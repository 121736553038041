<template>
  <v-dialog v-model="isOpen" max-width="1200" scrollable origin="top center">
    <v-card v-if="workload">
      <v-card-title class="primary white--text pb-4 mb-5">
        Tasks vom {{ workload.date | formatDate }}
      </v-card-title>
      <v-card-text v-if="ready">
        <v-row>
          <v-col> </v-col>
          <v-col cols="auto">
            <v-alert
              dense
              color="secondary"
              border="bottom"
              elevation="0"
              colored-border
            >
              <strong>Geplant:</strong>
              {{ workload.time_expected | formatHours }}
              Std.
            </v-alert>
          </v-col>
          <v-col cols="auto">
            <v-alert
              dense
              color="secondary"
              border="bottom"
              elevation="0"
              colored-border
            >
              <strong>Verfügbar:</strong>
              {{ workload.time_available | formatHours }}
              Std.
            </v-alert>
          </v-col>
        </v-row>

        <v-simple-table class="day-details-table">
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left">Titel</th>
                <th class="text-left">Start</th>
                <th class="text-left">Ende</th>
                <th class="text-left">Aufwand (Std.)</th>
                <th class="text-left">Budget</th>
                <th class="text-left">Projekt</th>
                <th class="text-right">MitarbeiterIn</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="task in tasks" :key="task.id">
                <td class="font-weight-bold">{{ task.name }}</td>
                <td>{{ task.starts_at | formatDate }}</td>
                <td>{{ task.ends_at | formatDate }}</td>
                <td>
                  {{ (task.time_expected / task.num_days) | formatHours }}
                </td>
                <td>{{ task.budget.name }}</td>
                <td>{{ task.budget.project.name }}</td>
                <td class="text-right">
                  <template v-if="task.employee">
                    <v-tooltip top>
                      <template v-slot:activator="{ on }">
                        <div v-on="on">
                          <div
                            v-if="task.employee.avatar"
                            class="avatar"
                            :style="{
                              backgroundImage:
                                'url(' + task.employee.avatar + ')',
                            }"
                          >
                            <span class="invisible">{{
                              task.employee.acronym
                            }}</span>
                          </div>
                          <div v-else class="avatar none">
                            <span>{{ task.employee.acronym }}</span>
                          </div>
                        </div>
                      </template>
                      <span>{{ task.employee.name }}</span>
                    </v-tooltip>
                  </template>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-card-text>
      <v-card-text v-else>
        <v-progress-linear indeterminate color="primary"></v-progress-linear>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" text @click="close()"> Schliessen </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState } from "vuex";

export default {
  data: () => ({
    isOpen: false,
    ready: false,
    tasks: [],
    workload: null,
  }),

  computed: {
    ...mapState("rp", [
      "projectStatusFilter",
      "projectTemplatesFilter",
      "projectsFilter",
      "customersFilter",
      "employeeTasksFilter",
      "employeeManagersFilter",
      "statusFilter",
      "scheduledFilter",
    ]),
  },

  methods: {
    open(workload) {
      this.isOpen = true;
      this.workload = workload;
      this.fetchTasks(workload);
    },

    close() {
      this.isOpen = false;
      this.reset();
    },

    reset() {
      this.ready = false;
      this.workload = null;
    },

    fetchTasks(workload) {
      let params = { date: workload.date };
      if (this.projectStatusFilter.length > 0) {
        params["_projects_status_filter"] = this.projectStatusFilter.join(",");
      }
      if (this.projectTemplatesFilter.length > 0) {
        params["_projects_templates_filter"] =
          this.projectTemplatesFilter.join(",");
      }
      if (this.projectsFilter.length > 0) {
        params["_projects_filter"] = this.projectsFilter.join(",");
      }
      if (this.customersFilter.length > 0) {
        params["_customers_filter"] = this.customersFilter.join(",");
      }
      if (this.employeeTasksFilter.length > 0) {
        params["_employee_tasks_filter"] = this.employeeTasksFilter.join(",");
      }
      if (this.employeeManagersFilter.length > 0) {
        params["_employee_managers_filter"] =
          this.employeeManagersFilter.join(",");
      }
      if (this.statusFilter) {
        params["_project_status"] = this.statusFilter;
      }
      if (this.scheduledFilter) {
        params["scheduled"] = this.scheduledFilter;
      }
      this.$api.http.get("api/tasks", { params: params }).then((res) => {
        if (res.data && res.data.data && res.data.data.length > 0) {
          this.tasks = res.data.data;
        }
        this.ready = true;
      });
    },
  },
};
</script>

<style lang="scss">
.day-details-table {
  .avatar {
    display: inline-block;
    width: 42px;
    height: 42px;
    border-radius: 50%;
    line-height: 42px;
    text-align: center;
    font-size: 14px;
    text-transform: uppercase;
    background-size: 100%;
    margin: 4px 0;

    span.invisible {
      visibility: hidden;
    }

    &.none {
      background: var(--v-accent-base);
    }
  }
}
</style>
