<template>
  <v-autocomplete
    :value="value"
    @input="select"
    :items="items"
    :filter="filter"
    :label="label"
    single-line
    item-value="id"
    item-text="name"
    :readonly="readonly"
    :disabled="items.length === 0 || readonly"
    :multiple="multiple"
    clearable
  >
    <template v-slot:selection="data">
      <v-chip
        v-if="chip"
        :key="data.item.id"
        close
        @click:close="removeItem(data.item)"
      >
        {{ data.item.name }}
      </v-chip>
      <span v-else :key="data.item.id">
        {{ data.item.name }}
      </span>
    </template>
    <template v-slot:label>
      <span :class="{ required: required, disabled: items.length === 0 }">
        {{ getLabel }}
      </span>
    </template>
  </v-autocomplete>
</template>

<script>
export default {
  props: {
    value: {
      required: true,
    },
    items: {
      required: true,
      type: Array,
    },
    label: {
      required: false,
      default: "Benutzer",
      type: String,
    },
    required: {
      type: Boolean,
      default: false,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    chip: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    getLabel() {
      return this.required ? `${this.label} *` : this.label;
    },
  },

  data() {
    return {
      selectionRule: this.required
        ? [(v) => !!v || "Muss selektiert werden"]
        : [],
    };
  },

  methods: {
    select(value) {
      this.$emit("input", value);
      this.$emit("selected", value);
    },

    filter(item, queryText) {
      const name = item.name.toLowerCase();
      const query = queryText.toLowerCase();

      return name.indexOf(query) > -1;
    },

    removeItem(user) {
      let tmp = [...this.value];
      const userIndex = this.value.findIndex((id) => id === user.id);

      if (userIndex > -1) {
        tmp.splice(userIndex, 1);
        this.$emit("input", tmp);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.required {
  font-weight: 500;
  color: black;
}

.disabled {
  color: black;
  opacity: 0.6;
}
</style>
