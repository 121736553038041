import moment from "moment";

const rp = {
  namespaced: true,

  state: {
    view: "projects",
    projectStatusFilter: [],
    projectTemplatesFilter: [],
    projectsFilter: [],
    customersFilter: [],
    employeeTasksFilter: [],
    employeeManagersFilter: [],
    statusFilter: "active",
    scheduledFilter: true,
    showWorkloads: true,
    startDate: moment(),
    endDate: moment().add(14, "days"),
  },

  getters: {},

  mutations: {
    /**
     * Initialize store data from local storage.
     * @param {*} state
     */
    initStore(state) {
      if (localStorage.getItem("rp_view")) {
        state.view = localStorage.getItem("rp_view");
      }
      if (localStorage.getItem("rp_projectStatusFilter")) {
        state.projectStatusFilter = JSON.parse(
          localStorage.getItem("rp_projectStatusFilter")
        );
      }
      if (localStorage.getItem("rp_projectTemplatesFilter")) {
        state.projectTemplatesFilter = JSON.parse(
          localStorage.getItem("rp_projectTemplatesFilter")
        );
      }
      if (localStorage.getItem("rp_projectsFilter")) {
        state.projectsFilter = JSON.parse(
          localStorage.getItem("rp_projectsFilter")
        );
      }
      if (localStorage.getItem("rp_customersFilter")) {
        state.customersFilter = JSON.parse(
          localStorage.getItem("rp_customersFilter")
        );
      }
      if (localStorage.getItem("rp_employeeTasksFilter")) {
        state.employeeTasksFilter = JSON.parse(
          localStorage.getItem("rp_employeeTasksFilter")
        );
      }
      if (localStorage.getItem("rp_employeeManagersFilter")) {
        state.employeeManagersFilter = JSON.parse(
          localStorage.getItem("rp_employeeManagersFilter")
        );
      }
      if (localStorage.getItem("rp_statusFilter")) {
        state.statusFilter = localStorage.getItem("rp_statusFilter");
      }
      if (localStorage.getItem("rp_scheduledFilter")) {
        state.scheduledFilter =
          localStorage.getItem("rp_scheduledFilter") === "true";
      }
      if (localStorage.getItem("rp_showWorkloads")) {
        state.showWorkloads = localStorage.getItem("rp_showWorkloads");
      }
      if (localStorage.getItem("rp_startDate")) {
        state.startDate = moment(localStorage.getItem("rp_startDate"));
      }
      if (localStorage.getItem("rp_endDate")) {
        state.endDate = moment(localStorage.getItem("rp_endDate"));
      }
    },

    /**
     * Set view.
     * @param {*} state
     * @param {*} view
     */
    setView(state, view) {
      state.view = view;
      localStorage.setItem("rp_view", view);
    },

    /**
     * Set filter project Status IDs.
     * @param {*} state
     * @param {*} statusIds
     */
    setProjectStatusFilter(state, statusIds) {
      state.projectStatusFilter = statusIds;
      localStorage.setItem("rp_projectStatusFilter", JSON.stringify(statusIds));
    },

    /**
     * Set filter project templates IDs.
     * @param {*} state
     * @param {*} templateIds
     */
    setProjectTemplatesFilter(state, templateIds) {
      state.projectTemplatesFilter = templateIds;
      localStorage.setItem(
        "rp_projectTemplatesFilter",
        JSON.stringify(templateIds)
      );
    },

    /**
     * Set filter project IDs.
     * @param {*} state
     * @param {*} projectIDs
     */
    setProjectsFilter(state, projectIDs) {
      state.projectsFilter = projectIDs;
      localStorage.setItem("rp_projectsFilter", JSON.stringify(projectIDs));
    },

    /**
     * Set filter customer names.
     * @param {*} state
     * @param {*} customerNames
     */
    setCustomersFilter(state, customerNames) {
      state.customersFilter = customerNames;
      localStorage.setItem("rp_customersFilter", JSON.stringify(customerNames));
    },

    /**
     * Set filter employee tasks IDs.
     * @param {*} state
     * @param {*} teamIDs
     */
    setEmployeeTasksFilter(state, employeeTasksFilter) {
      state.employeeTasksFilter = employeeTasksFilter;
      localStorage.setItem(
        "rp_employeeTasksFilter",
        JSON.stringify(employeeTasksFilter)
      );
    },

    /**
     * Set filter manager IDs.
     * @param {*} state
     * @param {*} teamIDs
     */
    setEmployeeManagersFilter(state, employeeManagersFilter) {
      state.employeeManagersFilter = employeeManagersFilter;
      localStorage.setItem(
        "rp_employeeManagersFilter",
        JSON.stringify(employeeManagersFilter)
      );
    },

    /**
     * Set status filter.
     * @param {*} state
     * @param {*} statusFilter
     */
    setStatusFilter(state, statusFilter) {
      state.statusFilter = statusFilter;
      localStorage.setItem("rp_statusFilter", statusFilter);
    },

    /**
     * Set scheduled filter.
     * @param {*} state
     * @param {*} scheduledFilter
     */
    setScheduledFilter(state, scheduledFilter) {
      state.scheduledFilter = scheduledFilter;
      localStorage.setItem("rp_scheduledFilter", scheduledFilter);
    },

    /**
     * Set if worklows should be shown.
     * @param {*} state
     * @param {*} showWorkloads
     */
    setShowWorkloads(state, showWorkloads) {
      state.showWorkloads = showWorkloads;
      localStorage.setItem("rp_showWorkloads", showWorkloads);
    },

    /**
     * Set start date.
     * @param {*} state
     * @param {*} startDate
     */
    setStartDate(state, startDate) {
      state.startDate = startDate;
      localStorage.setItem("rp_startDate", startDate.format("YYYY-MM-DD"));
    },

    /**
     * Set end date.
     * @param {*} state
     * @param {*} endDate
     */
    setEndDate(state, endDate) {
      state.endDate = endDate;
      localStorage.setItem("rp_endDate", endDate.format("YYYY-MM-DD"));
    },
  },
};

export default rp;
