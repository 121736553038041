export default class BudgetTimeService {
  constructor(tracked, planned, offered) {
    this.tracked = tracked;
    this.planned = planned;
    this.offered = offered;

    this.left = offered - tracked - planned;
    if (Math.abs(this.left) < Number.EPSILON) {
      this.left = 0;
    }

    if (this.left < 0) {
      this.total = tracked + planned + Math.abs(this.left);
    } else {
      this.total = offered;
    }
  }

  getLabel(mode = "task") {
    return {
      tracked: this.trackedLabel(mode),
      planned: this.plannedLabel(mode),
      left: this.leftLabel(mode),
      trackedTooltip: this.trackedTooltip(mode),
      plannedTooltip: this.plannedTooltip(mode),
      leftTooltip: this.leftTooltipLabel(mode),
    };
  }

  getVueStyle() {
    return {
      box: this.boxStyle(),
      tracked: this.trackedStyle(),
      planned: this.plannedStyle(),
      left: this.leftStyle(),
    };
  }

  getCssStyle() {
    return {
      box: this.boxStyle(true),
      tracked: this.trackedStyle(true),
      planned: this.plannedStyle(true),
      left: this.leftStyle(true),
    };
  }

  trackedLabel(mode) {
    if (mode === "holiday") {
      return this.tracked === 0 ? "" : this.round(this.tracked);
    }
    return this.tracked === 0 ? "" : this.round(this.tracked) + "h";
  }

  plannedLabel(mode) {
    if (mode === "holiday") {
      return this.planned === 0 ? "" : this.round(this.planned);
    }
    return this.planned === 0 ? "" : this.round(this.planned) + "h";
  }

  leftLabel(mode) {
    if (this.left === 0) {
      if (this.tracked + this.planned === 0) {
        if (mode === "holiday") {
          return 0;
        }
        return "0h";
      }
      return "";
    }

    if (mode === "holiday") {
      return this.left > 0
        ? this.round(this.left)
        : Math.abs(this.round(this.left));
    }

    return this.left > 0
      ? this.round(this.left) + "h"
      : Math.abs(this.round(this.left)) + "h";
  }

  trackedTooltip(mode) {
    if (mode === "holiday") {
      return "Bezogen";
    }
    return "Erfasst";
  }

  plannedTooltip(mode) {
    if (mode === "holiday") {
      return "Geplant";
    }
    return "Geplant";
  }

  leftTooltipLabel(mode) {
    if (mode === "holiday") {
      return this.left >= 0 ? "Verfügbar" : "Überzogen";
    }
    return this.left >= 0 ? "Frei" : "Überzogen";
  }

  trackedGrow() {
    if (this.total === 0) {
      return 0;
    }
    return (1 / this.total) * this.tracked;
  }

  plannedGrow() {
    if (this.total === 0) {
      return 0;
    }
    return (1 / this.total) * this.planned;
  }

  leftGrow() {
    if (this.left === 0 && this.plannedGrow() + this.trackedGrow() > 0) {
      return 0;
    }

    if (
      1 - this.trackedGrow() - this.plannedGrow() < Number.EPSILON ||
      this.trackedGrow() === 1 ||
      this.plannedGrow() === 1
    ) {
      return 1;
    }
    if (this.trackedGrow() === 1 || this.plannedGrow() === 1) {
      return 1;
    }
    return 1 - this.trackedGrow() - this.plannedGrow();
  }

  boxStyle(css = false) {
    if (css) {
      return (
        `display:flex;` + `width:100%;` + `max-width:100%;` + `margin:8px 0;`
      );
    }
    return {
      display: "flex",
      width: "100%",
      maxWidth: "100%",
      height: "100%",
    };
  }

  trackedStyle(css = false) {
    if (css) {
      return (
        `flex-grow: ${this.trackedGrow()};` +
        `background-color: #344056;` +
        `color: white;` +
        `border-radius: 8px 0 0 8px;` +
        `padding: 4px 8px;` +
        `text-align: center;`
      );
    }

    return {
      flexGrow: this.trackedGrow(),
      backgroundColor: "var(--v-primary-base)",
      color: "white",
      borderRadius: "8px 0 0 8px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    };
  }

  plannedStyle(css = false) {
    let borderRadius = this.tracked === 0 ? "8px 0 0 8px" : "0";
    if (this.left === 0) {
      borderRadius = this.tracked === 0 ? "8px" : "0 8px 8px 0";
    }

    if (css) {
      return (
        `flex-grow: ${this.plannedGrow()};` +
        `border-radius: ${borderRadius};` +
        `background-color: #06a1ea;` +
        `color: white;` +
        `padding: 4px 8px;` +
        `text-align: center;`
      );
    }

    return {
      flexGrow: this.plannedGrow(),
      borderRadius,
      backgroundColor: "var(--v-secondary-base)",
      color: "white",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    };
  }

  leftStyle(css = false) {
    const color =
      this.left >= 0 ? "var(--v-success-base)" : "var(--v-error-base)";
    const borderRadius =
      this.tracked + this.planned === 0 ? "8px" : "0 8px 8px 0";

    if (css) {
      return (
        `flex-grow: ${this.leftGrow()};` +
        `background-color: ${color};` +
        `border-radius: ${borderRadius};` +
        `color:white;` +
        `padding: 4px 8px;` +
        `text-align: center;`
      );
    }

    return {
      flexGrow: this.leftGrow(),
      backgroundColor: color,
      borderRadius,
      color: "white",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    };
  }

  round(number) {
    return Math.floor(number * 100) / 100;
  }
}
