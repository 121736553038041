<template>
  <div v-if="$vuetify.breakpoint.mdAndUp">
    <v-row style="align-items: center">
      <v-btn-toggle
        :value="value"
        @change="(v) => $emit('input', v)"
        class="mx-3"
      >
        <v-btn value="calendar"><v-icon>view_week</v-icon></v-btn>
        <v-btn value="list"><v-icon>view_list</v-icon></v-btn>
      </v-btn-toggle>

      <v-col>
        <h1 class="text-h4">Zeiterfassung</h1>
      </v-col>
      <v-col cols="12" sm="auto" style="text-align: end">
        <v-menu bottom offset-y max-height="352">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              class="px-2"
              :style="{ minWidth: '30px', borderRadius: '4px 0 0 4px' }"
              :disabled="notConcluded.length === 0"
            >
              <v-icon>arrow_drop_down</v-icon>
            </v-btn>
          </template>
          <v-list dense btn>
            <v-list-item
              v-for="month in notConcluded"
              :key="month.id"
              @click="selectedPeriod = month.start_date"
            >
              {{ accountingPeriodMonth(month.start_date) }}
            </v-list-item>
          </v-list>
        </v-menu>
        <v-btn
          color="error"
          :style="{ minWidth: '30px', borderRadius: '0 4px 4px 0 ' }"
          :disabled="notConcluded.length === 0"
          @click="openConcludeDialog"
        >
          {{ accountingPeriodMonth(selectedPeriod) }} Abschliessen
        </v-btn>
      </v-col>
    </v-row>
    <v-row class="mb-2">
      <v-col cols="12" md="6" lg="3">
        <time-overview-card
          :detail="displayWeek"
          title="Woche"
          :text="weekTotal"
        >
        </time-overview-card>
      </v-col>
      <v-col cols="12" md="6" lg="3">
        <time-overview-card
          :detail="displayMonth"
          title="Monat"
          :text="monthTotal"
        >
        </time-overview-card>
      </v-col>
      <v-col cols="12" md="6" lg="3">
        <time-overview-card
          :detail="endOfLastPeriod"
          title="Zeitkonto"
          :text="timeAccount"
        >
        </time-overview-card>
      </v-col>
      <v-col cols="12" md="6" lg="3">
        <time-overview-card :detail="endOfYear" title="Ferien">
          <template>
            <budget-time-diagram
              v-if="overview"
              :tracked="overview.holiday_taken_year"
              :planned="overview.holiday_planned_year"
              :offered="overview.holiday_balance_year"
              mode="holiday"
            ></budget-time-diagram>
          </template>
        </time-overview-card>
      </v-col>
    </v-row>
    <confirm-conclude-dialog
      @concluded="$emit('refresh')"
      ref="concludeDialog"
    ></confirm-conclude-dialog>
  </div>
</template>

<script>
import { mapState } from "vuex";
import moment from "moment";
import TimeOverviewCard from "@/components/cards/TimeOverviewCard";
import BudgetTimeDiagram from "@/components/diagram/BudgetTimeDiagram";
import ConfirmConcludeDialog from "@/components/dialogs/ConfirmConcludeDialog";

export default {
  components: {
    TimeOverviewCard,
    BudgetTimeDiagram,
    ConfirmConcludeDialog,
  },

  emit: ["refresh"],

  props: {
    notConcluded: {
      required: true,
    },
    value: {
      required: true,
    },
  },

  computed: {
    ...mapState("user", ["user"]),

    displayWeek() {
      const start = moment(this.currentWeek).format("DD.MM.YYYY");
      const end = moment(this.currentWeek).endOf("week").format("DD.MM.YYYY");
      return `${start}. - ${end}`;
    },

    displayMonth() {
      return moment(this.currentMonth).format("MMMM YYYY");
    },

    endOfLastPeriod() {
      return (
        "per " +
        moment(this.currentMonth)
          .subtract(1, "months")
          .endOf("month")
          .format("DD. MMMM YYYY")
      );
    },

    endOfYear() {
      const year = moment().endOf("year").format("D. MMMM YYYY");
      return "per " + year;
    },

    timeAccount() {
      if (this.overview) {
        return this.overview.time_balance_prev.toFixed(2);
      }
      return "";
    },

    weekTotal() {
      if (this.overview) {
        const clocked = this.overview.time_clocked_week.toFixed(2);
        const target = this.overview.time_target_week.toFixed(2);
        return `${clocked}/${target}`;
      }
      return "";
    },

    monthTotal() {
      if (this.overview) {
        const clocked = this.overview.time_clocked_month.toFixed(2);
        const target = this.overview.time_target_month.toFixed(2);
        return `${clocked}/${target}`;
      }
      return "";
    },
  },

  mounted() {
    this.fetchOverview();
  },

  data() {
    return {
      selectedPeriod: moment().startOf("month").format("YYYY-MM-DD"),
      overview: null,
      currentMonth: moment().startOf("month").format("YYYY-MM-DD"),
      currentWeek: moment().startOf("isoWeek").format("YYYY-MM-DD"),
    };
  },

  watch: {
    notConcluded(value) {
      if (value && value.length > 0) {
        this.selectedPeriod = value[0].start_date;
      } else {
        this.selectedPeriod = moment().startOf("month").format("YYYY-MM-DD");
      }
    },
  },

  methods: {
    fetchOverview() {
      this.$accountingPeriodService
        .getOverview(this.user.employee.id)
        .then((res) => {
          this.overview = res.data;
        })
        .catch((err) => console.error(err));
    },

    openConcludeDialog() {
      if (this.notConcluded.length > 0) {
        const selected = this.notConcluded.find((month) =>
          moment(month.start_date).isSame(this.selectedPeriod, "month")
        );
        if (selected) {
          this.$refs.concludeDialog.open(this.selectedPeriod, selected);
        }
      }
    },

    accountingPeriodMonth(date) {
      return moment(date).format("MMMM YYYY");
    },

    refresh() {
      this.fetchOverview();
    },
  },
};
</script>

<style lang="scss" scoped></style>
