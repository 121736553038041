const fullscreen = {
  namespaced: true,

  state: {
    active: false,
  },

  getters: {},

  mutations: {
    /**
     * Toggle fullscreen.
     *
     * @param {*} state
     * @param {*} sMessage
     */
    toggleFullscreen(state) {
      var elem = document.documentElement;
      if (
        document.fullscreenEnabled ||
        document.webkitFullscreenEnabled ||
        document.mozFullScreenEnabled ||
        document.msFullscreenEnabled
      ) {
        if (!state.active || !document.fullscreenElement) {
          if (elem.requestFullscreen) {
            elem.requestFullscreen();
            state.active = true;
            return;
          } else if (elem.webkitRequestFullscreen) {
            elem.webkitRequestFullscreen();
            state.active = true;
            return;
          } else if (elem.mozRequestFullScreen) {
            elem.mozRequestFullScreen();
            state.active = true;
            return;
          } else if (elem.msRequestFullscreen) {
            elem.msRequestFullscreen();
            state.active = true;
            return;
          }
        } else {
          if (document.exitFullscreen) {
            document.exitFullscreen();
            state.active = false;
            return;
          } else if (document.webkitExitFullscreen) {
            document.webkitExitFullscreen();
            state.active = false;
            return;
          } else if (document.mozCancelFullScreen) {
            document.mozCancelFullScreen();
            state.active = false;
            return;
          } else if (document.msExitFullscreen) {
            document.msExitFullscreen();
            state.active = false;
            return;
          }
        }
      }
    },
  },
};

export default fullscreen;
