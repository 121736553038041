<template>
  <div
    :class="{ 'app-navigation-item': true, subitem: subitem }"
    v-if="!item.hidden"
  >
    <!-- Subheader -->
    <v-subheader v-if="item.subheader" :key="item.title">
      {{ item.title }}
    </v-subheader>

    <!-- Divider -->
    <v-divider v-else-if="item.divider"></v-divider>

    <!-- Action -->
    <v-list-item
      v-else-if="item.action"
      @click="item.action()"
      :active-class="'primary'"
      :key="item.title"
    >
      <v-list-item-icon>
        <v-icon>{{ item.icon }}</v-icon>
      </v-list-item-icon>
      <v-list-item-content>
        <v-list-item-title>{{ item.title }}</v-list-item-title>
      </v-list-item-content>
    </v-list-item>

    <!-- Link -->
    <v-list-item
      v-else
      link
      :to="item.link"
      :active-class="'primary'"
      :key="item.title"
    >
      <v-list-item-icon>
        <v-icon>{{ item.icon }}</v-icon>
      </v-list-item-icon>
      <v-list-item-content>
        <v-list-item-title>{{ item.title }}</v-list-item-title>
      </v-list-item-content>
    </v-list-item>
  </div>
</template>

<script>
export default {
  props: {
    item: {
      required: true,
    },
    subitem: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss">
.app-navigation-item .v-list-item__title,
.v-list-group .v-list-item__title {
  color: #ecedf1;
}
.v-application .app-navigation-item.subitem {
  .v-list-item__title {
    color: #ecedf1;
  }
}
.v-application .app-navigation-item .primary {
  background: linear-gradient(
    to right,
    var(--v-primary-darken4),
    var(--v-primary-darken1)
  );
  border-color: var(--v-primary-darken3) !important;
}
</style>
