<template>
  <v-container fluid>
    <employee-table></employee-table>
  </v-container>
</template>

<script>
import EmployeeTable from "@/components/tables/EmployeeTable";

export default {
  name: "employees",

  components: {
    EmployeeTable,
  },
};
</script>
