export default class SettingService {
  url = "api/settings";

  constructor(api) {
    this.api = api;
  }

  getSettings(category) {
    return this.api.http.get(`${this.url}/${category}`);
  }

  setSettings(settings) {
    return this.api.http.post(`${this.url}`, settings);
  }
}
