<template>
  <div>
    <v-card color="default" outlined class="tt-card">
      <v-card-title class="header" style="position: relative">
        Mitarbeiter
      </v-card-title>
      <v-card-text>
        <remote-data-table
          :url="'api/employees'"
          :search-enabled="true"
          :headers="headers"
          ref="employeesDatatable"
        >
          <template v-slot:[`item.id`]="{ item }">
            <router-link :to="`/employees/${item.id}`">
              {{ item.id }}
            </router-link>
          </template>
          <template v-slot:[`item.name`]="{ item }">
            <router-link :to="`/employees/${item.id}`">
              {{ item.name }}
            </router-link>
          </template>
          <template v-slot:[`item.is_plannable`]="{ item }">
            <v-icon v-if="item.is_plannable" color="success">check</v-icon>
            <v-icon v-else color="error">close</v-icon>
          </template>
          <template v-slot:[`item.is_manager`]="{ item }">
            <v-icon v-if="item.is_manager" color="success">check</v-icon>
          </template>
          <template v-slot:[`item.is_backoffice`]="{ item }">
            <v-icon v-if="item.is_backoffice" color="success">check</v-icon>
          </template>
          <template v-slot:[`item.is_support`]="{ item }">
            <v-icon v-if="item.is_support" color="success">check</v-icon>
          </template>
          <template v-slot:[`item.avatar`]="{ item }">
            <div
              v-if="item.avatar"
              class="avatar"
              :style="{ backgroundImage: 'url(' + item.avatar + ')' }"
            >
              <span class="invisible">{{ item.acronym }}</span>
            </div>
            <div v-else class="avatar none">
              <span>{{ item.acronym.substring(0, 2) }}</span>
            </div>
          </template>
        </remote-data-table>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import RemoteDataTable from "@/components/RemoteDataTable";

export default {
  components: {
    RemoteDataTable,
  },

  data: () => ({
    headers: [
      { text: "ID", align: "left", sortable: true, value: "id" },
      { text: "Name", align: "left", sortable: true, value: "name" },
      { text: "E-Mail", align: "left", sortable: true, value: "email" },
      { text: "Kürzel", align: "left", sortable: true, value: "acronym" },
      {
        text: "Planungsrelevant",
        align: "center",
        sortable: true,
        value: "is_plannable",
      },
      {
        text: "Manager",
        align: "center",
        sortable: true,
        value: "is_manager",
      },
      {
        text: "Backoffice",
        align: "center",
        sortable: true,
        value: "is_backoffice",
      },
      {
        text: "Support",
        align: "center",
        sortable: true,
        value: "is_support",
      },
      {
        text: "",
        align: "right",
        sortable: false,
        value: "avatar",
      },
    ],
  }),
};
</script>

<style lang="scss" scoped>
.avatar {
  display: inline-block;
  width: 42px;
  height: 42px;
  border-radius: 50%;
  line-height: 42px;
  text-align: center;
  font-size: 14px;
  text-transform: uppercase;
  background-size: 100%;
  margin: 4px 0;

  span.invisible {
    visibility: hidden;
  }

  &.none {
    background: var(--v-accent-base);
  }
}
</style>
