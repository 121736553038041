<template>
  <div class="task-status-label">
    <v-chip :color="color" label :small="small" :large="large">
      {{ text }}
    </v-chip>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  props: {
    task: {
      type: Object,
      required: true,
    },
    small: {
      type: Boolean,
      default: false,
    },
    large: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    ...mapState("app", ["configs"]),

    color() {
      if (
        this.configs.task_colors.success.find(
          (id) => id === parseInt(this.task.statusId)
        )
      ) {
        return "success";
      }

      if (
        this.configs.task_colors.warning.find(
          (id) => id === parseInt(this.task.statusId)
        )
      ) {
        return "warning";
      }

      return "error";
    },

    text() {
      const status = this.configs.task_statuses.find(
        (s) => s.remote_id === parseInt(this.task.statusId)
      );
      return status ? status.name : "Unbekannt";
    },
  },
};
</script>

<style lang="scss"></style>
