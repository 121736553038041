<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12" class="py-0">
        <admin-task-table @refresh="refresh"></admin-task-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapState, mapMutations, mapGetters } from "vuex";
import AdminTaskTable from "@/components/tables/admin/AdminTaskTable";

export default {
  components: {
    AdminTaskTable,
  },

  computed: {
    ...mapState("user", ["user"]),
    ...mapGetters("user", ["isManagerOrBackoffice"]),
  },

  methods: {
    ...mapMutations("app", ["setAdminTasks"]),

    refresh() {
      this.updateAdminTasks();
    },

    updateAdminTasks() {
      if (this.isManagerOrBackoffice) {
        this.$adminTaskService.getTasks().then((res) => {
          if (res.data) {
            this.setAdminTasks(res.data.data);
          }
        });
      }
    },
  },
};
</script>
