<template>
  <div class="budget-card">
    <div class="header">
      <v-chip color="grey darken-1" dark label small class="header-id">
        {{ budget.remote_id }}
      </v-chip>
      <v-tooltip top v-if="budget.is_locked">
        <template v-slot:activator="{ on }">
          <v-icon v-on="on" small class="header-lock">lock</v-icon>
        </template>
        <span>Gesperrt</span>
      </v-tooltip>
      <div class="header-name">{{ budget.name }}</div>

      <div class="header-hours">{{ overviewHours }}</div>
    </div>
    <div class="time-diagram">
      <budget-time-diagram
        :tracked="budget.time_used"
        :planned="budget.time_planned"
        :offered="budget.time_offered"
      ></budget-time-diagram>
    </div>
  </div>
</template>

<script>
import BudgetTimeDiagram from "@/components/diagram/BudgetTimeDiagram";

export default {
  components: {
    BudgetTimeDiagram,
  },

  props: ["budget"],

  computed: {
    overviewHours() {
      return (
        (this.budget.time_used + this.budget.time_planned).toFixed(2) +
        " / " +
        this.budget.time_offered +
        " Stunden"
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.budget-card {
  padding: 8px 16px;
  margin-bottom: 16px;

  .header {
    display: flex;
    align-items: center;
  }

  .header-name {
    color: black;
    font-size: 18px;
    font-weight: 500;
    margin-right: 4px;
    flex-grow: 1;
  }

  .header-lock {
    margin-right: 4px;
  }

  .header-id {
    margin-right: 8px;
  }

  .header-hours {
    color: black;
    font-size: 14px;
  }

  .time-diagram {
    height: 28px;
    margin: 8px 0;
  }
}
</style>
