import { render, staticRenderFns } from "./BillingPeriodeOverview.vue?vue&type=template&id=ebc679ac&scoped=true&"
import script from "./BillingPeriodeOverview.vue?vue&type=script&lang=js&"
export * from "./BillingPeriodeOverview.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ebc679ac",
  null
  
)

export default component.exports