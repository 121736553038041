<template>
  <v-app id="app">
    <template v-if="initialized || isLoginPage || isNoAccessPage">
      <app-navigation-drawer />
      <v-main class="main">
        <app-bar />
        <router-view></router-view>
      </v-main>
      <app-snackbar />
      <app-overlay />
      <app-fullscreen-dialog />
    </template>
    <template v-else>
      <app-loader />
    </template>
  </v-app>
</template>

<script>
import AppBar from "@/components/app/AppBar";
import AppNavigationDrawer from "@/components/app/AppNavigationDrawer";
import AppSnackbar from "@/components/app/AppSnackbar";
import AppOverlay from "@/components/app/AppOverlay";
import AppFullscreenDialog from "@/components/app/AppFullscreenDialog";
import AppLoader from "@/components/app/AppLoader";
import version from "../version.json";
import { mapMutations, mapState, mapGetters } from "vuex";

export default {
  components: {
    AppBar,
    AppNavigationDrawer,
    AppSnackbar,
    AppLoader,
    AppOverlay,
    AppFullscreenDialog,
  },

  created() {
    // Read version from version.json
    this.setVersion(version);
  },

  computed: {
    ...mapState("app", ["initialized"]),
    ...mapState("user", ["user"]),
    ...mapGetters("user", ["isManagerOrBackoffice"]),

    isLoginPage() {
      return this.$route.name === "login";
    },

    isNoAccessPage() {
      return this.$route.name === "no-access";
    },
  },

  watch: {
    user() {
      this.fetchNotifications();
      this.fetchActivities();
      this.fetchAdminTasks();
    },
  },

  methods: {
    ...mapMutations("app", [
      "setVersion",
      "setNotifications",
      "setAdminTasks",
      "setActivities",
    ]),

    fetchActivities() {
      this.$api.http
        .get(`api/activities`)
        .then((res) => this.setActivities(res.data))
        .catch((err) => console.error(err));
    },

    fetchNotifications() {
      this.$notificationService.getNotifications().then((res) => {
        this.setNotifications(res.data);
      });
    },

    fetchAdminTasks() {
      if (this.isManagerOrBackoffice) {
        this.$adminTaskService.getTasks().then((res) => {
          if (res.data) {
            this.setAdminTasks(res.data.data);
          }
        });
      }
    },
  },
};
</script>

<style lang="scss">
@import "~vuetify/src/styles/settings/_variables";

@media #{map-get($display-breakpoints, 'md-and-up')} {
  .container {
    margin: 16px !important;
    width: inherit !important;
  }
}

.main {
  background-color: aliceblue;
}

.v-btn {
  text-transform: none !important;
  letter-spacing: 0em !important;
}
.v-tab.v-tab {
  text-transform: none !important;
  letter-spacing: 0em !important;
}

.tt-card {
  .header {
    background-color: var(--v-accent-base);
    color: white;
    height: 56px;
    padding: 8px 16px !important;
    margin-bottom: 16px;

    .action {
      margin-left: 16px;
    }
  }
}

// @fullCalendar style
.fc {
  .fc-header-toolbar {
    background-color: var(--v-accent-base);
    color: white;
    height: 56px;
    padding: 8px 16px;
    border-radius: 4px 4px 0 0;
    margin-bottom: 16px !important;

    .fc-toolbar-title {
      font-size: 1.25rem;
      font-weight: 500;
    }

    .fc-button {
      background: var(--v-primary-base);
      color: white;

      .fc-icon {
        vertical-align: bottom;
      }
    }
  }

  .fc-day-sat {
    background: #efefef;
  }

  .fc-day-sun {
    background: #efefef;
  }
}

// vue toast
.v-toast {
  font-family: Roboto, "sans-serif";
}
</style>
