<template>
  <div>
    <v-card color="default" outlined class="tt-card">
      <v-card-title class="header" style="position: relative">
        <div class="title" v-if="$vuetify.breakpoint.mdAndUp">Absenzen</div>

        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              color="primary"
              fab
              dark
              absolute
              bottom
              right
              v-bind="attrs"
              v-on="on"
              @click="$emit('createAbsence')"
            >
              <v-icon>add</v-icon>
            </v-btn>
          </template>
          <span>Neuen Antrag stellen</span>
        </v-tooltip>
      </v-card-title>

      <v-card-text>
        <remote-data-table
          :url="listUrl"
          :headers="headers"
          :sort-by="'start'"
          ref="absenceDataTable"
          class="table"
          @dataItems="updateItems"
          @dblclick="editAbsence"
        >
          <template v-slot:[`item.start`]="{ item }">
            <div class="text-caption" style="white-space: nowrap">
              {{ formatDate(item.start) }}
            </div>
          </template>
          <template v-slot:[`item.end`]="{ item }">
            <div class="text-caption" style="white-space: nowrap">
              {{ formatDate(item.end) }}
            </div>
          </template>
          <template v-slot:[`item.type`]="{ item }">
            <div class="text-caption" style="white-space: nowrap">
              {{ getAbsenceType(item.type) }}
            </div>
          </template>
          <template v-slot:[`item.manager_request_state`]="{ item }">
            <div class="text-caption" style="white-space: nowrap">
              <v-tooltip bottom v-if="item.manager_request_state === 'pending'">
                <template v-slot:activator="{ on }">
                  <v-icon v-on="on" color="primary"> pending_actions </v-icon>
                </template>
                <span>In Bearbeitung</span>
              </v-tooltip>

              <v-tooltip bottom v-if="item.manager_request_state === 'denied'">
                <template v-slot:activator="{ on }">
                  <v-icon v-on="on" color="error">cancel</v-icon>
                </template>
                <span>Abgelehnt</span>
              </v-tooltip>

              <v-tooltip
                bottom
                v-if="item.manager_request_state === 'approved'"
              >
                <template v-slot:activator="{ on }">
                  <v-icon v-on="on" color="accent">check_circle</v-icon>
                </template>
                <span>Angenommen</span>
              </v-tooltip>
            </div>
          </template>
          <template v-slot:[`item.office_task_state`]="{ item }">
            <div class="text-caption" style="white-space: nowrap">
              <v-tooltip bottom v-if="item.office_task_state === 'waiting'">
                <template v-slot:activator="{ on }">
                  <v-icon v-on="on" color="primary">hourglass_empty</v-icon>
                </template>
                <span>Warten auf Annahme</span>
              </v-tooltip>

              <v-tooltip bottom v-if="item.office_task_state === 'inProgress'">
                <template v-slot:activator="{ on }">
                  <v-icon v-on="on" color="error">sync</v-icon>
                </template>
                <span>In Bearbeitung</span>
              </v-tooltip>

              <v-tooltip bottom v-if="item.office_task_state === 'done'">
                <template v-slot:activator="{ on }">
                  <v-icon v-on="on" color="accent">done</v-icon>
                </template>
                <span>Fertig</span>
              </v-tooltip>
            </div>
          </template>
          <template v-slot:[`item.action`]="{ item }">
            <v-menu bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn x-small v-bind="attrs" v-on="on" text fab>
                  <v-icon>more_vert</v-icon>
                </v-btn>
              </template>
              <v-list>
                <v-list-item @click="editAbsence(item)">
                  Bearbeiten
                </v-list-item>
                <v-list-item @click="deleteEntry(item.id, item.name)">
                  Löschen
                </v-list-item>
              </v-list>
            </v-menu>
          </template>
        </remote-data-table>
      </v-card-text>
    </v-card>

    <delete-absence-request-dialog
      ref="deleteAbsenceDialog"
      @deleted="refresh"
    ></delete-absence-request-dialog>
  </div>
</template>

<script>
import { mapState } from "vuex";
import moment from "moment";
import RemoteDataTable from "@/components/RemoteDataTable";
import DeleteAbsenceRequestDialog from "@/components/dialogs/DeleteAbsenceRequestDialog";

export default {
  components: {
    RemoteDataTable,
    DeleteAbsenceRequestDialog,
  },

  data: () => ({
    absenceRequests: [],
    displayDate: moment().format("YYYY-MM-DD"),
    openDatePicker: false,
    headers: [
      { text: "Von", align: "left", value: "start" },
      { text: "Bis und mit", align: "left", value: "end" },
      { text: "Art", align: "left", value: "type" },
      { text: "Info", align: "left", value: "info" },
      { text: "Status", align: "left", value: "manager_request_state" },
      { text: "Backoffice", align: "left", value: "office_task_state" },
      { text: "", align: "right", value: "action" },
    ],
  }),

  computed: {
    ...mapState("user", ["user"]),
    ...mapState("app", ["configs"]),

    listUrl() {
      return `api/absences?employee_id=${this.user.employee.id}`;
    },
  },

  methods: {
    updateItems(newAbsenceRequests) {
      this.absenceRequests = newAbsenceRequests;
    },

    editAbsence(item) {
      this.$emit("editAbsence", item);
    },

    deleteEntry(id) {
      this.$refs.deleteAbsenceDialog.open(id);
    },

    refresh() {
      this.$refs.absenceDataTable.getDataFromApi();
      this.$emit("deletedAbsence");
    },

    getAbsenceType(typeId) {
      return this.$absenceRequestService.getAbsenceType(
        this.configs.absence_types,
        typeId
      );
    },

    formatDate(date) {
      return moment(date).format("DD.MM.YYYY");
    },
  },
};
</script>
