<template>
  <v-form ref="form" v-model="valid">
    <v-row>
      <v-col cols="6" class="py-0">
        <v-row class="mb-4">
          <v-col class="py-0">
            <single-select
              v-if="edit"
              v-model="formData.mainSupport"
              label="Zuständiger Wochensupport"
              :items="supporters"
              :required="true"
              :readonly="!edit"
              @selected="mainSelection"
            ></single-select>

            <div v-else>
              <div class="text-subtitle-1">Wochensupport</div>
              <div class="text-h5 black--text">
                {{ getEmployeeName(formData.mainSupport) }}
              </div>
            </div>
          </v-col>
        </v-row>
        <v-row class="mb-4">
          <v-col class="py-0">
            <div class="text-subtitle-1">Email</div>
            <div class="text-h5 black--text">
              {{ getEmail(formData.mainSupport) }}
            </div>
          </v-col>
        </v-row>
        <v-row class="mb-4">
          <v-col class="py-0">
            <div class="text-subtitle-1">Mobile</div>
            <div class="text-h5 black--text">
              {{ getMobile(formData.mainSupport) }}
            </div>
          </v-col>
        </v-row>
      </v-col>

      <v-col cols="6" class="py-0">
        <v-row v-for="(day, index) in days" :key="day" class="py-1">
          <v-col cols="auto" class="py-0 text-h6" align-self="center">
            {{ displayDate(index) }}
          </v-col>
          <v-col class="py-0">
            <single-select
              v-if="edit"
              v-model="formData.week[day]"
              :label="weekday(index)"
              :items="supporters"
              :readonly="!edit"
            ></single-select>

            <div v-else>
              <div class="text-subtitle-1">{{ weekday(index) }}</div>
              <div class="text-h6">
                {{ getEmployeeName(formData.week[day]) }}
              </div>
            </div>
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <v-row class="action" v-if="edit">
      <v-col cols="auto">
        <v-btn class="cancel" text @click="cancel">Abbrechen</v-btn>
      </v-col>
      <v-col cols="auto">
        <v-btn class="commit" color="primary" :disabled="!valid" @click="save">
          Speichern
        </v-btn>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import moment from "moment";
import { mapState } from "vuex";
import SingleSelect from "@/components/forms/common/SingleSelect";

export default {
  components: {
    SingleSelect,
  },

  data() {
    return {
      valid: false,
      formData: {
        id: null,
        weekStart: null,
        mainSupport: null,
        week: {
          monday: null,
          tuesday: null,
          wednesday: null,
          thursday: null,
          friday: null,
          saturday: null,
          sunday: null,
        },
      },
    };
  },

  props: {
    edit: {
      required: true,
    },
    input: {
      required: true,
    },
    selectedWeek: {
      required: true,
    },
  },

  computed: {
    ...mapState("app", ["configs"]),

    hasExceptionDay() {
      if (!this.formData.mainSupport) {
        return false;
      }

      return this.days.some(
        (d) => this.formData.week[d] !== this.formData.mainSupport
      );
    },

    days() {
      return Object.keys(this.formData.week);
    },

    supporters() {
      return this.configs.employees.filter((e) => e.is_support);
    },
  },

  watch: {
    input(value) {
      this.reset(value);
    },
  },

  methods: {
    reset(value) {
      this.formData = {
        id: value?.id ?? null,
        weekStart: value?.week_start ?? null,
        mainSupport: value?.main ?? null,
        week: {
          monday: value?.monday ?? null,
          tuesday: value?.tuesday ?? null,
          wednesday: value?.wednesday ?? null,
          thursday: value?.thursday ?? null,
          friday: value?.friday ?? null,
          saturday: value?.saturday ?? null,
          sunday: value?.sunday ?? null,
        },
      };

      this.$refs.form.resetValidation();
    },

    getEmployee(employeeId) {
      if (employeeId) {
        return this.configs.employees.find((e) => e.id === employeeId);
      }
      return null;
    },

    getEmployeeName(employeeId) {
      const e = this.getEmployee(employeeId);
      return e ? e.name : "";
    },

    getMobile(employeeId) {
      const e = this.getEmployee(employeeId);
      return e ? e.mobile : "";
    },

    getEmail(employeeId) {
      const e = this.getEmployee(employeeId);
      return e ? e.email : "";
    },

    weekday(index) {
      const day = moment(this.selectedWeek).add(index, "days");
      return moment(day).format("dddd");
    },

    displayDate(index) {
      const day = moment(this.selectedWeek).add(index, "days");
      return moment(day).format("DD.MM.YYYY");
    },

    calenderWeek() {
      const year = moment(this.selectedWeek).format("YYYY");
      const kw = moment(this.selectedWeek).format("W");
      return year + " - KW " + kw;
    },

    mainSelection(employee) {
      if (employee) {
        this.days.forEach((d) => {
          this.formData.week[d] = employee;
        });
      }
    },

    getPayload() {
      const payload = {
        week_start: moment(this.selectedWeek).format("YYYY-MM-DD"),
        main: this.formData.mainSupport,
        monday: this.formData.week.monday,
        tuesday: this.formData.week.tuesday,
        wednesday: this.formData.week.wednesday,
        thursday: this.formData.week.thursday,
        friday: this.formData.week.friday,
        saturday: this.formData.week.saturday,
        sunday: this.formData.week.sunday,
      };

      return payload;
    },

    cancel() {
      this.reset(this.input);
      this.$emit("cancel");
    },

    save() {
      const payload = this.getPayload();

      if (this.input) {
        this.$api.http
          .put(`api/supports/${this.input.id}`, payload)
          .then(() => {
            this.$toast.open("Support geändert");
            this.$emit("saved");
          })
          .catch(() => {
            this.$toast.open({
              message: "Etwas ist schiefgelaufen.",
              type: "error",
            });
          });
      } else {
        this.$api.http
          .post("api/supports", payload)
          .then(() => {
            this.$toast.open("Support eingetragen");
            this.$emit("saved");
          })
          .catch(() => {
            this.$toast.open({
              message: "Etwas ist schiefgelaufen.",
              type: "error",
            });
          });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.action {
  justify-content: end;

  .cancel {
    width: 100%;
  }

  .commit {
    width: 100%;
  }
}
</style>
