<template>
  <v-card class="tt-card">
    <v-card-title class="header">
      <div class="grow">{{ title }}</div>
      <v-btn class="action" @click="runSync(syncKey)" :disabled="loading">
        <v-progress-circular
          v-if="loading"
          indeterminate
          color="white"
          class="mr-4"
          :width="3"
          :size="20"
        ></v-progress-circular>
        <v-icon :left="$vuetify.breakpoint.smAndUp">sync</v-icon>
        {{ $vuetify.breakpoint.smAndUp ? "Sync starten" : "" }}
      </v-btn>
    </v-card-title>

    <v-card-text>
      <v-row class="my-4 px-4 sync-status">
        <h4 class="grow black--text">Zuletzt synchronisiert:</h4>
        <v-chip :color="getStatusColor(status)" label outlined v-if="status">
          {{ status | formatDateTime }}
        </v-chip>
        <v-chip :color="'default'" label outlined v-else>
          kein Sync durchgeführt
        </v-chip>
      </v-row>

      <v-alert dense border="left" color="default" class="mt-5">
        <strong>{{ subtitle }}</strong
        ><br />
        {{ info }}
      </v-alert>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapMutations } from "vuex";
import moment from "moment";

export default {
  props: {
    syncKey: {
      value: String,
      required: true,
    },
    status: {
      value: String | null,
      required: true,
    },
    title: {
      value: String,
      required: true,
    },
    subtitle: {
      value: String,
      required: true,
    },
    info: {
      value: String,
      required: true,
    },
  },

  data() {
    return {
      loading: false,
    };
  },

  methods: {
    ...mapMutations("snackbar", ["showErrorSnackbar", "showSuccessSnackbar"]),

    async runSync(type) {
      this.loading = true;
      const response = await this.createSync(type);
      if (response.data.code === 100) {
        this.showErrorSnackbar(
          "Es läuft derzeit bereits ein Sync-Vorgang. Versuche es später nochmals."
        );
      } else {
        this.showSuccessSnackbar("Sync erfolgreich durchgeführt.");
      }
      this.$emit("refresh");
      this.loading = false;
    },

    createSync(type) {
      return this.$api.http.post("api/syncs", { type });
    },

    getStatusColor(ts) {
      return moment().diff(moment(ts), "minutes") > 15 ? "info" : "success";
    },
  },
};
</script>

<style lang="scss" scoped>
.sync-status {
  align-items: baseline;
}
</style>
