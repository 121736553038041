import moment from "moment";

export default class TimeEntryService {
  url = "api/time-entries";

  constructor(api) {
    this.api = api;
  }

  update(payload) {
    return this.api.http.put(`${this.url}/${payload.id}`, payload);
  }

  getWeek(start, user) {
    return this.api.http.get(
      `${this.url}/week/${start}?employee_id=${user.id}`
    );
  }

  getMonth(start, user) {
    return this.api.http.get(
      `${this.url}/month/${start}?employee_id=${user.id}`
    );
  }

  getFromTo(start, end, user) {
    return this.api.http.get(
      `${this.url}/${start}/${end}?employee_id=${user.id}`
    );
  }

  isEditable(notConcluded, item) {
    if (item.is_locked) {
      return false;
    }

    if (item.approved_on !== null) {
      return false;
    }

    if (moment(item.date).isAfter(moment())) {
      return true;
    }

    return notConcluded.some((month) =>
      moment(month.start_date).isSame(item.date, "month")
    );
  }

  parseData(week, notConcluded, activities) {
    let events = week.map((entry) => {
      const startTime = moment(entry.start_time);
      const endTime = moment(entry.end_time);

      const date = moment(entry.date).format("YYYY-MM-DD");
      const start = date + " " + startTime.format("HH:mm");
      const end = date + " " + endTime.format("HH:mm");

      const activity = activities.find(
        (a) => a.remote_id === entry.remote_category_id
      );
      const editable = this.isEditable(notConcluded, entry);
      return {
        id: entry.id,
        title: entry.project?.name ?? "Support Aufgabe",
        start: start,
        end: end,
        budget: entry.budget?.name ?? null,
        task: entry.budget?.task ? entry.budget.task.name : "",
        startEditable: editable,
        durationEditable: editable,
        color: this.getEventColor(entry, editable, activity),
        allDay: this.isAlldayEvent(activity),
        time_category: entry.remote_category_id,
      };
    });

    return events;
  }

  getClockedWeek(events, activities, weekStart) {
    let weekClocked = 0;
    let weekDaysClocked = [0, 0, 0, 0, 0, 0, 0];

    events.forEach((event) => {
      const activity = activities.find(
        (a) => a.remote_id === event.time_category
      );

      // TODO TT-28 Replace magic number with ActivityIdRoles
      if (activity.project_time_coverage_type !== 3) {
        let duration = moment.duration(moment(event.end).diff(event.start));

        duration = parseFloat(duration.asHours().toFixed(2));
        weekClocked += duration;
        weekDaysClocked[moment(event.start).day()] += duration;
      }
    });

    let clockedEvents = [];
    weekDaysClocked.forEach((clocked, index) => {
      if (clocked > 0) {
        clockedEvents.push({
          id: `day-${index}`,
          title: `${clocked}h`,
          start: moment(weekStart)
            .add(index - 1, "days")
            .format("YYYY-MM-DD"),
          end: moment(weekStart)
            .add(index - 1, "days")
            .format("YYYY-MM-DD"),
          color: "var(--v-primary-lighten5)",
          allDay: true,
          editable: false,
        });
      }
    });

    return {
      weekClocked,
      clockedEvents,
    };
  }

  isAlldayEvent(activity) {
    return activity.project_time_coverage_type === 1;
  }

  getEventColor(entry, editable, activity) {
    // TODO TT-28 Replace magic number with ActivityIdRoles
    if (activity && activity.project_time_coverage_type > 0) {
      return "var(--v-special-base)";
    } else if (!editable) {
      return "var(--v-primary-base)";
    } else if (entry.todo) {
      return "var(--v-error-base)";
    }
    return "var(--v-accent-base)";
  }

  getEntryByInfo(info, items) {
    let entry = items.find((entry) => entry.id == info.event._def.publicId);
    if (entry != undefined) {
      entry.date = moment(info.event.start).format("YYYY-MM-DD");
      entry.start_time = moment(info.event.start).format("YYYY-MM-DD HH:mm:ss");
      entry.end_time = moment(info.event.end).format("YYYY-MM-DD HH:mm:ss");

      return entry;
    }

    return null;
  }

  getPayloadByCalendarInfo(entry) {
    if (entry) {
      let payload = {};

      payload.employee_id = entry.employee.id;
      payload.budget_id = entry.budget ? entry.budget.id : null;
      payload.project_id = entry.project.id;
      payload.task_id = entry.task ? entry.task.id : null;
      payload.remote_category_id = entry.remote_category_id;

      payload.id = entry.id;
      payload.date = entry.date;
      payload.start_time = entry.start_time;
      payload.end_time = entry.end_time;
      payload.billable_rate = entry.billable_rate;
      payload.description = entry.description;
      payload.description_intern = entry.description_intern;

      payload.todo = entry.todo;

      return payload;
    }

    return null;
  }

  setPayloadDateDuration(payload, info, date) {
    payload.date = moment(date).format("YYYY-MM-DD");
    payload.start_time = moment(info.event.start).format("YYYY-MM-DD HH:mm:ss");
    payload.end_time = moment(info.event.end).format("YYYY-MM-DD HH:mm:ss");

    return payload;
  }

  getDurationChange(payload, event) {
    const oldStartTime = moment(payload.start_time);
    const oldEndTime = moment(payload.end_time);
    let oldDuration = moment.duration(oldEndTime.diff(oldStartTime));
    oldDuration = oldDuration.asHours();

    const newStartTime = moment(event.start);
    const newEndTime = moment(event.end);
    let newDuration = moment.duration(newEndTime.diff(newStartTime));
    newDuration = newDuration.asHours();

    return newDuration - oldDuration;
  }

  async setNewDateDurationFromInfo(info, items, activities) {
    const entry = this.getEntryByInfo(info, items);
    let payload = this.getPayloadByCalendarInfo(entry);
    const activity = activities.find(
      (a) => a.remote_id === payload.remote_category_id
    );

    if (payload) {
      const durationChange = this.getDurationChange(payload, info.event);

      payload = this.setPayloadDateDuration(payload, info, info.event.start);
      await this.update(payload);

      // TODO TT-28 Replace magic number with ActivityIdRoles
      return {
        durationChange:
          activity.project_time_coverage_type === 3 ? 0 : durationChange,
        entry: entry,
      };
    }

    return Promise.reject(
      "Payload is null, because entry not found in provided list."
    );
  }
}
