<template>
  <v-text-field
    type="date"
    :label="getLabel"
    :value="value"
    :clearable="clearable"
    :rules="selectionRule"
    @input="edit"
    :disabled="readonly"
    :readonly="readonly"
  ></v-text-field>
</template>

<script>
export default {
  props: {
    value: {
      required: true,
    },
    label: {
      required: true,
      type: String,
    },
    required: {
      type: Boolean,
      default: false,
    },
    clearable: {
      type: Boolean,
      default: false,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    getLabel() {
      return this.required ? `${this.label} *` : this.label;
    },
  },

  data() {
    return {
      selectionRule: this.required
        ? [(v) => !!v || "Muss selektiert werden"]
        : [],
    };
  },

  methods: {
    edit(value) {
      this.$emit("input", value);
    },
  },
};
</script>

<style lang="scss" scoped>
.required {
  font-weight: 500;
  color: black;
}

.disabled {
  color: black;
  opacity: 0.6;
}
</style>
