<template>
  <v-dialog v-model="isOpen" scrollable width="500">
    <v-card>
      <v-card-title :class="['primary', 'white--text']">
        <span class="subtitle-1">Anfrage beantworten</span>
      </v-card-title>

      <v-card-text class="mt-3">
        <p>
          <budget-time-diagram
            v-if="overview"
            :tracked="overview.holiday_taken_year"
            :planned="overview.holiday_planned_year"
            :offered="overview.holiday_balance_year"
            mode="holiday"
          ></budget-time-diagram>
        </p>
        <p>
          Absenz vom <strong>{{ getStartDate() }}</strong> bis am
          <strong>{{ getEndDate() }}</strong>
        </p>
        <p>
          Soll der Absenzeintrag von
          <strong>{{ employeeName }}</strong> angenommen werden?
        </p>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions class="py-4">
        <v-spacer></v-spacer>
        <v-btn color="default" text @click="close()"> Abbrechen </v-btn>
        <v-btn
          color="error"
          @click="completeRequest('denied')"
          class="ml-6 px-4"
        >
          Ablehnen
        </v-btn>
        <v-btn
          color="accent"
          @click="completeRequest('approved')"
          class="ml-6 px-4"
        >
          Annehmen
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import moment from "moment";
import BudgetTimeDiagram from "../diagram/BudgetTimeDiagram.vue";

export default {
  components: {
    BudgetTimeDiagram,
  },

  data: () => ({
    isOpen: false,
    employeeName: "",
    request: null,
    overview: null,
  }),

  methods: {
    getStartDate() {
      if (this.request) {
        return moment(this.request.start).format("DD.MM.YYYY");
      }
      return "";
    },

    getEndDate() {
      if (this.request) {
        return moment(this.request.end).format("DD.MM.YYYY");
      }
      return "";
    },

    open(request, employeeName, overview) {
      this.isOpen = true;
      this.request = request;
      this.employeeName = employeeName;
      this.overview = overview;
    },

    close() {
      this.isOpen = false;
      this.reset();
    },

    reset() {
      this.request = null;
      this.employeeName = "";
      this.overview = null;
    },

    completeRequest(state) {
      const text = state === "denied" ? "abgelehnt" : "angenommen";
      this.$absenceRequestService
        .changeManagerRequestState(this.request.id, state)
        .then(() => {
          this.$toast.open(`Eintrag wurde erfolgreich ${text}.`);
          this.$emit("done");
        })
        .catch(() => {
          this.$toast.open({
            message: `Eintrag konnte nicht ${text} werden.`,
            type: "error",
          });
        })
        .finally(() => {
          this.close();
        });
    },
  },
};
</script>
