<template>
  <div class="main-container">
    <v-container fluid>
      <v-row justify="center">
        <v-col cols="5" xl="3" class="logo-holder">
          <img src="@/assets/Tingo_T.jpg" class="logo" />
        </v-col>
        <v-col cols="5" xl="3">
          <h2>Login</h2>
          <p class="mt-4">
            Bitte melden Sie sich mit ihrer E-Mail-Adresse und ihrem Passwort
            an.
          </p>
          <v-form
            ref="form"
            v-model="valid"
            @submit.prevent="loginWithUsernameAndPassword()"
          >
            <v-text-field
              label="Benutzername"
              v-model="email"
              :rules="[rules.required]"
              :autocomplete="'on'"
            ></v-text-field>
            <v-text-field
              v-model="password"
              :append-icon="passwordVisible ? 'visibility' : 'visibility_off'"
              :rules="[rules.required, rules.min]"
              :type="passwordVisible ? 'text' : 'password'"
              label="Passwort"
              class="input-group--focused"
              @click:append="passwordVisible = !passwordVisible"
              :autocomplete="'on'"
            ></v-text-field>
            <v-btn
              type="submit"
              color="primary"
              elevation="0"
              class="float-right"
              :disabled="!valid"
            >
              Login
            </v-btn>
          </v-form>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { mapMutations } from "vuex";
export default {
  data: () => ({
    email: null,
    password: null,
    passwordVisible: false,

    valid: false,

    rules: {
      required: (value) => !!value || "Muss ausgefüllt werden",
      min: (v) => (v && v.length >= 8) || "Mindestens 8 Zeichen",
    },
  }),

  methods: {
    ...mapMutations("snackbar", ["showErrorSnackbar"]),

    async loginWithUsernameAndPassword() {
      if (this.$refs.form.validate()) {
        try {
          let response = await this.$api.login(this.email, this.password);
          if (response.status === 200 || response.status === 201) {
            this.$router.push("/");
            return;
          }
          this.showErrorSnackbar(response.data);
        } catch (error) {
          this.showErrorSnackbar(error);
        }
      }
    },

    /**
     * Login with terminal user.
     */
    async terminalLogin() {
      try {
        let response = await this.$api.terminalLogin();
        if (response.status === 200 || response.status === 201) {
          this.$router.push("/");
          return;
        }
        this.showErrorSnackbar(response.data);
      } catch (error) {
        this.showErrorSnackbar(error);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.main-container {
  width: 100%;
  height: 100%;
  display: relative;

  .container {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);

    .logo-holder {
      position: relative;

      .logo {
        width: 50%;
        position: absolute;
        left: 50%;
        top: 40%;
        transform: translate(-50%, -50%);
      }
    }
  }
}
</style>
