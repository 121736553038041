var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',{staticClass:"tt-card",attrs:{"color":"default","outlined":""}},[_c('v-card-title',{staticClass:"header",staticStyle:{"position":"relative"}},[_vm._v(" Mitarbeiter ")]),_c('v-card-text',[_c('remote-data-table',{ref:"employeesDatatable",attrs:{"url":'api/employees',"search-enabled":true,"headers":_vm.headers},scopedSlots:_vm._u([{key:"item.id",fn:function(ref){
var item = ref.item;
return [_c('router-link',{attrs:{"to":("/employees/" + (item.id))}},[_vm._v(" "+_vm._s(item.id)+" ")])]}},{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('router-link',{attrs:{"to":("/employees/" + (item.id))}},[_vm._v(" "+_vm._s(item.name)+" ")])]}},{key:"item.is_plannable",fn:function(ref){
var item = ref.item;
return [(item.is_plannable)?_c('v-icon',{attrs:{"color":"success"}},[_vm._v("check")]):_c('v-icon',{attrs:{"color":"error"}},[_vm._v("close")])]}},{key:"item.is_manager",fn:function(ref){
var item = ref.item;
return [(item.is_manager)?_c('v-icon',{attrs:{"color":"success"}},[_vm._v("check")]):_vm._e()]}},{key:"item.is_backoffice",fn:function(ref){
var item = ref.item;
return [(item.is_backoffice)?_c('v-icon',{attrs:{"color":"success"}},[_vm._v("check")]):_vm._e()]}},{key:"item.is_support",fn:function(ref){
var item = ref.item;
return [(item.is_support)?_c('v-icon',{attrs:{"color":"success"}},[_vm._v("check")]):_vm._e()]}},{key:"item.avatar",fn:function(ref){
var item = ref.item;
return [(item.avatar)?_c('div',{staticClass:"avatar",style:({ backgroundImage: 'url(' + item.avatar + ')' })},[_c('span',{staticClass:"invisible"},[_vm._v(_vm._s(item.acronym))])]):_c('div',{staticClass:"avatar none"},[_c('span',[_vm._v(_vm._s(item.acronym.substring(0, 2)))])])]}}],null,true)})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }