import Vue from "vue";
import moment from "moment";

moment.locale("de");

Vue.filter("formatTime", function (value) {
  if (value) {
    return moment(value, "HH:mm:ss").format("HH:mm");
  }
});

Vue.filter("formatTimeShort", function (value) {
  if (value) {
    return moment(value, "HH:mm:ss").format("HH:mm");
  }
});

Vue.filter("formatDate", function (value) {
  if (value) {
    return moment(value, "YYYY-MM-DD").format("DD.MM.YYYY");
  }
});

Vue.filter("formatDateTime", function (value) {
  if (value) {
    return moment(value, "YYYY-MM-DD HH:mm:ss").format("DD.MM.YYYY HH:mm:ss");
  }
});

Vue.filter("formatMonthNameYear", function (value) {
  if (value) {
    return moment(value, "YYYY-MM-DD").format("MMMM YYYY");
  }
});
