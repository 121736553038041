<template>
  <div>
    <v-card color="default" outlined class="tt-card">
      <v-card-title class="header" style="position: relative">
        <div class="title">Absenzanfragen</div>
      </v-card-title>

      <v-card-text>
        <remote-data-table
          :url="listUrl"
          :headers="headers"
          :sort-by="'start'"
          ref="absenceManagerDataTable"
          class="table"
          @dblclick="dblclick"
        >
          <template v-slot:[`item.employee_id`]="{ item }">
            <div class="text-caption" style="white-space: nowrap">
              {{ getEmployeeName(item.employee_id) }}
            </div>
          </template>
          <template v-slot:[`item.start`]="{ item }">
            <div class="text-caption" style="white-space: nowrap">
              {{ formatDate(item.start) }}
            </div>
          </template>
          <template v-slot:[`item.end`]="{ item }">
            <div class="text-caption" style="white-space: nowrap">
              {{ formatDate(item.end) }}
            </div>
          </template>
          <template v-slot:[`item.type`]="{ item }">
            <div class="text-caption" style="white-space: nowrap">
              {{ getAbsenceType(item.type) }}
            </div>
          </template>
          <template v-slot:[`item.action`]="{ item }">
            <v-progress-circular
              v-if="overviews.length === 0"
              indeterminate
              color="primary"
              class="mr-6"
              :width="3"
              :size="20"
            ></v-progress-circular>
            <v-btn
              v-else
              text
              color="primary"
              @click="handleRequestAction(item)"
            >
              <v-icon>task</v-icon>
            </v-btn>
          </template>
        </remote-data-table>
      </v-card-text>
    </v-card>

    <absence-request-action-dialog
      ref="absenceRequestActionDialog"
      @done="refresh"
    ></absence-request-action-dialog>
  </div>
</template>

<script>
import { mapState, mapMutations } from "vuex";
import moment from "moment";
import RemoteDataTable from "@/components/RemoteDataTable";
import AbsenceRequestActionDialog from "@/components/dialogs/AbsenceRequestActionDialog";

export default {
  components: {
    RemoteDataTable,
    AbsenceRequestActionDialog,
  },

  data: () => ({
    headers: [
      { text: "Mitarbeiter", align: "left", value: "employee_id" },
      { text: "Von", align: "left", value: "start" },
      { text: "Bis und mit", align: "left", value: "end" },
      { text: "Art", align: "left", value: "type" },
      { text: "Info", align: "left", value: "info" },
      { text: "", align: "right", value: "action" },
    ],
  }),

  computed: {
    ...mapState("user", ["user"]),
    ...mapState("app", ["configs", "overviews"]),

    listUrl() {
      return this.$absenceRequestService.getManagerLink();
    },
  },

  mounted() {
    if (this.overviews.length === 0) {
      this.$accountingPeriodService
        .getOverviews()
        .then((res) => this.setOverviews(res.data))
        .catch((err) => console.error(err));
    }
  },

  methods: {
    ...mapMutations("app", ["setOverviews"]),

    refresh() {
      this.$refs.absenceManagerDataTable.getDataFromApi();
      this.$emit("refresh");
    },

    handleRequestAction(request) {
      const employee = this.getEmployeeName(request.employee_id);
      const overview = this.overviews.find(
        (o) => o.employee_id === request.employee_id
      );
      this.$refs.absenceRequestActionDialog.open(request, employee, overview);
    },

    getAbsenceType(typeId) {
      const type = this.configs.absence_types.find((t) => t.id == typeId);
      if (type) {
        return type.name;
      }
      return "";
    },

    getEmployeeName(employeeId) {
      const employee = this.configs.employees.find((e) => e.id === employeeId);
      if (employee) {
        return employee.name;
      }
      return "MA nicht gefunden";
    },

    formatDate(date) {
      return moment(date).format("DD.MM.YYYY");
    },

    dblclick(item) {
      this.$emit("jumpTo", item.start);
    },
  },
};
</script>
