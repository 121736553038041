<template>
  <v-dialog v-model="isOpen" scrollable width="500">
    <v-card>
      <v-card-title :class="['primary', 'white--text']">
        <span class="text-heading-5">Abrechnungsperiode abschliessen</span>
      </v-card-title>

      <v-card-text class="mt-3">
        <p class="text-body-1 font-weight-medium">
          Sollen die Abrechnungsperiode {{ displayDate }} abgeschlossen werden?
        </p>
        <p class="text-body-1 font-weight-medium">
          Eingetragene Stunden {{ displayTime }}
        </p>
        <v-alert border="left" type="info">
          Die Zeiteinträge sind nachher nur noch über Comatic änderbar.
        </v-alert>
        <v-checkbox v-model="confirmed" label="Monat Abschliessen"></v-checkbox>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="default" text @click="close()">Nein</v-btn>
        <v-btn color="error" @click="confirm()" :disabled="!confirmed">
          Ja
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapMutations } from "vuex";
import moment from "moment";

export default {
  data: () => ({
    isOpen: false,
    date: null,
    period: null,
    confirmed: false,
  }),

  computed: {
    displayDate() {
      return moment(this.date).format("MMMM YYYY");
    },

    displayTime() {
      if (this.period) {
        const clocked = moment
          .duration(this.period.time_clocked, "hours")
          .asHours();
        const target = moment
          .duration(this.period.time_target, "hours")
          .asHours();
        return clocked + "/" + target;
      }
      return "";
    },
  },

  methods: {
    ...mapMutations("snackbar", ["showErrorSnackbar"]),

    open(date, period) {
      this.isOpen = true;
      this.date = date;
      this.period = period;
    },

    close() {
      this.isOpen = false;
      this.reset();
    },

    reset() {
      this.date = null;
      this.period = null;
      this.confirmed = false;
    },

    confirm() {
      this.isOpen = false;
      this.$api.http
        .post(
          `api/accounting-periods/conclude/${moment(
            this.period.start_date
          ).format("YYYY-MM-DD")}`
        )
        .then(() => {
          this.$toast.open("Abrechnungsperiode erfolgreich abgeschlossen");
          this.reset();
          this.$emit("concluded");
        })
        .catch(() => {
          this.$toast.open({
            message: "Etwas ist schiefgelaufen",
            type: "error",
          });
        });
    },
  },
};
</script>
