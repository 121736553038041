import TaskModel from "@/components/rp/models/TaskModel";
import VueCookies from "vue-cookies";
import { ProjectModel as Model } from "@tingo-gmbh/bryntum-gantt";

const token = VueCookies.get("access_token");

const ProjectsModel = new Model({
  taskModelClass: TaskModel,
  transport: {
    load: {
      requestConfig: {
        url: process.env.VUE_APP_API_URL + "/api/rp/projects/load",
        method: "GET",
        fetchOptions: {
          credentials: "same-origin",
        },
        disableCaching: true,
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      },
    },
    sync: {
      requestConfig: {
        url: process.env.VUE_APP_API_URL + "/api/rp/projects/sync",
        method: "POST",
        fetchOptions: {
          credentials: "same-origin",
        },
        disableCaching: true,
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      },
    },
  },
});

export default ProjectsModel;
