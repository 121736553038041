<template>
  <v-card elevation="2" outlined class="time-overview-card">
    <v-row>
      <v-col cols="12" class="py-0 card-detail">{{ detail }}</v-col>
    </v-row>
    <v-row style="display: flex">
      <v-col cols="6" class="py-0 card-title">{{ title }}</v-col>
      <v-col cols="6" class="py-0 card-text" v-if="text">{{ text }}</v-col>
      <v-col cols="6" v-else class="card-diagram"><slot></slot></v-col>
    </v-row>
  </v-card>
</template>

<script>
export default {
  props: ["detail", "title", "text"],
};
</script>

<style lang="scss" scoped>
.time-overview-card {
  padding: 8px 16px;

  .card-detail {
    font-style: italic;
    text-align: end;
  }

  .card-title {
    font-size: 24px;
    font-weight: 500;
    color: black;
  }

  .card-text {
    font-size: 24px;
    text-align: end;
  }

  .card-diagram {
    height: 24px;
    font-size: 16px;
    padding: 0 8px;
    margin: 4px 0;
  }
}
</style>
