<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12" md="6" class="py-0">
        <v-card>
          <FullCalendar
            ref="absenceCalendar"
            :options="absenceCalendarOptions"
          />
        </v-card>
      </v-col>

      <v-col cols="12" md="6" class="py-0">
        <absence-manager-table
          @refresh="refresh"
          @jumpTo="jumpTo"
        ></absence-manager-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import moment from "moment";
import { mapState, mapMutations, mapGetters } from "vuex";
import FullCalendar from "@fullcalendar/vue";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import deLocale from "@fullcalendar/core/locales/de";
import AbsenceManagerTable from "@/components/tables/admin/AbsenceManagerTable";

export default {
  components: {
    FullCalendar,
    AbsenceManagerTable,
  },

  data() {
    return {
      view: "list",
      year: null,
      durationSelection: null,
      selectedAbsence: null,
      absenceCalendarOptions: {
        locale: deLocale,
        plugins: [dayGridPlugin, interactionPlugin],
        initialView: "dayGridMonth",
        events: [],
        customButtons: {
          prev: { click: () => this.navMonth("prev") },
          next: { click: () => this.navMonth("next") },
          today: { click: () => this.navMonth("today"), text: "Heute" },
        },
      },
    };
  },

  mounted() {
    this.fetchYear();
  },

  computed: {
    ...mapState("app", ["configs"]),
    ...mapState("user", ["user"]),
    ...mapGetters("user", ["isManagerOrBackoffice"]),
  },

  methods: {
    ...mapMutations("app", ["setAdminTasks"]),

    fetchYear() {
      let date = moment();
      if (this.$refs.calendar) {
        date = moment(this.$refs.absenceCalendar.getApi().getDate());
      }

      this.$absenceRequestService
        .getYear(date.format("YYYY-MM-DD"))
        .then((res) => {
          this.year = res.data;
          const events = this.$absenceRequestService.parseData(
            res.data,
            this.configs.employees
          );
          this.setHolidays(events);
        })
        .catch((err) => console.error(err));
    },

    setHolidays(events) {
      let date = moment(this.$refs.absenceCalendar.getApi().getDate());
      this.$holidayService
        .getHolidaysByYear(date.format("YYYY"))
        .then((res) => {
          const holidaysParsed = this.$holidayService.parseHolidays(res.data);
          events.push(...holidaysParsed);
          this.absenceCalendarOptions.events = events;
        });
    },

    updateAdminTasks() {
      if (this.isManagerOrBackoffice) {
        this.$adminTaskService.getTasks().then((res) => {
          if (res.data) {
            this.setAdminTasks(res.data.data);
          }
        });
      }
    },

    refresh() {
      this.year = null;
      this.selectedAbsence = null;
      this.fetchYear();
      this.updateAdminTasks();
    },

    navMonth(direction) {
      const current = this.$refs.absenceCalendar.getApi().getDate();

      if (direction === "prev") {
        this.$refs.absenceCalendar.getApi().prev();
      } else if (direction === "next") {
        this.$refs.absenceCalendar.getApi().next();
      } else if (direction === "today") {
        this.$refs.absenceCalendar.getApi().today();
      }

      const next = this.$refs.absenceCalendar.getApi().getDate();

      if (moment(current).format("YYYY") !== moment(next).format("YYYY")) {
        this.week = null;
        this.fetchYear();
      }
    },

    jumpTo(date) {
      this.$refs.absenceCalendar.getApi().gotoDate(date);
    },
  },
};
</script>
