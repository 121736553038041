<template>
  <div>
    <v-row>
      <v-col cols="12">
        <time-entry-table
          :allProjects="allProjects"
          :allMyTasks="allMyTasks"
          :notConcluded="notConcluded"
          ref="TimeEntryTable"
        ></time-entry-table>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12">
        <task-table-overview
          :allProjects="allProjects"
          :allMyTasks="allMyTasks"
          @savedTimeEntry="onTimeEntrySavedEvent"
        ></task-table-overview>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import TimeEntryTable from "@/components/tables/timetrack/TimeEntryTable";
import TaskTableOverview from "@/components/tables/TaskTableOverview";

export default {
  components: {
    TimeEntryTable,
    TaskTableOverview,
  },

  props: {
    allProjects: {
      required: true,
    },
    allMyTasks: {
      required: true,
    },
    notConcluded: {
      required: true,
    },
  },

  methods: {
    onTimeEntrySavedEvent() {
      this.$refs.TimeEntryTable.refresh();
    },
  },
};
</script>
