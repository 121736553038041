var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',{staticClass:"tt-card",attrs:{"color":"default","outlined":""}},[_c('v-card-title',{staticClass:"header",staticStyle:{"position":"relative"}},[_vm._v(" Projekte "),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"primary","fab":"","dark":"","absolute":"","bottom":"","right":""},on:{"click":_vm.openCreateTaskDialog}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("add")])],1)]}}])},[_c('span',[_vm._v("Neue Aufgabe")])])],1),_c('v-card-text',[_c('remote-data-table',{ref:"projectsDatatable",attrs:{"url":'api/projects',"search-enabled":true,"headers":_vm.headers,"filters":_vm.filters,"sort-by":'-created_at'},scopedSlots:_vm._u([{key:"item.id",fn:function(ref){
var item = ref.item;
return [_c('router-link',{attrs:{"to":("/projects/" + (item.id))}},[_vm._v(" "+_vm._s(item.id)+" ")])]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('project-status-label',{attrs:{"project":item,"small":""}})]}},{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('router-link',{attrs:{"to":("/projects/" + (item.id))}},[_vm._v(" "+_vm._s(item.name)+" ")])]}},{key:"item.manager",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"text-caption",staticStyle:{"white-space":"nowrap"}},[_vm._v(" "+_vm._s(item.manager ? item.manager.name : null)+" ")])]}},{key:"item.deputy",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"text-caption",staticStyle:{"white-space":"nowrap"}},[_vm._v(" "+_vm._s(item.deputy ? item.deputy.name : null)+" ")])]}}],null,true)})],1)],1),_c('task-dialog',{ref:"taskDialog",on:{"saved":_vm.goToProject}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }