<template>
  <v-dialog v-model="isOpen" scrollable width="500">
    <v-card>
      <v-card-title :class="['primary', 'white--text']">
        <span class="subtitle-1">Erledigt</span>
      </v-card-title>

      <v-card-text class="mt-3">
        <p>
          Soll der Eintrag <strong>{{ entityName }}</strong> als erledigt
          markiert werden?
        </p>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="default" text @click="close()"> Nein </v-btn>
        <v-btn color="primary" text @click="deleteEntity()"> Ja </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapMutations, mapState } from "vuex";

export default {
  data: () => ({
    isOpen: false,
    entityId: null,
    entityName: null,
  }),

  computed: {
    ...mapState("app", ["configs"]),
  },

  methods: {
    ...mapMutations("snackbar", ["showErrorSnackbar"]),

    open(id, name) {
      this.isOpen = true;
      this.entityId = id;
      this.entityName = `#${id} ${name}`;
    },

    close() {
      this.reset();
      this.isOpen = false;
    },

    reset() {
      this.entityId = null;
      this.entityName = null;
    },

    deleteEntity() {
      this.$api.http
        .put(`api/tasks/${this.entityId}`, {
          statusId: this.configs.task_settings.completed,
        })
        .then(() => {
          this.$emit("completed");
          this.close();
        })
        .catch(() => {
          this.showErrorSnackbar(
            `${this.entityName} konnte nicht erledigt werden.`
          );
        });
    },
  },
};
</script>
