<template>
  <v-textarea
    :label="getLabel"
    :value="value"
    @input="edit"
    type="text"
    :rules="getTextRule"
    :rows="rows"
    :disabled="readonly"
    :readonly="readonly"
  ></v-textarea>
</template>

<script>
export default {
  props: {
    value: {
      required: true,
    },
    label: {
      required: true,
      type: String,
    },
    required: {
      type: Boolean,
      default: false,
    },
    max: {
      type: Number,
      default: 255,
    },
    rows: {
      type: Number,
      default: 1,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    getLabel() {
      return this.required ? `${this.label} *` : this.label;
    },

    getTextRule() {
      return this.required
        ? [
            (v) => !!v || `Das Feld ist erforderlich`,
            (v) =>
              (v && v.length <= this.max) ||
              `Der Text muss kürzer als ${this.max} sein`,
          ]
        : [
            (v) =>
              !v ||
              v.length <= this.max ||
              `Der Text muss kürzer als ${this.max} sein`,
          ];
    },
  },

  methods: {
    edit(value) {
      this.$emit("input", value);
    },
  },
};
</script>

<style lang="scss" scoped>
.required {
  font-weight: 500;
  color: black;
}
</style>
