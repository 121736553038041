<template>
  <v-container fluid>
    <billing-periode-overview
      ref="overview"
      v-model="view"
      :notConcluded="notConcluded"
      @refresh="fetchNotConcluded"
    ></billing-periode-overview>

    <time-track-calendar
      v-if="view === 'calendar'"
      :allProjects="allProjects"
      :allMyTasks="allMyTasks"
      :notConcluded="notConcluded"
      @refresh="refresh"
    >
    </time-track-calendar>

    <time-track-list
      v-if="view === 'list'"
      :allProjects="allProjects"
      :allMyTasks="allMyTasks"
      :notConcluded="notConcluded"
      @refresh="refresh"
    >
    </time-track-list>
  </v-container>
</template>

<script>
import { mapState, mapMutations, mapGetters } from "vuex";
import BillingPeriodeOverview from "@/components/sections/BillingPeriodeOverview";
import TimeTrackCalendar from "@/components/sections/timetrack/TimeTrackCalendar";
import TimeTrackList from "@/components/sections/timetrack/TimeTrackList";

export default {
  name: "TimeTrack",

  components: {
    BillingPeriodeOverview,
    TimeTrackCalendar,
    TimeTrackList,
  },

  data() {
    return {
      view: "calendar",
      tab: null,
      allProjects: [],
      allMyTasks: [],
      notConcluded: [],
    };
  },

  mounted() {
    this.fetchProjects();
    this.fetchMyTasks();
    this.fetchNotConcluded();
  },

  computed: {
    ...mapState("app", ["configs"]),
    ...mapState("user", ["user"]),
    ...mapGetters("user", ["isManagerOrBackoffice"]),
  },

  methods: {
    ...mapMutations("app", ["setAdminTasks"]),

    fetchProjects() {
      let projectFilter = "";
      this.configs.project_settings.time_track.forEach((s) => {
        projectFilter += `status[]=${s}&`;
      });

      this.$api.http
        .get(`api/projects/list?${projectFilter}hideLockedBudget=false`)
        .then((res) => {
          this.allProjects = res.data;
        })
        .catch(() => console.error("Unhandled Exception"));
    },

    fetchMyTasks() {
      this.$api.http
        .get(`api/tasks?employee_id=${this.user.employee.id}&_per_page=9999`)
        .then((res) => {
          this.allMyTasks = res.data.data;
        })
        .catch(() => console.error("Unhandled Exception"));
    },

    fetchNotConcluded() {
      this.$accountingPeriodService
        .getNotConcluded()
        .then((res) => {
          this.notConcluded = res.data;
        })
        .catch((err) => console.error(err));
    },

    refresh() {
      this.updateAdminTasks();
      this.$refs.overview.refresh();
    },

    updateAdminTasks() {
      if (this.isManagerOrBackoffice) {
        this.$adminTaskService.getTasks().then((res) => {
          if (res.data) {
            this.setAdminTasks(res.data.data);
          }
        });
      }
    },
  },
};
</script>
