<template>
  <div>
    <v-container fluid v-if="project">
      <v-row style="align-items: center">
        <v-col>
          <h1 class="text-h4">
            {{ project.name }}
          </h1>
        </v-col>
        <v-col cols="12" sm="auto" class="project-labels">
          <project-template-label :project="project" />
          <project-status-label :project="project" />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="4">
          <v-card outlined class="mb-6">
            <v-card-title class="card-title-grey"> Übersicht </v-card-title>
            <v-card-text>
              <v-text-field
                v-model="project.remote_id"
                label="Comatic ID"
                type="text"
                :maxlength="255"
                dense
                outlined
                readonly
              ></v-text-field>
              <v-text-field
                v-model="project.reference"
                label="Referenz"
                type="text"
                :maxlength="255"
                dense
                outlined
                readonly
              ></v-text-field>
              <v-text-field
                :value="project.manager ? project.manager.name : null"
                label="Projektleiter"
                type="text"
                :maxlength="255"
                dense
                outlined
                readonly
              ></v-text-field>
              <v-text-field
                :value="project.deputy ? project.deputy.name : null"
                label="Stv. Projektleiter"
                type="text"
                :maxlength="255"
                dense
                outlined
                readonly
              ></v-text-field>
              <v-text-field
                v-model="project.customer"
                label="Kunde"
                type="text"
                :maxlength="255"
                dense
                outlined
                readonly
              ></v-text-field>
            </v-card-text>
          </v-card>

          <v-card outlined class="mb-6">
            <v-card-title class="card-title-grey mb-0"> Budgets </v-card-title>
            <v-card-text
              class="pa-0"
              v-if="project.budgets && project.budgets.length > 0"
            >
              <budget-card
                v-for="budget in project.budgets"
                :key="budget.id"
                :budget="budget"
              >
              </budget-card>
            </v-card-text>
            <v-card-text v-else>
              <p class="mt-4 mb-0">Keine Budgets vorhanden</p>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="12" md="8">
          <v-card outlined>
            <v-card-title class="card-title-grey" style="position: relative">
              Aufgaben
              <v-tooltip
                bottom
                v-if="project.budgets && project.budgets.length > 0"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-if="project.is_projectable"
                    color="primary"
                    fab
                    dark
                    absolute
                    bottom
                    right
                    @click="openCreateDialog"
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-icon>add</v-icon>
                  </v-btn>
                </template>
                <span>Aufgabe erstellen</span>
              </v-tooltip>
            </v-card-title>
            <v-card-text v-if="tasks && tasks.length">
              <v-simple-table>
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="text-left">ID</th>
                      <th class="text-left">Titel</th>
                      <th class="text-left">Start</th>
                      <th class="text-left">Ende</th>
                      <th class="text-left">Budget</th>
                      <th class="text-left">Mitarbeiter</th>
                      <th class="text-left">Erfasst (Std)</th>
                      <th class="text-left">Geplant (Std)</th>
                      <th class="text-left">Status</th>
                      <th class="text-right"></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="task in tasks" :key="task.id">
                      <td>{{ task.id }}</td>
                      <td>
                        <span style="white-space: nowrap; font-weight: bold">
                          {{ task.name }}
                        </span>
                      </td>
                      <td>{{ task.starts_at | formatDate }}</td>
                      <td>{{ task.ends_at | formatDate }}</td>
                      <td>
                        <span class="text-caption">
                          {{ task.budget.name }}
                        </span>
                      </td>
                      <td>
                        <span class="text-caption">
                          {{ task.employee_name ? task.employee_name : "-" }}
                        </span>
                      </td>
                      <td>{{ task.time_used | formatHours }}</td>
                      <td>{{ task.time_expected | formatHours }}</td>
                      <td><task-status-label :task="task" small /></td>
                      <td class="text-right">
                        <v-menu bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                              color="default"
                              x-small
                              v-bind="attrs"
                              v-on="on"
                              fab
                              class="elevation-0"
                            >
                              <v-icon>more_vert</v-icon>
                            </v-btn>
                          </template>
                          <v-list>
                            <v-list-item
                              @click="openCreateTimeEntryDialog(task)"
                            >
                              Zeit erfassen
                            </v-list-item>
                            <v-list-item @click="openEditDialog(task)">
                              Bearbeiten
                            </v-list-item>
                            <v-list-item
                              v-if="task.status !== 'completed'"
                              @click="openCompleteDialog(task.id, task.name)"
                            >
                              Erledigt
                            </v-list-item>
                            <v-list-item
                              @click="openDeleteDialog(task.id, task.name)"
                            >
                              Löschen
                            </v-list-item>
                          </v-list>
                        </v-menu>
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-card-text>
            <v-card-text v-else>
              <p class="mt-4 mb-0">
                {{
                  project.is_projectable
                    ? "Keine Aufgaben vorhanden"
                    : "Projekt kann keine Aufgaben erstellen"
                }}
              </p>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>

    <task-dialog ref="taskDialog" @saved="fetchProject()" />
    <delete-dialog ref="deleteDialog" @deleted="fetchProject()" />
    <complete-dialog ref="completeDialog" @completed="fetchProject()" />
    <time-entry-dialog ref="timeEntryDialog"></time-entry-dialog>
  </div>
</template>

<script>
import { mapMutations, mapState } from "vuex";
import TaskDialog from "@/components/dialogs/TaskDialog";
import DeleteDialog from "@/components/dialogs/DeleteDialog";
import CompleteDialog from "@/components/dialogs/CompleteDialog";
import ProjectStatusLabel from "@/components/labels/ProjectStatusLabel";
import ProjectTemplateLabel from "@/components/labels/ProjectTemplateLabel";
import TaskStatusLabel from "@/components/labels/TaskStatusLabel";
import TimeEntryDialog from "@/components/dialogs/TimeEntryDialog";
import BudgetCard from "@/components/cards/BudgetCard";

export default {
  components: {
    TaskDialog,
    DeleteDialog,
    CompleteDialog,
    ProjectStatusLabel,
    ProjectTemplateLabel,
    TaskStatusLabel,
    TimeEntryDialog,
    BudgetCard,
  },

  data: () => ({
    project: null,
    valid: false,
    allProjects: [],
    allMyTasks: [],
  }),

  computed: {
    ...mapState("app", ["configs"]),
    ...mapState("user", ["user"]),

    projectId() {
      return this.$route.params.id;
    },

    tasks() {
      let tasks = [];
      this.project.budgets.forEach((budget) => {
        budget.tasks.forEach((task) => {
          task.budget = {
            id: budget.id,
            name: budget.name,
            project: {
              id: this.project.id,
            },
          };
          task.employee_name = task.employee ? task.employee.name : null;
          tasks.push(task);
        });
      });
      return tasks;
    },
  },

  created() {
    this.fetchProject();
    this.fetchProjects();
    this.fetchMyTasks();
  },

  methods: {
    ...mapMutations("snackbar", ["showErrorSnackbar", "showSuccessSnackbar"]),

    fetchProjects() {
      let projectFilter = "";
      this.configs.project_settings.time_track.forEach((s) => {
        projectFilter += `status[]=${s}&`;
      });

      this.$api.http
        .get(`api/projects/list?${projectFilter}hideLockedBudget=false`)
        .then((res) => {
          this.allProjects = res.data;
        })
        .catch(() => console.error("Unhandled Exception"));
    },

    fetchMyTasks() {
      this.$api.http
        .get(`api/tasks?employee_id=${this.user.employee.id}`)
        .then((res) => {
          this.allMyTasks = res.data.data;
        })
        .catch(() => console.error("Unhandled Exception"));
    },

    fetchProject() {
      this.$api.http.get(`api/projects/${this.projectId}`).then((response) => {
        this.project = response.data;
      });
    },

    getChipColor(used, offered) {
      const thresholdMax = offered + offered * 0.1;

      if (used <= offered) {
        return "success";
      }

      if (used > thresholdMax) {
        return "error";
      }

      return "warning";
    },

    openCreateDialog() {
      this.$refs.taskDialog.open("create", null, this.project.id);
    },

    openEditDialog(task) {
      this.$refs.taskDialog.open(
        "edit",
        task.id,
        this.project.id,
        task.budget.id
      );
    },

    openCompleteDialog(id, name) {
      this.$refs.completeDialog.open(id, name);
    },

    openDeleteDialog(id, name) {
      this.$refs.deleteDialog.open(id, name, "tasks");
    },

    openCreateTimeEntryDialog(task) {
      this.$refs.timeEntryDialog.open(
        this.allProjects,
        this.allMyTasks,
        null,
        task
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.card-title-grey {
  background-color: #eee;
  padding: 10px 15px;
  font-size: 16px;
  margin-bottom: 20px;
}

.theme--light.v-expansion-panels .v-expansion-panel.active-budget {
  background-color: #e9e9e9;

  .v-expansion-panel-header {
    background-color: #dbdbdb;
  }
}

.project-labels {
  text-align: end;
  display: flex;
  column-gap: 16px;
}
</style>
