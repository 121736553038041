<template>
  <div :style="style.box">
    <v-tooltip bottom v-if="this.tracked !== 0">
      <template v-slot:activator="{ on }">
        <div v-on="on" :style="style.tracked">
          {{ label.tracked }}
        </div>
      </template>
      <span>{{ label.trackedTooltip }}</span>
    </v-tooltip>
    <v-tooltip bottom v-if="this.planned !== 0">
      <template v-slot:activator="{ on }">
        <div v-on="on" :style="style.planned">
          {{ label.planned }}
        </div>
      </template>
      <span>{{ label.plannedTooltip }}</span>
    </v-tooltip>
    <v-tooltip bottom>
      <template v-slot:activator="{ on }">
        <div v-on="on" :style="style.left">
          {{ label.left }}
        </div>
      </template>
      <span>{{ label.leftTooltip }}</span>
    </v-tooltip>
  </div>
</template>

<script>
import BudgetTimeService from "@/services/BudgetTimeService";

export default {
  props: ["tracked", "planned", "offered", "mode"],

  computed: {
    budgetTimeService() {
      return new BudgetTimeService(this.tracked, this.planned, this.offered);
    },

    style() {
      return this.budgetTimeService.getVueStyle();
    },

    label() {
      return this.budgetTimeService.getLabel(this.mode);
    },
  },
};
</script>
