<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12" md="6" class="py-0">
        <v-card>
          <FullCalendar
            ref="supportCalendar"
            :options="supportCalendarOptions"
          />
        </v-card>
      </v-col>
      <v-col cols="12" md="6" class="py-0">
        <v-row>
          <v-col>
            <support-card
              :selectedSupport="selectedSupport"
              :selectedWeek="selectedWeek"
              @refresh="refreshCalendar"
            ></support-card>
          </v-col>
        </v-row>
        <v-row v-if="isManagerOrBackoffice">
          <v-col>
            <support-report-card></support-report-card>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import moment from "moment";
import { mapState, mapGetters } from "vuex";
import FullCalendar from "@fullcalendar/vue";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import deLocale from "@fullcalendar/core/locales/de";
import SupportCard from "@/components/cards/SupportCard";
import SupportReportCard from "@/components/cards/SupportReportCard";

export default {
  components: {
    FullCalendar,
    SupportCard,
    SupportReportCard,
  },

  data() {
    return {
      year: null,
      durationSelection: null,
      selectedWeek: moment().startOf("week").format("YYYY-MM-DD"),
      selectedSupport: null,
      supportCalendarOptions: {
        locale: deLocale,
        plugins: [dayGridPlugin, interactionPlugin],
        initialView: "dayGridMonth",
        events: [],
        selectable: true,
        select: (info) => this.handleSelection(info),
        customButtons: {
          prev: { click: () => this.navMonth("prev") },
          next: { click: () => this.navMonth("next") },
          today: { click: () => this.navMonth("today"), text: "Heute" },
        },
      },
    };
  },

  mounted() {
    this.fetchYear();
  },

  computed: {
    ...mapState("app", ["configs"]),
    ...mapGetters("user", ["isManagerOrBackoffice"]),
  },

  methods: {
    refreshCalendar() {
      this.year = null;
      this.selectedSupport = null;
      this.fetchYear();
    },

    fetchYear() {
      this.$supportService
        .getYear(moment(this.selectedWeek).format("YYYY-MM-DD"))
        .then((res) => {
          this.year = res.data;
          this.selectedSupport = this.year.find(
            (week) => week.week_start === this.selectedWeek
          );

          const events = this.$supportService.parseData(
            res.data,
            this.configs.employees
          );
          this.setHolidays(events);
        })
        .catch((err) => console.error(err));
    },

    setHolidays(events) {
      let date = moment(this.$refs.supportCalendar.getApi().getDate());
      this.$holidayService
        .getHolidaysByYear(date.format("YYYY"))
        .then((res) => {
          const holidaysParsed = this.$holidayService.parseHolidays(res.data);
          events.push(...holidaysParsed);
          this.supportCalendarOptions.events = events;
        });
    },

    navMonth(direction) {
      const current = this.$refs.supportCalendar.getApi().getDate();

      if (direction === "prev") {
        this.$refs.supportCalendar.getApi().prev();
      } else if (direction === "next") {
        this.$refs.supportCalendar.getApi().next();
      } else if (direction === "today") {
        this.$refs.supportCalendar.getApi().today();
      }

      const next = this.$refs.supportCalendar.getApi().getDate();

      if (moment(current).format("YYYY") !== moment(next).format("YYYY")) {
        this.fetchYear();
      }
    },

    handleSelection(info) {
      this.selectedWeek = moment(info.startStr)
        .startOf("week")
        .format("YYYY-MM-DD");

      this.selectedSupport = this.year.find(
        (week) => week.week_start === this.selectedWeek
      );
    },
  },
};
</script>
