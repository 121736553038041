<template>
  <v-row>
    <v-col cols="12" md="6">
      <v-card>
        <FullCalendar ref="calendar" :options="calendarOptions" />
      </v-card>
    </v-col>

    <v-col cols="12" md="6">
      <time-entry-card
        :selectedEntry="selectedEntry"
        :durationSelection="durationSelection"
        :allProjects="allProjects"
        :allMyTasks="allMyTasks"
        :notConcluded="notConcluded"
        @changed="refreshCalendar"
        @cancel="clearTimeEntry"
      >
      </time-entry-card>
    </v-col>
  </v-row>
</template>

<script>
import moment from "moment";
import { mapState } from "vuex";

import FullCalendar from "@fullcalendar/vue";
import deLocale from "@fullcalendar/core/locales/de";
import TimeEntryCard from "@/components/cards/TimeEntryCard";
import interactionPlugin from "@fullcalendar/interaction";
import timeGridPlugin from "@fullcalendar/timegrid";

export default {
  name: "TimeTrackCalendar",

  components: {
    FullCalendar,
    TimeEntryCard,
  },

  props: {
    allProjects: {
      required: true,
    },
    allMyTasks: {
      required: true,
    },
    notConcluded: {
      required: true,
    },
  },

  data() {
    return {
      week: null,
      weekStart: moment().startOf("week").format("YYYY-MM-DD"),
      weekClocked: 0,
      selectedEntry: null,
      durationSelection: null,
      events: {
        base: [],
        holiday: [],
        clocked: [],
      },
      calendarOptions: {
        locale: deLocale,
        plugins: [interactionPlugin, timeGridPlugin],
        initialView: this.$vuetify.breakpoint.mdAndUp
          ? "timeGridWeek"
          : "timeGridDay",
        firstDay: 1,
        height: this.$vuetify.breakpoint.mdAndUp ? "650px" : "70vh",
        scrollTime: "07:00:00",
        events: [],
        selectable: true,
        snapDuration: "00:05:00",
        select: (info) => this.handleSelection(info),
        eventClick: (info) => this.handleClick(info),
        eventResize: (info) => this.handleDateChange(info),
        eventDrop: (info) => this.handleDateChange(info),
        customButtons: {
          prev: { click: () => this.navWeek("prev") },
          next: { click: () => this.navWeek("next") },
          today: { click: () => this.navWeek("today"), text: "Heute" },
        },
        headerToolbar: {
          left: "title",
          center: "hours",
          right: "today prev,next",
        },
      },
    };
  },

  mounted() {
    if (this.notConcluded.length > 0) {
      this.fetchWeek(this.notConcluded);
    }

    // FC: Create a custom element for week hour display
    let hours = document.getElementsByClassName("fc-hours-button")[0];
    hours.id = "WeekHoursToolbar";
  },

  computed: {
    ...mapState("app", ["activities"]),
    ...mapState("user", ["user"]),
  },

  watch: {
    weekClocked(value) {
      let elem = document.getElementById("WeekHoursToolbar");
      elem.innerHTML = parseFloat(value).toFixed(2) + "h";
    },

    notConcluded(value) {
      this.fetchWeek(value);
    },

    "events.base"(value) {
      this.setClockedEvents(value);
    },

    events: {
      deep: true,
      handler(value) {
        this.calendarOptions.events = [
          ...value.base,
          ...value.holiday,
          ...value.clocked,
        ];
      },
    },
  },

  methods: {
    fetchWeek(notConlcuded) {
      let date = moment();
      if (this.$refs.calendar) {
        date = moment(this.$refs.calendar.getApi().getDate());
      }
      date = date.startOf("isoWeek").format("YYYY-MM-DD");

      this.$timeEntryService
        .getWeek(date, this.user.employee)
        .then((res) => {
          this.week = res.data;
          const events = this.$timeEntryService.parseData(
            res.data,
            notConlcuded,
            this.activities
          );
          this.events.base = events;
          this.setClockedEvents(events);
          this.setHolidays();
        })
        .catch((err) => {
          this.$toast.open({
            type: "error",
            message: err.message,
          });
        });
    },

    clearTimeEntry() {
      this.selectedEntry = null;
    },

    refreshCalendar() {
      this.week = null;
      this.selectedEntry = null;
      this.fetchWeek(this.notConcluded);
      this.$emit("refresh");
    },

    navWeek(direction) {
      if (direction === "prev") {
        this.$refs.calendar.getApi().prev();
      } else if (direction === "next") {
        this.$refs.calendar.getApi().next();
      } else if (direction === "today") {
        this.$refs.calendar.getApi().today();
      }

      this.week = null;
      this.fetchWeek(this.notConcluded);
    },

    setClockedEvents(events) {
      const startOfWeek = moment(this.$refs.calendar.getApi().getDate())
        .startOf("isoWeek")
        .format("YYYY-MM-DD");
      const clockedWeek = this.$timeEntryService.getClockedWeek(
        events,
        this.activities,
        startOfWeek
      );

      this.weekClocked = clockedWeek.weekClocked;
      this.events.clocked = clockedWeek.clockedEvents;
    },

    setHolidays() {
      const startOfWeek = moment(this.$refs.calendar.getApi().getDate())
        .startOf("week")
        .format("YYYY-MM-DD");
      this.$holidayService.getHolidaysByWeek(startOfWeek).then((res) => {
        const holidaysParsed = this.$holidayService.parseHolidays(res.data);
        this.events.holiday = holidaysParsed;
      });
    },

    handleSelection(event) {
      const duration = {
        date: moment(event.start).format("YYYY-MM-DD"),
        start_time: moment(event.start).format("HH:mm"),
        end_time: moment(event.end).format("HH:mm"),
      };
      this.durationSelection = duration;
    },

    handleClick(info) {
      if (info.event.allDay) {
        return false;
      }
      this.selectedEntry = {
        ...this.$timeEntryService.getEntryByInfo(info, this.week),
      };
    },

    handleDateChange(info) {
      this.handleSelection(info.event);

      const index = this.events.base.findIndex(
        (e) => e.id === parseInt(info.event.id)
      );

      if (index > -1) {
        let base = {
          ...this.events.base[index],
          start: info.event.start,
          end: info.event.end,
        };
        this.events.base.splice(index, 1);
        this.events.base.push(base);
      }

      this.$timeEntryService
        .setNewDateDurationFromInfo(info, this.week, this.activities)
        .then(() => {
          this.$emit("refresh");
        })
        .catch((err) => {
          this.$toast.open({
            type: "error",
            message: err.message,
          });
        });
    },
  },
};
</script>

<style lang="scss">
#WeekHoursToolbar {
  color: white;
  background-color: var(--v-accent-base);
  border: none;
  cursor: default;
  content: "Test";
  box-shadow: none;
  font-size: 1.2em;
}
</style>
