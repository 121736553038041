<template>
  <v-navigation-drawer
    v-model="navigationDrawer"
    :clipped="false"
    :mini-variant.sync="navigationMini"
    mini-variant-width="56"
    :temporary="$vuetify.breakpoint.mdAndDown"
    :permanent="$vuetify.breakpoint.lgAndUp"
    app
    dark
    :expand="true"
    v-if="isLoggedIn && !isFullscreen"
    class="app-navigation-drawer"
  >
    <div class="navigation-drawer-header">
      <v-app-bar-nav-icon
        v-if="$vuetify.breakpoint.mdAndDown"
        @click.stop="updateNavigationDrawer($vuetify.breakpoint)"
      />
      <div class="navigation-drawer-header-title">
        <img src="@/assets/comatic.svg" class="logo" />
      </div>
    </div>
    <v-list dense>
      <template v-for="(item, index) in items">
        <!-- Group -->
        <v-list-group
          v-if="item.group && !item.hidden"
          :prepend-icon="item.icon"
          :key="index"
          :active-class="'none'"
          value="open"
        >
          <template v-slot:activator>
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </template>
          <app-navigation-item
            :subitem="true"
            v-for="(subitem, index) in item.items"
            :item="subitem"
            :key="'s' + index"
          />
        </v-list-group>

        <!-- Navigation item -->
        <app-navigation-item v-else :item="item" :key="item.title" />
      </template>
    </v-list>
    <div class="navigation-drawer-footer font-weight-bold">
      <div class="ml-3">Comatic App</div>
      <div class="ml-3">
        {{ version }}
        <!-- <span class="font-weight-light">/ &copy; Tingo GmbH</span> -->
      </div>
    </div>
  </v-navigation-drawer>
</template>

<script>
import { mapState, mapMutations, mapGetters } from "vuex";
import AppNavigationItem from "@/components/app/AppNavigationItem";

export default {
  components: {
    AppNavigationItem,
  },

  computed: {
    ...mapState("app", ["drawer", "mini", "version", "configs"]),
    ...mapGetters("user", [
      "isLoggedIn",
      "isManagerOrBackoffice",
      "isManager",
      "isBackoffice",
    ]),

    items() {
      let items = [
        {
          title: "Dashboard",
          link: "/",
          icon: "dashboard",
        },
        { divider: true },
        {
          title: "Ressourcenplanung",
          link: "/resource-planning",
          icon: "supervised_user_circle",
        },

        { divider: true },
        {
          title: "Zeiterfassung",
          link: "/time-track",
          icon: "timer",
        },
        { divider: true },
        {
          title: "Projekte",
          link: "/projects",
          icon: "content_paste",
        },
        // { divider: true },
        // {
        //   title: "Kalender",
        //   link: "/calendar",
        //   icon: "event",
        // },
        { divider: true },
        {
          title: "Urlaub",
          link: "/absences",
          icon: "work_off",
        },
        { divider: true },
        {
          title: "Support",
          link: "/support",
          icon: "support_agent",
        },
        { divider: true },
        {
          title: "Mitarbeiter",
          link: "/employees",
          icon: "supervisor_account",
        },
        { divider: true },
        {
          group: true,
          title: "Administration",
          icon: "business",
          hidden: !this.isManagerOrBackoffice,
          items: [
            {
              title: "Admin Aufgaben",
              link: "/admin/tasks",
              hidden: !this.isManagerOrBackoffice,
            },
            {
              title: "Absenzanfragen",
              link: "/admin/absence-requests",
              hidden: !this.isManager,
            },
            {
              title: "Mitarbeiterverwaltung",
              link: "/admin/employees",
              hidden: !this.isManagerOrBackoffice,
            },
            {
              title: "Einstellungen",
              link: "/admin/settings",
              hidden: !this.isBackoffice,
            },
          ],
        },
      ];

      return items;
    },

    /**
     * Internal getter / setter
     */
    navigationDrawer: {
      get() {
        return this.drawer;
      },
      set(value) {
        this.setDrawer(value);
      },
    },

    /**
     * Internal getter / setter
     */
    navigationMini: {
      get() {
        return this.mini;
      },
      set(value) {
        // Otherwise an error is thrown.
        if (value) {
          this.setMini(value);
        }
      },
    },

    isFullscreen() {
      return this.$route.meta.isFullscreen ?? false;
    },
  },

  methods: {
    ...mapMutations("app", [
      "initNavigationDrawer",
      "setDrawer",
      "updateNavigationDrawer",
      "setFullscreenDialogComponent",
      "setFullscreenDialogConfig",
    ]),
  },

  created() {
    // Set navigation drawer on page load
    this.initNavigationDrawer(this.$vuetify.breakpoint);
  },
};
</script>

<style lang="scss">
.v-navigation-drawer--mini-variant {
  width: 56px !important;
  .v-subheader {
    visibility: hidden;
  }
  .navigation-drawer-header-title,
  .navigation-drawer-footer {
    display: none !important;
  }
}
.v-navigation-drawer .v-list {
  padding-top: 40px;
}

.app-navigation-drawer {
  background: linear-gradient(180deg, #202b2b, #091d1d) !important;
  .v-navigation-drawer__border {
    display: none;
  }

  .navigation-drawer-header {
    padding: 15px 9px;
    height: 64px;
    background-color: #091d1d !important;

    .theme--dark.v-btn.v-btn--icon {
      color: white;
    }

    .navigation-drawer-header-title {
      display: inline-flex;
      color: white;
      vertical-align: middle;
      font-size: 1.3em;
      padding-left: 10px;

      .menu-bar-icon {
        margin-right: 10px;
      }

      .logo {
        height: 30px;
        margin-right: 12px;
      }
    }
  }

  .v-list {
    .theme--dark.v-icon,
    .v-list-item__title {
      color: white;
    }

    .v-list-item--active {
      .theme--dark.v-icon,
      .v-list-item__title {
        color: white;
      }
    }
  }

  .navigation-drawer-footer {
    position: absolute;
    width: 100%;
    bottom: 0;
    color: white;
    padding: 10px 0;
    font-size: 0.8em;
    border-top: 1px solid #344242 !important;
  }
}
</style>
