var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',{staticClass:"tt-card",attrs:{"color":"default","outlined":""}},[_c('v-card-title',{staticClass:"header",staticStyle:{"position":"relative"}},[(_vm.$vuetify.breakpoint.mdAndUp)?_c('div',{staticClass:"title"},[_vm._v("Absenzen")]):_vm._e(),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"primary","fab":"","dark":"","absolute":"","bottom":"","right":""},on:{"click":function($event){return _vm.$emit('createAbsence')}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("add")])],1)]}}])},[_c('span',[_vm._v("Neuen Antrag stellen")])])],1),_c('v-card-text',[_c('remote-data-table',{ref:"absenceDataTable",staticClass:"table",attrs:{"url":_vm.listUrl,"headers":_vm.headers,"sort-by":'start'},on:{"dataItems":_vm.updateItems,"dblclick":_vm.editAbsence},scopedSlots:_vm._u([{key:"item.start",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-caption",staticStyle:{"white-space":"nowrap"}},[_vm._v(" "+_vm._s(_vm.formatDate(item.start))+" ")])]}},{key:"item.end",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-caption",staticStyle:{"white-space":"nowrap"}},[_vm._v(" "+_vm._s(_vm.formatDate(item.end))+" ")])]}},{key:"item.type",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-caption",staticStyle:{"white-space":"nowrap"}},[_vm._v(" "+_vm._s(_vm.getAbsenceType(item.type))+" ")])]}},{key:"item.manager_request_state",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-caption",staticStyle:{"white-space":"nowrap"}},[(item.manager_request_state === 'pending')?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"color":"primary"}},on),[_vm._v(" pending_actions ")])]}}],null,true)},[_c('span',[_vm._v("In Bearbeitung")])]):_vm._e(),(item.manager_request_state === 'denied')?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"color":"error"}},on),[_vm._v("cancel")])]}}],null,true)},[_c('span',[_vm._v("Abgelehnt")])]):_vm._e(),(item.manager_request_state === 'approved')?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"color":"accent"}},on),[_vm._v("check_circle")])]}}],null,true)},[_c('span',[_vm._v("Angenommen")])]):_vm._e()],1)]}},{key:"item.office_task_state",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-caption",staticStyle:{"white-space":"nowrap"}},[(item.office_task_state === 'waiting')?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"color":"primary"}},on),[_vm._v("hourglass_empty")])]}}],null,true)},[_c('span',[_vm._v("Warten auf Annahme")])]):_vm._e(),(item.office_task_state === 'inProgress')?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"color":"error"}},on),[_vm._v("sync")])]}}],null,true)},[_c('span',[_vm._v("In Bearbeitung")])]):_vm._e(),(item.office_task_state === 'done')?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"color":"accent"}},on),[_vm._v("done")])]}}],null,true)},[_c('span',[_vm._v("Fertig")])]):_vm._e()],1)]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('v-menu',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"x-small":"","text":"","fab":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("more_vert")])],1)]}}],null,true)},[_c('v-list',[_c('v-list-item',{on:{"click":function($event){return _vm.editAbsence(item)}}},[_vm._v(" Bearbeiten ")]),_c('v-list-item',{on:{"click":function($event){return _vm.deleteEntry(item.id, item.name)}}},[_vm._v(" Löschen ")])],1)],1)]}}],null,true)})],1)],1),_c('delete-absence-request-dialog',{ref:"deleteAbsenceDialog",on:{"deleted":_vm.refresh}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }