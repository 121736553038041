<template>
  <v-card class="tt-card">
    <v-card-title class="header">
      <div class="grow">Auswertung</div>
    </v-card-title>

    <v-card-text>
      <v-card-text v-if="compensationNotSet">
        <v-row>
          <v-col>
            <h2 class="text-h5">Kompensationen wurden noch nicht gesetzt</h2>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-btn link to="/admin/settings" color="primary">
              Zu den Einstellungen
            </v-btn>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-text v-else>
        <v-row>
          <v-col sm="4" class="py-0">
            <date-picker
              v-model="month"
              type="month"
              label="Auswertungsmonat"
              format="MMMM YYYY"
            ></date-picker>
          </v-col>
        </v-row>
        <v-row>
          <v-col sm="12" class="py-0">
            <v-row v-for="item in report" :key="item.employee">
              <v-col
                cols="4"
                class="py-2 black--text"
                :class="{ 'error--text': item.employeeId === 'NA' }"
              >
                {{ item.employeeName }}
              </v-col>
              <v-col
                cols="8"
                class="py-2 black--text"
                :class="{ 'error--text': item.employeeId === 'NA' }"
              >
                {{ item.compensation }} CHF
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card-text>
  </v-card>
</template>

<script>
import moment from "moment";
import { mapState } from "vuex";
import DatePicker from "@/components/forms/input/DatePicker";

export default {
  components: {
    DatePicker,
  },

  data() {
    return {
      month: moment().format("YYYY-MM"),
      report: null,
      compensationNotSet: false,
    };
  },
  computed: {
    ...mapState("app", ["configs"]),
  },

  mounted() {
    this.fetchReport(this.month);
  },

  watch: {
    month(value) {
      this.fetchReport(value);
    },
  },

  methods: {
    fetchReport(month) {
      this.$supportService
        .getSupportReport(month)
        .then((res) => {
          this.compensationNotSet = false;
          this.report = Object.keys(res.data).map((id) => {
            return {
              employeeId: id,
              employeeName:
                this.configs.employees.find((e) => e.id == id)?.name ??
                "Nicht Zugewiesen",
              compensation: res.data[id],
            };
          });
        })
        .catch((err) => {
          this.compensationNotSet = true;
        });
    },
  },
};
</script>
