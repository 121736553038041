<template>
  <v-tooltip :top="top" :right="right" :bottom="bottom" :left="left">
    <template v-slot:activator="{ on }">
      <v-btn
        :color="color"
        :dark="dark"
        fab
        v-on="on"
        @click="$emit('click')"
        :class="classNames"
        :small="small"
        :x-small="xSmall"
        :medium="medium"
        :large="large"
        :x-large="xLarge"
        :disabled="disabled"
        :to="to"
      >
        <v-icon>{{ icon }}</v-icon>
      </v-btn>
    </template>
    <span>{{ text }}</span>
  </v-tooltip>
</template>

<script>
export default {
  props: {
    text: {
      type: String,
      requried: true,
    },
    icon: {
      type: String,
      requried: true,
    },
    dark: {
      type: Boolean,
      default: true,
    },
    color: {
      type: String,
      default: "primary",
    },
    size: {
      type: String,
      default: "medium",
    },
    position: {
      type: String,
      default: "bottom",
    },
    classNames: {
      default: "",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    to: {
      type: String,
      default: null,
    },
  },
  computed: {
    small() {
      return this.size === "small";
    },
    xSmall() {
      return this.size === "x-small";
    },
    medium() {
      return this.size === "medium";
    },
    large() {
      return this.size === "large";
    },
    xLarge() {
      return this.size === "x-large";
    },
    top() {
      return this.position === "top";
    },
    right() {
      return this.position === "right";
    },
    bottom() {
      return this.position === "bottom";
    },
    left() {
      return this.position === "left";
    },
  },
};
</script>

<style></style>
