<template>
  <div>
    <v-card color="default" outlined class="tt-card">
      <v-card-title class="header" style="position: relative">
        <v-menu bottom offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn v-bind="attrs" v-on="on">
              <v-icon left>keyboard_arrow_down</v-icon>
              {{ selectedYear }}
            </v-btn>
          </template>
          <v-list>
            <v-list-item
              v-for="year in years"
              :key="year"
              @click="selectedYear = year"
            >
              {{ year }}
            </v-list-item>
          </v-list>
        </v-menu>
        <div class="ml-4 grow">Abrechnungsperioden</div>
        <div v-if="!edit">
          <v-btn @click="openRecalcDialog">
            Neu berechnen ab {{ selectedYear }}
          </v-btn>
          <v-btn @click="edit = true" class="ml-4">Bearbeiten</v-btn>
        </div>
        <div v-else>
          <v-btn @click="cancel" text color="white">Abbrechen</v-btn>
          <v-btn @click="save" class="ml-4">Speichern</v-btn>
        </div>
      </v-card-title>

      <v-card-text>
        <remote-data-table
          :url="listUrl"
          :headers="headers"
          ref="accountingPeriodDataTable"
          class="table"
        >
          <template v-slot:[`item.month`]="{ item }">
            <div class="text-caption" style="white-space: nowrap">
              {{ getMonthName(item.start_date) }}
            </div>
          </template>
          <template v-slot:[`item.time_target`]="{ item }">
            <div class="text-caption" style="white-space: nowrap">
              {{ item.time_target.toFixed(2) }}
            </div>
          </template>
          <template v-slot:[`item.time_clocked`]="{ item }">
            <div class="text-caption" style="white-space: nowrap">
              {{ item.time_clocked.toFixed(2) }}
            </div>
          </template>
          <template v-slot:[`item.time_difference`]="{ item }">
            <div class="text-caption" style="white-space: nowrap">
              {{ item.time_difference.toFixed(2) }}
            </div>
          </template>
          <template v-slot:[`item.time_balance`]="{ item }">
            <div class="text-caption" style="white-space: nowrap">
              {{ item.time_balance.toFixed(2) }}
            </div>
          </template>
          <template v-slot:[`item.time_change`]="{ item }">
            <div>
              <div v-if="!edit">
                {{ item.time_change.toFixed(2) }}
              </div>
              <v-text-field
                v-else
                dense
                type="number"
                :value="item.time_change"
                @input="(value) => handleInput(value, item, 'time_change')"
                :style="{ maxWidth: '80px' }"
              ></v-text-field>
            </div>
          </template>
          <template v-slot:[`item.holiday_taken`]="{ item }">
            <div class="text-caption" style="white-space: nowrap">
              {{ item.holiday_taken.toFixed(2) }}
            </div>
          </template>
          <template v-slot:[`item.holiday_balance`]="{ item }">
            <div class="text-caption" style="white-space: nowrap">
              {{ item.holiday_balance.toFixed(2) }}
            </div>
          </template>
          <template v-slot:[`item.holiday_target_hours`]="{ item }">
            <div>
              <div v-if="!edit">
                {{ item.holiday_target_hours.toFixed(2) }}
              </div>
              <v-text-field
                v-else
                dense
                type="number"
                :value="item.holiday_target_hours"
                @input="
                  (value) => handleInput(value, item, 'holiday_target_hours')
                "
                :style="{ maxWidth: '80px' }"
              ></v-text-field>
            </div>
          </template>
          <template v-slot:[`item.holiday_change`]="{ item }">
            <div>
              <div v-if="!edit">
                {{ item.holiday_change.toFixed(2) }}
              </div>
              <v-text-field
                v-else
                dense
                type="number"
                :value="item.holiday_change"
                @input="(value) => handleInput(value, item, 'holiday_change')"
                :style="{ maxWidth: '80px' }"
              ></v-text-field>
            </div>
          </template>
        </remote-data-table>
      </v-card-text>
    </v-card>

    <confirm-recalc-dialog
      ref="confirmRecalcDialog"
      @refresh="refresh"
    ></confirm-recalc-dialog>
  </div>
</template>

<script>
import { mapState } from "vuex";
import moment from "moment";
import RemoteDataTable from "@/components/RemoteDataTable";
import ConfirmRecalcDialog from "@/components/dialogs/ConfirmRecalcDialog";

export default {
  components: {
    RemoteDataTable,
    ConfirmRecalcDialog,
  },

  props: {
    employee: {
      required: true,
    },
  },

  data: () => ({
    selectedYear: moment().format("YYYY"),
    years: [moment().format("YYYY")],
    edit: false,
    changes: [],
    headers: [
      { text: "Monat", align: "left", value: "month" },
      { text: "Sollzeit", align: "left", value: "time_target" },
      { text: "Arbeitszeit", align: "left", value: "time_clocked" },
      { text: "Zeitdifferenz", align: "left", value: "time_difference" },
      { text: "Zeitbalance", align: "left", value: "time_balance" },
      { text: "Zeit Änderung", align: "left", value: "time_change" },
      { text: "Ferienbezug", align: "left", value: "holiday_taken" },
      { text: "Ferienbalance", align: "left", value: "holiday_balance" },
      {
        text: "Ferien Stundentag",
        align: "left",
        value: "holiday_target_hours",
      },
      { text: "Ferien Änderung", align: "left", value: "holiday_change" },
    ],
  }),

  computed: {
    ...mapState("user", ["user"]),
    ...mapState("app", ["configs"]),

    listUrl() {
      return this.$accountingPeriodService.getEmployeeLink(
        this.employee.id,
        this.selectedYear
      );
    },
  },

  mounted() {
    this.setYears(this.employee);
  },

  methods: {
    refresh() {
      this.$refs.accountingPeriodDataTable.getDataFromApi();
      this.$emit("refresh");
    },

    getMonthName(date) {
      return moment(date).format("MMMM");
    },

    setYears(e) {
      if (e.start_date) {
        this.years = [];
        const cursor = moment(e.start_date);
        while (moment() > cursor) {
          this.years.push(cursor.format("YYYY"));
          cursor.add(1, "years");
        }
        this.years = this.years.reverse();
        this.selectedYear = this.years[0];
      }
    },

    handleInput(v, item, key) {
      const existsIndex = this.changes.findIndex(
        (change) => change.id === item.id
      );

      const value = parseFloat(v);
      if (existsIndex > -1) {
        this.changes[existsIndex][key] = value;
      } else {
        this.changes.push(item);
        this.changes[this.changes.length - 1][key] = value;
      }
    },

    cancel() {
      this.changes = [];
      this.edit = false;
    },

    save() {
      this.$accountingPeriodService
        .updateChanges(this.changes)
        .then(() => {
          this.refresh();
          this.$toast.open("Änderungen wurden gespeichert.");
          this.changes = [];
          this.edit = false;
        })
        .catch(() => {
          this.$toast.open({
            message: "Etwas ist schiefgelaufen",
            type: "error",
          });
        });
    },

    openRecalcDialog() {
      this.$refs.confirmRecalcDialog.open(this.employee, this.selectedYear);
    },
  },

  watch: {
    employee(value) {
      this.setYears(value);
    },
  },
};
</script>
