var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',{staticClass:"tt-card",attrs:{"color":"default","outlined":""}},[_c('v-card-title',{staticClass:"header"},[_c('div',{staticClass:"title"},[_vm._v("Offene Aufgaben")])]),_c('v-card-text',[_c('remote-data-table',{ref:"absenceBackofficeTaskTable",staticClass:"table",attrs:{"url":_vm.listUrl,"headers":_vm.headers,"sort-by":'created_at'},scopedSlots:_vm._u([{key:"item.task_type",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-caption",staticStyle:{"white-space":"nowrap"}},[(_vm.getType(item.type).link)?_c('router-link',{attrs:{"to":_vm.getType(item.type).link}},[_vm._v(" "+_vm._s(_vm.getType(item.type).name)+" ")]):_c('div',[_vm._v(" "+_vm._s(_vm.getType(item.type).name)+" ")])],1)]}},{key:"item.employee_ref",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-caption",staticStyle:{"white-space":"nowrap"}},[_vm._v(" "+_vm._s(_vm.getEmployeeName(item.employee_ref))+" ")])]}},{key:"item.instruction",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-caption"},[_vm._v(" "+_vm._s(_vm.getType(item.type).description)+" ")])]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [(_vm.getType(item.type).has_info)?_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){return _vm.handleInfoAction(item)}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("info")]),_vm._v(" Details ")],1):(_vm.getType(item.type).action_text !== null)?_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){return _vm.handleRequestAction(item)}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("task")]),_vm._v(" "+_vm._s(_vm.getType(item.type).action_text)+" ")],1):_c('v-btn',{attrs:{"text":"","disabled":"","color":"primary"}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("autorenew")]),_vm._v(" Automatisch ")],1)]}}],null,true)})],1)],1),_c('absence-task-dialog',{ref:"absenceTaskDialog",on:{"done":_vm.refresh}}),_c('absence-delete-task-dialog',{ref:"absenceDeleteTaskDialog",on:{"done":_vm.refresh}}),_c('admin-task-complete-dialog',{ref:"adminTaskCompleteDialog",on:{"done":_vm.refresh}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }