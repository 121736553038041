<template>
  <v-container fluid>
    <project-table-detail></project-table-detail>
  </v-container>
</template>

<script>
import ProjectTableDetail from "@/components/tables/ProjectTableDetail";

export default {
  name: "projects",

  components: {
    ProjectTableDetail,
  },
};
</script>
