<template>
  <v-dialog v-model="isOpen" scrollable width="500">
    <v-card>
      <v-card-title :class="['error', 'white--text']">
        <span class="subtitle-1">Löschen</span>
      </v-card-title>

      <v-card-text class="mt-3">
        <p>
          Soll der Eintrag
          <strong>{{ entityName ? entityName : "" }}</strong> endgültig gelöscht
          werden?
        </p>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="default" text @click="close()"> Nein </v-btn>
        <v-btn color="error" text @click="deleteEntity()"> Ja </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapMutations } from "vuex";

export default {
  data: () => ({
    isOpen: false,
    entityId: null,
    entityName: null,
    entityUrl: null,
  }),

  methods: {
    ...mapMutations("snackbar", ["showErrorSnackbar"]),

    open(id, name, entityUrl) {
      this.isOpen = true;
      this.entityId = id;
      this.entityName = name;
      this.entityUrl = entityUrl;
    },

    close() {
      this.isOpen = false;
      this.reset();
    },

    reset() {
      this.entityId = null;
      this.entityName = null;
      this.entityUrl = null;
    },

    /**
     * Delete entity.
     */
    deleteEntity() {
      this.$api.http
        .delete(`api/${this.entityUrl}/${this.entityId}`)
        .then(() => {
          this.$toast.open("Eintrag wurde erfolgreich gelöscht.");
          this.$emit("deleted");
          this.close();
        })
        .catch(() => {
          this.$toast.open({
            message: "Eintrag konnte nicht gelöscht werden.",
            type: "error",
          });
          this.close();
        });
    },
  },
};
</script>
