<template>
  <v-dialog v-model="isOpen" persistent max-width="1024" origin="top center">
    <v-card>
      <v-card-title class="primary white--text mb-5">
        Filtereinstellungen
      </v-card-title>
      <v-card-text v-if="ready">
        <h2 class="mb-3">Projekteinstellungen</h2>
        <v-radio-group v-model="status" row hide-details>
          <v-radio label="Alle" :value="'all'"></v-radio>
          <v-radio label="Aktive" :value="'active'"></v-radio>
          <v-radio label="Inaktive" :value="'inactive'"></v-radio>
        </v-radio-group>
        <v-checkbox v-model="scheduled">
          <template v-slot:label>
            <div>Nur Projekte mit zugewiesene Aufgaben anzeigen</div>
          </template>
        </v-checkbox>
        <h2 class="mt-3 mb-3">Filter</h2>
        <v-alert v-if="showFilterWarning" dark color="warning" class="mb-6">
          Es gibt gespeicherte Filtereinstellungen die nicht mehr aktuell sind
          und zu ungewünschten Ergebnissen führen.
          <v-btn
            small
            class="elevation-0 ml-2"
            color="warning darken-3"
            @click="resetFilter()"
          >
            Jetzt zurücksetzen
          </v-btn>
        </v-alert>

        <config-select
          v-model="projectStatusFilterValue"
          :items="projectStatus"
          :multiple="true"
          :chip="true"
          label="Projekt Status."
          class="mb-4"
        ></config-select>

        <config-select
          v-model="projectTemplatesFilterValue"
          :items="projectTemplates"
          :multiple="true"
          :chip="true"
          label="Projekt Templates"
          class="mb-4"
        ></config-select>

        <project-select
          v-model="projectFilterValue"
          :items="projectItems"
          label="Projekte"
          :multiple="true"
          :chip="true"
          class="mb-4"
        ></project-select>

        <customer-select
          v-model="customersFilterValue"
          :items="customerItems"
          label="Customer"
          :multiple="true"
          :chip="true"
          class="mb-4"
        ></customer-select>

        <user-select
          v-model="employeeManagersFilterValue"
          :items="employeeItems"
          :multiple="true"
          :chip="true"
          label="Projektleiter / Stv."
          class="mb-4"
        ></user-select>

        <user-select
          v-model="employeeTasksFilterValue"
          :items="employeeItems"
          :multiple="true"
          :chip="true"
          label="Mitarbeiter (Aufgaben)"
          class="mb-4"
        ></user-select>
      </v-card-text>

      <v-card-text v-else>
        <v-progress-linear indeterminate color="primary"></v-progress-linear>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="default" text @click="close()"> Abbrechen </v-btn>
        <v-btn color="primary" text @click="save()"> Filtern </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState, mapMutations } from "vuex";
import ProjectSelect from "@/components/forms/common/ProjectSelect";
import CustomerSelect from "@/components/forms/common/CustomerSelect";
import UserSelect from "@/components/forms/common/UserSelect";
import ConfigSelect from "@/components/forms/common/ConfigSelect";

export default {
  data: () => ({
    isOpen: false,
    ready: false,
    projects: [],
    projectStatus: [],
    projectTemplates: [],
  }),

  components: {
    ProjectSelect,
    CustomerSelect,
    UserSelect,
    ConfigSelect,
  },

  computed: {
    ...mapState("app", ["configs"]),
    ...mapState("rp", [
      "projectStatusFilter",
      "projectTemplatesFilter",
      "projectsFilter",
      "customersFilter",
      "employeeManagersFilter",
      "employeeTasksFilter",
      "statusFilter",
      "scheduledFilter",
    ]),

    showFilterWarning() {
      const hasRemovedProject = this.projectsFilter.find(
        (id) => !this.projectItems.find((project) => project.id === id)
      );
      const hasCustomers = this.customersFilter.find(
        (cFilter) =>
          !this.customerItems.find((customer) => customer === cFilter)
      );
      const hasRemovedEmployee = this.employeeTasksFilter.find(
        (id) => !this.employeeItems.find((employee) => employee.id === id)
      );
      return hasRemovedProject || hasRemovedEmployee || hasCustomers;
    },

    employeeItems() {
      return this.configs.employees
        .filter((employee) => employee.is_plannable)
        .map((employee) => ({
          id: employee.id,
          name: employee.name,
        }));
    },

    projectItems() {
      return this.projects.map((project) => ({
        id: project.id,
        name: project.name,
        customer: project.customer,
      }));
    },

    customerItems() {
      let customers = new Set();

      this.projects.forEach((p) => {
        customers.add(p.customer);
      });

      return Array.from(customers);
    },

    projectStatusFilterValue: {
      get() {
        return this.projectStatusFilter;
      },
      set(value) {
        this.setProjectStatusFilter(value);
      },
    },

    projectTemplatesFilterValue: {
      get() {
        return this.projectTemplatesFilter;
      },
      set(value) {
        this.setProjectTemplatesFilter(value);
      },
    },

    projectFilterValue: {
      get() {
        return this.projectsFilter;
      },
      set(value) {
        this.setProjectsFilter(value);
      },
    },

    customersFilterValue: {
      get() {
        return this.customersFilter;
      },
      set(value) {
        this.setCustomersFilter(value);
      },
    },

    employeeManagersFilterValue: {
      get() {
        return this.employeeManagersFilter;
      },
      set(value) {
        this.setEmployeeManagersFilter(value);
      },
    },

    employeeTasksFilterValue: {
      get() {
        return this.employeeTasksFilter;
      },
      set(value) {
        this.setEmployeeTasksFilter(value);
      },
    },

    status: {
      get() {
        return this.statusFilter;
      },
      set(value) {
        this.setStatusFilter(value);
      },
    },

    scheduled: {
      get() {
        return this.scheduledFilter;
      },
      set(value) {
        this.setScheduledFilter(value);
      },
    },
  },

  methods: {
    ...mapMutations("rp", [
      "setProjectStatusFilter",
      "setProjectTemplatesFilter",
      "setProjectsFilter",
      "setCustomersFilter",
      "setEmployeeManagersFilter",
      "setEmployeeTasksFilter",
      "setStatusFilter",
      "setScheduledFilter",
    ]),

    open() {
      this.isOpen = true;
      this.fetchProjects();
      this.fetchProjectStatus();
      this.fetchProjectTemplates();
    },

    close() {
      this.isOpen = false;
      this.reset();
    },

    reset() {
      this.ready = false;
    },

    save() {
      this.$emit("rerender");
      this.close();
    },

    fetchProjects() {
      this.$api.http.get(`api/projects/simple`).then((response) => {
        this.projects = response.data;
        this.ready = true;
      });
    },

    fetchProjectStatus() {
      this.$api.http.get("api/projectstatus").then((res) => {
        this.projectStatus = res.data;
      });
    },

    fetchProjectTemplates() {
      this.$api.http.get("api/projecttemplates").then((res) => {
        this.projectTemplates = res.data;
      });
    },

    resetFilter() {
      this.setProjectsFilter([]);
      this.setCustomersFilter([]);
      this.setEmployeeManagersFilter([]);
      this.setEmployeeTasksFilter([]);
      this.setStatusFilter("active");
      this.setScheduledFilter(true);
    },
  },
};
</script>

<style></style>
