<template>
  <v-card color="default" outlined class="tt-card">
    <v-card-title class="header"> Deine Projekte </v-card-title>
    <v-card-text>
      <remote-data-table
        :url="`api/projects?relevant&employee_id=${this.user.employee.id}&_per_page=9999'`"
        :headers="headers"
        ref="myProjectsDatatable"
      >
        <template v-slot:[`item.name`]="{ item }">
          <router-link :to="`/projects/${item.id}`">
            {{ item.name }}
          </router-link>
        </template>
        <template v-slot:[`item.manager`]="{ item }">
          <span class="text-caption" style="white-space: nowrap">
            {{ item.manager ? item.manager.name : null }}
          </span>
        </template>
        <template v-slot:[`item.deputy`]="{ item }">
          <span class="text-caption" style="white-space: nowrap">
            {{ item.deputy ? item.deputy.name : null }}
          </span>
        </template>
      </remote-data-table>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapState } from "vuex";
import RemoteDataTable from "@/components/RemoteDataTable";

export default {
  components: {
    RemoteDataTable,
  },

  props: ["title", "isFilterable", "url"],

  computed: {
    ...mapState("user", ["user"]),
  },

  data: () => ({
    headers: [
      { text: "Name", align: "left", value: "name" },
      {
        text: "Projektleiter",
        align: "left",
        value: "manager",
        sortable: false,
      },
      {
        text: "Stv. Projektleiter",
        align: "left",
        value: "deputy",
        sortable: false,
      },
    ],
  }),
};
</script>
