<template>
  <div>
    <v-card color="default" outlined class="tt-card">
      <v-card-title class="header" style="position: relative">
        Projekte
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              color="primary"
              fab
              dark
              absolute
              bottom
              right
              @click="openCreateTaskDialog"
              v-bind="attrs"
              v-on="on"
            >
              <v-icon>add</v-icon>
            </v-btn>
          </template>
          <span>Neue Aufgabe</span>
        </v-tooltip>
      </v-card-title>
      <v-card-text>
        <remote-data-table
          :url="'api/projects'"
          :search-enabled="true"
          :headers="headers"
          :filters="filters"
          :sort-by="'-created_at'"
          ref="projectsDatatable"
        >
          <template v-slot:[`item.id`]="{ item }">
            <router-link :to="`/projects/${item.id}`">
              {{ item.id }}
            </router-link>
          </template>
          <template v-slot:[`item.status`]="{ item }">
            <project-status-label :project="item" small />
          </template>
          <template v-slot:[`item.name`]="{ item }">
            <router-link :to="`/projects/${item.id}`">
              {{ item.name }}
            </router-link>
          </template>
          <template v-slot:[`item.manager`]="{ item }">
            <span class="text-caption" style="white-space: nowrap">
              {{ item.manager ? item.manager.name : null }}
            </span>
          </template>
          <template v-slot:[`item.deputy`]="{ item }">
            <span class="text-caption" style="white-space: nowrap">
              {{ item.deputy ? item.deputy.name : null }}
            </span>
          </template>
        </remote-data-table>
      </v-card-text>
    </v-card>

    <task-dialog ref="taskDialog" @saved="goToProject" />
  </div>
</template>

<script>
import { mapState } from "vuex";
import RemoteDataTable from "@/components/RemoteDataTable";
import ProjectStatusLabel from "@/components/labels/ProjectStatusLabel.vue";
import TaskDialog from "@/components/dialogs/TaskDialog";

export default {
  components: {
    RemoteDataTable,
    ProjectStatusLabel,
    TaskDialog,
  },

  computed: {
    ...mapState("app", ["configs"]),
  },

  data: () => ({
    headers: [
      { text: "ID", align: "left", sortable: true, value: "id" },
      { text: "Name", align: "left", sortable: true, value: "name" },
      { text: "Kunde", align: "left", sortable: true, value: "customer" },
      {
        text: "PL",
        align: "left",
        sortable: false,
        value: "manager",
        width: 80,
      },
      {
        text: "PL Stv.",
        align: "left",
        sortable: false,
        value: "deputy",
        width: 80,
      },
      { text: "Status", align: "right", sortable: false, value: "status" },
    ],
    filters: [
      {
        type: "select_multi",
        field: "status",
        label: "Status",
        values: [],
        default: [],
      },
      {
        type: "select_multi",
        field: "manager",
        label: "Projektleiter / Stv.",
        values: [],
        default: null,
      },
      {
        type: "checkbox",
        field: "relevant",
        label: "Aktuelle",
        default: false,
      },
    ],
  }),

  mounted() {
    if (this.configs) {
      const statusIndex = this.filters.findIndex((f) => f.field === "status");
      this.filters[statusIndex].values = this.configs.project_statuses.map(
        (s) => {
          return { text: s.name, value: s.remote_id };
        }
      );

      const teamLeadIndex = this.filters.findIndex(
        (f) => f.field === "manager"
      );
      this.filters[teamLeadIndex].values = this.configs.employees.map((e) => {
        return { text: e.name, value: e.id };
      });
    }
  },

  methods: {
    openCreateTaskDialog() {
      this.$refs.taskDialog.open("create");
    },

    goToProject(task) {
      this.$router.push("/projects/" + task.budget.project.id);
    },
  },
};
</script>
