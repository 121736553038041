<template>
  <v-dialog v-model="isOpen" scrollable persistent width="1024">
    <time-entry-card
      v-if="isOpen"
      :selectedEntry="selectedEntry"
      :selectedTask="selectedTask"
      :allProjects="allProjects"
      :allMyTasks="allMyTasks"
      :notConcluded="notConcluded"
      @changed="changed"
      @cancel="close"
    >
    </time-entry-card>
  </v-dialog>
</template>

<script>
import { mapMutations } from "vuex";
import TimeEntryCard from "@/components/cards/TimeEntryCard";

export default {
  components: { TimeEntryCard },

  data: () => ({
    isOpen: false,
    selectedEntry: null,
    selectedTask: null,
    allProjects: null,
    allMyTasks: null,
  }),

  props: {
    notConcluded: {
      default() {
        return [];
      },
    },
  },

  methods: {
    ...mapMutations("snackbar", ["showErrorSnackbar"]),

    open(projects, tasks, entry = null, task = null) {
      this.allProjects = projects;
      this.allMyTasks = tasks;

      this.selectedEntry = entry;
      this.selectedTask = task;

      this.isOpen = true;
    },

    reset() {
      this.selectedEntry = null;
      this.selectedTask = null;
    },

    changed() {
      this.close();
      this.$emit("changed");
    },

    close() {
      this.reset();
      this.isOpen = false;
    },
  },
};
</script>
