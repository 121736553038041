<template>
  <div class="workloads-employees">
    <template v-if="loading">
      <v-progress-circular indeterminate color="white"></v-progress-circular>
    </template>
    <template v-else>
      <template v-if="data.length > 0">
        <workload-employees :data="data" />
      </template>
      <div v-else class="no-workloads">Keine Daten vorhanden.</div>
    </template>
  </div>
</template>

<script>
import { mapState } from "vuex";
import WorkloadEmployees from "@/components/rp/employees/WorkloadEmployees.vue";

export default {
  components: { WorkloadEmployees },

  data: () => ({
    data: [],
    loading: true,
  }),

  computed: {
    ...mapState("rp", [
      "projectStatusFilter",
      "projectTemplatesFilter",
      "projectsFilter",
      "customersFilter",
      "employeeTasksFilter",
      "employeeManagersFilter",
      "statusFilter",
      "scheduledFilter",
      "startDate",
      "endDate",
    ]),
  },

  methods: {
    /**
     * Fetch workloads from API.
     */
    fetchData() {
      let params = {};
      if (this.projectStatusFilter.length > 0) {
        params["_projects_status_filter"] = this.projectStatusFilter.join(",");
      }
      if (this.projectTemplatesFilter.length > 0) {
        params["_projects_templates_filter"] =
          this.projectTemplatesFilter.join(",");
      }
      if (this.projectsFilter.length > 0) {
        params["_projects_filter"] = this.projectsFilter.join(",");
      }
      if (this.customersFilter.length > 0) {
        params["_customers_filter"] = this.customersFilter.join(",");
      }
      if (this.employeeTasksFilter.length > 0) {
        params["_employee_tasks_filter"] = this.employeeTasksFilter.join(",");
      }
      if (this.employeeManagersFilter.length > 0) {
        params["_employee_managers_filter"] =
          this.employeeManagersFilter.join(",");
      }
      if (this.statusFilter) {
        params.status = this.statusFilter;
      }
      if (this.scheduledFilter) {
        params.scheduled = this.scheduledFilter;
      }
      if (this.startDate) {
        params.from = this.startDate.format("YYYY-MM-DD");
      }
      if (this.endDate) {
        params.to = this.endDate.format("YYYY-MM-DD");
      }
      return this.$api.http.get("api/rp/employees/workloads", { params });
    },

    /**
     * Reload data.
     */
    async reload() {
      this.loading = true;
      const { data } = await this.fetchData();
      this.data = data;
      this.loading = false;
    },
  },
};
</script>

<style lang="scss">
.workloads-employees {
  width: 100%;
  min-height: 140px;
  background: var(--v-primary-darken1);
  position: relative;
  overflow: visible;
  padding: 40px 20px 20px;

  .workloads-chart {
    overflow: visible;
    white-space: nowrap;
    position: relative;
    width: 100%;
  }

  .v-progress-circular {
    position: absolute;
    left: 50%;
    top: 50%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
  }

  .no-workloads {
    color: white;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
}
</style>
