<template>
  <v-card class="tt-card">
    <v-card-title class="header" style="position: relative">
      <v-menu bottom offset-y max-height="352">
        <template v-slot:activator="{ on, attrs }">
          <v-btn v-bind="attrs" v-on="on">{{
            selectedEmployee ? selectedEmployee.name : "Mitarbeiter"
          }}</v-btn>
        </template>
        <v-list dense>
          <v-list-item
            v-for="employee in configs.employees"
            :key="employee.id"
            btn
            @click="selectEmployee(employee)"
          >
            {{ employee.name }}
          </v-list-item>
        </v-list>
      </v-menu>
      <div class="ml-4 grow">Mitarbeiter</div>
      <v-btn v-if="!edit" @click="edit = true">Bearbeiten</v-btn>
    </v-card-title>

    <v-card-text class="pt-4">
      <admin-employee-form
        v-if="selectedEmployee"
        :employee="selectedEmployee"
        :edit="edit"
        @saved="refresh"
        @cancel="cancel"
      ></admin-employee-form>
      <h3 v-else>Bitte Mitarbeiter auswählen</h3>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapMutations, mapState } from "vuex";
import AdminEmployeeForm from "@/components/forms/AdminEmployeeForm";

export default {
  components: {
    AdminEmployeeForm,
  },

  data() {
    return {
      selectedEmployee: null,
      edit: false,
    };
  },

  computed: {
    ...mapState("app", ["configs"]),
  },

  mounted() {
    this.selectFirstEmployee();
  },

  methods: {
    ...mapMutations("app", ["setEmployees"]),

    selectFirstEmployee() {
      if (!this.selectedEmployee && this.configs.employees.length > 0) {
        this.selectEmployee(this.configs.employees[0]);
      }
    },

    selectEmployee(employee) {
      this.selectedEmployee = employee;
      this.$emit("selected", employee);
    },

    refresh() {
      this.edit = false;
      this.$employeeService.getEmployees().then((res) => {
        this.setEmployees(res.data.data);
      });
    },

    cancel() {
      this.edit = false;
    },
  },
};
</script>
