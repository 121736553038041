import Vue from "vue";
import Vuex from "vuex";
import app from "./modules/app";
import snackbar from "./modules/snackbar";
import user from "./modules/user";
import fullscreen from "./modules/fullscreen";
import rp from "./modules/rp";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    app,
    snackbar,
    user,
    fullscreen,
    rp,
  },
});
