<template>
  <v-dialog v-model="isOpen" scrollable width="500">
    <v-card>
      <v-card-title :class="['primary', 'white--text']">
        <span class="subtitle-1">Erledigt</span>
      </v-card-title>

      <v-card-text class="mt-3">
        <p>Wurde die Aufgabe erledigt?</p>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="default" text @click="close()" class="ml-6 px-4">
          Abbrechen
        </v-btn>
        <v-btn
          color="primary"
          @click="completeAbsenceProcess()"
          class="ml-6 px-4"
        >
          Ja
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data: () => ({
    isOpen: false,
    request: null,
    employeeName: "",
  }),

  methods: {
    open(request, employee) {
      this.isOpen = true;
      this.request = request;
      this.employeeName = employee;
    },

    close() {
      this.reset();
      this.isOpen = false;
    },

    reset() {
      this.request = null;
      this.employeeName = "";
    },

    completeAbsenceProcess() {
      this.$api.http
        .put(`api/admin/tasks/complete/${this.request.id}`)
        .then(() => {
          this.$toast.open("Aufgabe erfolgreicht abgeschlossen.");
          this.$emit("done");
          this.close();
        })
        .catch(() => {
          this.$toast.open({
            message: "Eintrag konnte nicht abgeschlossen werden.",
            type: "error",
          });
          this.close();
        });
    },
  },
};
</script>
