export default class NotificationService {
  url = "api/notifications";

  constructor(api) {
    this.api = api;
  }

  getNotifications() {
    return this.api.http.get(this.url);
  }

  readNotification(notificationId) {
    return this.api.http.put(`${this.url}/read/${notificationId}`);
  }
}
