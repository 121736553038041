<template>
  <div class="project-template-label">
    <v-chip color="default" label :small="small" :large="large">
      {{ text }}
    </v-chip>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  props: {
    project: {
      type: Object,
      required: true,
    },
    small: {
      type: Boolean,
      default: false,
    },
    large: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    ...mapState("app", ["configs"]),

    text() {
      const template = this.configs.project_templates.find(
        (s) => s.id === parseInt(this.project.template)
      );
      return template ? template.name : "Unbekannt";
    },
  },
};
</script>

<style lang="scss"></style>
