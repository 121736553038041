export default class AccountingPeriodService {
  url = "api/accounting-periods";

  constructor(api) {
    this.api = api;
  }

  getEmployeeLink(employeeId, year) {
    return `${this.url}/employee/${employeeId}/${year}`;
  }

  getOverview(employeeId) {
    return this.api.http.get(`api/accounting-periods/overview/${employeeId}`);
  }

  getOverviews() {
    return this.api.http.get(`api/accounting-periods/overviews`);
  }

  getNotConcluded() {
    return this.api.http.get(`api/accounting-periods/conclude`);
  }

  getAccountingPeriods(employeeId) {
    return this.api.http.get(this.getEmployeeLink(employeeId));
  }

  updateChanges(accountingPeriods) {
    return this.api.http.put(`${this.url}`, { periods: accountingPeriods });
  }
}
