import { TaskModel as Model } from "@tingo-gmbh/bryntum-gantt";

class TaskModel extends Model {
  static get fields() {
    return [
      { name: "description", type: "string" },
      { name: "time_expected", type: "number", defaultValue: 0 },
      { name: "time_offered", type: "number", defaultValue: 0 },
      { name: "time_used", type: "number", defaultValue: 0 },
      { name: "task_id", type: "number", defaultValue: 0 },
      { name: "budget_id", type: "number", defaultValue: 0 },
      { name: "project_id", type: "number", defaultValue: 0 },
      { name: "employee_id", type: "number", defaultValue: 0 },
      { name: "type", type: "string" },
      { name: "employee", type: "string" },
      { name: "statusName", type: "string" },
    ];
  }

  static set fields(value) {}
}

export default TaskModel;
