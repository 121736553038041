<template>
  <div>
    <v-card color="default" outlined class="tt-card">
      <v-card-title class="header" style="position: relative">
        <v-menu bottom offset-y v-model="openDatePicker">
          <template v-slot:activator="{ on, attrs }">
            <v-btn v-bind="attrs" v-on="on" color="white">
              <v-icon left>event</v-icon>
              {{ titleDate }}
            </v-btn>
          </template>
          <v-date-picker v-model="displayDate"></v-date-picker>
        </v-menu>

        <div class="ml-4" v-if="$vuetify.breakpoint.mdAndUp">Zeiterfassung</div>
        <div class="ml-4">{{ titleTotalDuration }}</div>

        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              color="primary"
              fab
              dark
              absolute
              bottom
              right
              @click="openCreateDialog"
              v-bind="attrs"
              v-on="on"
            >
              <v-icon>add</v-icon>
            </v-btn>
          </template>
          <span>Neue Zeiterfassung</span>
        </v-tooltip>
      </v-card-title>
      <v-card-text>
        <remote-data-table
          :url="listUrl"
          :headers="headers"
          :sort-by="'start_time'"
          ref="timeEntryDataTable"
          class="table"
          @dataItems="updateItems"
          @dblclick="dblclick"
        >
          <template v-slot:[`item.budget.project.name`]="{ item }">
            <div class="text-caption" style="white-space: nowrap">
              {{ item.project.name }}
            </div>
          </template>
          <template v-slot:[`item.remote_category_id`]="{ item }">
            <div class="text-caption" style="white-space: nowrap">
              {{ getCategory(item.remote_category_id) }}
            </div>
          </template>
          <template v-slot:[`item.billable_rate`]="{ item }">
            <div class="text-caption" style="white-space: nowrap">
              {{ item.billable_rate }}%
            </div>
          </template>
          <template v-slot:[`item.start_time`]="{ item }">
            <div class="text-caption" style="white-space: nowrap">
              {{ item.start_time.slice(11, 16) }}
            </div>
          </template>
          <template v-slot:[`item.end_time`]="{ item }">
            <div class="text-caption" style="white-space: nowrap">
              <span v-if="item.end_time && item.end_time.length > 15">
                {{ item.end_time.slice(11, 16) }}
              </span>
              <v-btn v-else text @click="openEditDialog(item)">
                <v-icon color="error">access_time</v-icon>
              </v-btn>
            </div>
          </template>
          <template v-slot:[`item.action`]="{ item }">
            <v-menu bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn x-small v-bind="attrs" v-on="on" text fab>
                  <v-icon>more_vert</v-icon>
                </v-btn>
              </template>
              <v-list>
                <v-list-item
                  @click="openEditDialog(item)"
                  :disabled="!isEditable(item)"
                >
                  Bearbeiten
                </v-list-item>
                <v-list-item
                  @click="deleteEntry(item.id, item.name)"
                  :disabled="!isEditable(item)"
                >
                  Löschen
                </v-list-item>
              </v-list>
            </v-menu>
          </template>
        </remote-data-table>
      </v-card-text>
    </v-card>

    <time-entry-dialog
      ref="timeEntryDialog"
      :notConcluded="notConcluded"
      @changed="refresh"
    ></time-entry-dialog>
    <delete-dialog
      ref="deleteTimeEntryDialog"
      @deleted="refresh"
    ></delete-dialog>
  </div>
</template>

<script>
import { mapState } from "vuex";
import moment from "moment";
import RemoteDataTable from "@/components/RemoteDataTable";
import TimeEntryDialog from "@/components/dialogs/TimeEntryDialog";
import DeleteDialog from "@/components/dialogs/DeleteDialog";

export default {
  components: {
    RemoteDataTable,
    TimeEntryDialog,
    DeleteDialog,
  },

  props: {
    allProjects: {
      required: true,
    },
    allMyTasks: {
      required: true,
    },
    notConcluded: {
      required: true,
    },
  },

  data: () => ({
    timeEntries: [],
    displayDate: moment().format("YYYY-MM-DD"),
    openDatePicker: false,
    headers: [
      { text: "Projekt", align: "left", value: "project.name" },
      { text: "Budget", align: "left", value: "budget.name" },
      { text: "Aufgabe", align: "left", value: "task.name" },
      { text: "Arbeitsgang", align: "left", value: "remote_category_id" },
      { text: "Beschreibung", align: "left", value: "description" },
      {
        text: "Beschreibung Intern",
        align: "left",
        value: "description_intern",
      },
      { text: "Rate", align: "center", value: "billable_rate" },
      { text: "Von", align: "center", value: "start_time" },
      { text: "Bis", align: "center", value: "end_time" },
      { text: "", align: "right", value: "action" },
    ],
  }),

  computed: {
    ...mapState("user", ["user"]),
    ...mapState("app", ["configs", "activities"]),

    titleDate() {
      return moment(this.displayDate).format("DD MMM YYYY");
    },

    titleTotalDuration() {
      if (this.timeEntries && this.timeEntries.length > 0) {
        let duration = 0;
        this.timeEntries.forEach((i) => {
          const startTime = moment(i.start_time, "YYYY-MM-DD HH:mm:ss");
          const endTime = moment(i.end_time, "YYYY-MM-DD HH:mm:ss");

          if (!isNaN(endTime)) {
            duration += endTime.diff(startTime, "milliseconds", true);
          }
        });

        const tmp = moment.duration(duration);
        return tmp.hours() + "h " + tmp.minutes() + "min";
      }

      return "0h";
    },

    listUrl() {
      return `api/time-entries?employee_id=${this.user.employee.id}&date=${this.displayDate}`;
    },
  },

  methods: {
    isEditable(item) {
      return this.$timeEntryService.isEditable(this.notConcluded, item);
    },

    updateItems(newTimeEntries) {
      this.timeEntries = newTimeEntries;
    },

    openCreateDialog() {
      this.$refs.timeEntryDialog.open(this.allProjects, this.allMyTasks);
    },

    openEditDialog(item) {
      this.$refs.timeEntryDialog.open(this.allProjects, this.allMyTasks, item);
    },

    deleteEntry(id) {
      this.$refs.deleteTimeEntryDialog.open(id, null, "time-entries");
    },

    getCategory(categoryId) {
      return (
        this.activities.find((a) => a.remote_id === categoryId)?.description ??
        "Arbeitsvorgang nicht gefunden"
      );
    },

    refresh() {
      this.$refs.timeEntryDataTable.getDataFromApi();
    },

    dblclick(item) {
      if (this.isEditable(item)) {
        this.openEditDialog(item);
      }
    },
  },
};
</script>
