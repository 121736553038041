import moment from "moment";

export default class SupportService {
  url = "api/supports";
  days = [
    "monday",
    "tuesday",
    "wednesday",
    "thursday",
    "friday",
    "saturday",
    "sunday",
  ];

  constructor(api) {
    this.api = api;
  }

  getYear(start) {
    return this.api.http.get(`${this.url}/year/${start}`);
  }

  getEmployee(employees, id) {
    const result = employees.find((e) => e.id === id);
    if (result) {
      return result.name;
    }

    return "MA nicht gefunden";
  }

  getSupportReport(month) {
    return this.api.http.get(`${this.url}/report/${month}`);
  }

  findAssigned(week) {
    const assigned = [];

    this.days.forEach((day, index) => {
      const nof = assigned.length;

      if (nof === 0) {
        assigned.push({
          employee: week[day],
          start: index,
          end: null,
        });
      } else {
        if (assigned[nof - 1].employee !== week[day]) {
          assigned[nof - 1].end = index;

          assigned.push({
            employee: week[day],
            start: index,
            end: null,
          });
        }
      }
    });

    assigned[assigned.length - 1].end = 7;
    return assigned;
  }

  parseData(year, employees) {
    const events = [];

    year.forEach((week) => {
      const assigned = this.findAssigned(week);

      const weekEvents = assigned.map((ass, index) => {
        const start = moment(week.week_start).add(ass.start, "days");
        const end = moment(week.week_start).add(ass.end, "days");
        const employee = this.getEmployee(employees, ass.employee);

        return {
          id: `${week.id}_${index}`,
          title: employee,
          start: start.format("YYYY-MM-DD"),
          end: end.format("YYYY-MM-DD"),
          color: this.getEventColor(week.main, ass.employee),
        };
      });

      events.push(...weekEvents);
    });

    return events;
  }

  getEventColor(main, other) {
    if (main !== other) {
      return "var(--v-accent-base)";
    }

    return "var(--v-primary-base)";
  }
}
