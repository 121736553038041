export default class EmployeeService {
  url = "api/employees";

  constructor(api) {
    this.api = api;
  }

  getEmployees() {
    return this.api.http.get(this.url);
  }

  updateEmployee(employee) {
    return this.api.http.put(`${this.url}/${employee.id}`, {
      start_date: employee.start_date,
      end_date: employee.end_date,
      is_plannable: employee.is_plannable,
      is_manager: employee.is_manager,
      is_backoffice: employee.is_backoffice,
      is_support: employee.is_support,
    });
  }
}
