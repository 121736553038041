<template>
  <div class="workloads-projects">
    <template v-if="loading">
      <v-progress-circular indeterminate color="white"></v-progress-circular>
    </template>
    <template v-else>
      <div class="workloads-chart" v-if="workloads.length > 0">
        <workload-item
          v-for="workload in workloads"
          :key="workload.date"
          :workload="workload"
          :total="workloads.length"
          :peak-min="peakMin"
          :peak-max="peakMax"
          @showDayProjects="showDayProjectsDialog"
        ></workload-item>
      </div>
      <div v-else class="no-workloads">Keine Daten vorhanden.</div>
    </template>
    <day-projects-dialog ref="dayProjectsDialog" />
  </div>
</template>

<script>
import { mapState } from "vuex";
import WorkloadItem from "@/components/rp/projects/WorkloadItem";
import DayProjectsDialog from "@/components/rp/dialogs/DayProjectsDialog.vue";

export default {
  components: {
    WorkloadItem,
    DayProjectsDialog,
  },

  data: () => ({
    workloads: [],
    peakMin: 0,
    peakMax: 0,
    loading: true,
  }),

  computed: {
    ...mapState("rp", [
      "projectStatusFilter",
      "projectTemplatesFilter",
      "projectsFilter",
      "customersFilter",
      "employeeTasksFilter",
      "employeeManagersFilter",
      "statusFilter",
      "scheduledFilter",
      "startDate",
      "endDate",
    ]),
  },

  methods: {
    fetchData() {
      let params = {};
      if (this.projectStatusFilter.length > 0) {
        params["_projects_status_filter"] = this.projectStatusFilter.join(",");
      }
      if (this.projectTemplatesFilter.length > 0) {
        params["_projects_templates_filter"] =
          this.projectTemplatesFilter.join(",");
      }
      if (this.projectsFilter.length > 0) {
        params["_projects_filter"] = this.projectsFilter.join(",");
      }
      if (this.customersFilter.length > 0) {
        params["_customers_filter"] = this.customersFilter.join(",");
      }
      if (this.employeeTasksFilter.length > 0) {
        params["_employee_tasks_filter"] = this.employeeTasksFilter.join(",");
      }
      if (this.employeeManagersFilter.length > 0) {
        params["_employee_managers_filter"] =
          this.employeeManagersFilter.join(",");
      }
      if (this.statusFilter) {
        params.status = this.statusFilter;
      }
      if (this.scheduledFilter) {
        params.scheduled = this.scheduledFilter;
      }
      if (this.startDate) {
        params.from = this.startDate.format("YYYY-MM-DD");
      }
      if (this.endDate) {
        params.to = this.endDate.format("YYYY-MM-DD");
      }
      return this.$api.http.get("api/rp/projects/workloads", { params });
    },

    async reload() {
      this.loading = true;
      const { data } = await this.fetchData();
      this.peakMin = data.peak_min;
      this.peakMax = data.peak_max;
      this.workloads = data.workloads;
      this.loading = false;
    },

    showDayProjectsDialog(workload) {
      this.$refs.dayProjectsDialog.open(workload);
    },
  },
};
</script>

<style lang="scss">
.workloads-projects {
  width: 100%;
  min-height: 140px;
  background: var(--v-primary-darken1);
  position: relative;
  overflow: visible;
  padding: 40px 20px 20px;

  .workloads-chart {
    height: 100px;
    overflow: visible;
    white-space: nowrap;
    position: relative;
    width: 100%;
  }

  .v-progress-circular {
    position: absolute;
    left: 50%;
    top: 50%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
  }

  .no-workloads {
    color: white;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
}
</style>
