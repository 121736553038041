export const TASKS = {
  ABSENCE_REQUEST: "absence_request",
  ABSENCE_COMATIC: "absence_comatic",
  ABSENCE_COMATIC_DELETE: "absence_comatic_delete",
  HOLIDAY: "holiday",
  VACATION: "vacation",
};

export default class AdminTaskService {
  url = "api/admin/tasks";

  constructor(api) {
    this.api = api;
  }

  getLink() {
    return this.url;
  }

  getTasks() {
    return this.api.http.get(this.url);
  }

  // Simple Tasks can be completed directly in the admin task view.
  // Complex Tasks will usually be completed through the backend.
  completeTask(taskId) {
    return this.api.http.put(`${this.url}/complete/${taskId}`);
  }

  getAbsenceRequests(tasks) {
    const requests = [];
    tasks.forEach((t) => {
      if (t.type === TASKS.ABSENCE_REQUEST) {
        requests.push(t);
      }
    });
    return requests;
  }
}
