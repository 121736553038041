<template>
  <v-app-bar
    app
    dark
    elevation="0"
    v-if="isLoggedIn && !isFullscreen"
    class="primary darken-1"
  >
    <v-app-bar-nav-icon
      v-if="$vuetify.breakpoint.mdAndDown"
      @click.stop="updateNavigationDrawer($vuetify.breakpoint)"
    />
    <v-spacer></v-spacer>

    <v-tooltip bottom v-if="isManagerOrBackoffice">
      <template v-slot:activator="{ on }">
        <v-btn icon :to="'/admin/tasks'" v-on="on" class="mr-2">
          <v-badge
            v-if="adminTasks.length > 0"
            color="error"
            :content="adminTasks.length"
          >
            <v-icon>task</v-icon>
          </v-badge>
          <v-icon v-else disabled>task</v-icon>
        </v-btn>
      </template>
      <span>Admin Aufgaben</span>
    </v-tooltip>

    <v-tooltip bottom>
      <template v-slot:activator="{ on }">
        <v-btn icon :to="'/syncs'" v-on="on" class="mr-2">
          <v-icon>sync</v-icon>
        </v-btn>
      </template>
      <span>Syncs</span>
    </v-tooltip>

    <v-tooltip bottom>
      <template v-slot:activator="{ on }">
        <v-btn icon @click="logout" v-on="on" class="mr-2">
          <v-icon>exit_to_app</v-icon>
        </v-btn>
      </template>
      <span>Logout</span>
    </v-tooltip>

    <img
      :src="user.employee.avatar"
      alt="Avatar"
      class="avatar"
      v-if="user.employee && user.employee.avatar"
    />
    <div class="avatar" v-else-if="user.employee && user.employee.acronym">
      {{ user.employee.acronym.substring(0, 2) }}
    </div>
    <div class="avatar" v-else-if="user.employee.email">
      {{ user.employee.email.substring(0, 2) }}
    </div>
    <div v-else class="avatar">{{ user.username.substring(0, 2) }}</div>
  </v-app-bar>
</template>

<script>
import { mapGetters, mapMutations, mapState } from "vuex";

export default {
  computed: {
    ...mapState("user", ["user"]),
    ...mapState("app", ["adminTasks"]),
    ...mapGetters("user", ["isLoggedIn", "isManagerOrBackoffice"]),

    isFullscreen() {
      return this.$route.meta.isFullscreen ?? false;
    },
  },

  methods: {
    ...mapMutations("app", ["updateNavigationDrawer"]),
    logout() {
      this.$api.logout();
    },
  },
};
</script>

<style lang="scss" scoped>
.avatar {
  width: 42px;
  height: 42px;
  background: var(--v-accent-base);
  border-radius: 50%;
  line-height: 42px;
  text-align: center;
  font-size: 18px;
  text-transform: uppercase;
}

.no-tasks {
  padding: 16px;
}
</style>
