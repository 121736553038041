<template>
  <v-snackbar
    :value="open"
    :bottom="true"
    :color="color"
    :multi-line="false"
    :center="true"
  >
    <v-row>
      <v-col class="pb-0 pt-2">{{ sMessage }}</v-col>
      <v-col class="pb-0 pt-1" cols="auto">
        <v-btn dark text fab @click="forceClose()" x-small>
          <v-icon>close</v-icon>
        </v-btn>
      </v-col>
    </v-row>
  </v-snackbar>
</template>

<script>
import { mapState, mapMutations } from "vuex";

export default {
  data: () => ({
    timeoutTime: 0,
    timeoutInterval: null,
  }),

  watch: {
    /**
     * Close snackbar and update state
     */
    open(value) {
      if (value) {
        this.timeoutTime = 0;
      }
    },
  },

  computed: {
    ...mapState("snackbar", ["open", "color", "sMessage"]),
  },

  created() {
    this.timeoutInterval = setInterval(() => {
      if (this.timeoutTime >= 8) {
        this.timeoutTime = 0;
        if (this.open) {
          this.closeSnackbar();
        }
        return;
      }
      this.timeoutTime++;
    }, 1000);
  },

  methods: {
    ...mapMutations("snackbar", ["closeSnackbar"]),

    /**
     * Close snackbar and clear timer
     */
    forceClose() {
      this.closeSnackbar();
      this.timeoutTime = 0;
    },
  },
};
</script>
