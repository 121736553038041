import Vue from "vue";
import VueRouter from "vue-router";
import routes from "./routes";
import store from "../store/index";

Vue.use(VueRouter);

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeResolve();

// Validate meta fields (authentication rules)
router.beforeEach(async (to, from, next) => {
  // Check if authentication is required
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (store.getters["user/isLoggedIn"]) {
      const user = await Vue.prototype.$api.getUser();
      if (user) {
        const employee = user.employee;
        if (
          to.matched.some((record) => record.meta.requiresManagerOrBackoffice)
        ) {
          if (
            employee &&
            (employee.is_admin || employee.is_manager || employee.is_backoffice)
          ) {
            return next();
          } else {
            return next("/no-access");
          }
        } else if (to.matched.some((record) => record.meta.requiresManager)) {
          if (employee && (employee.is_admin || employee.is_manager)) {
            return next();
          } else {
            return next("/no-access");
          }
        } else if (
          to.matched.some((record) => record.meta.requiresBackoffice)
        ) {
          if (employee && (employee.is_admin || employee.is_backoffice)) {
            return next();
          } else {
            return next("/no-access");
          }
        } else {
          return next();
        }
      }
    }
    return next("/login");
  }

  // Check if a redirection is expected
  if (to.matched.some((record) => record.meta.redirectAfterAuth)) {
    if (store.getters["user/isLoggedIn"]) {
      return next("/");
    }
  }
  return next();
});

export default router;
