<template>
  <v-container fluid>
    <v-card class="tt-card">
      <v-card-title class="header" style="position: relative">
        <div class="ml-4 grow">Einstellungen</div>
      </v-card-title>

      <v-card-text class="d-flex">
        <v-navigation-drawer permanent>
          <v-list nav class="py-2">
            <v-list-item link @click="view = 'holiday'">
              <v-list-item-content>
                <v-list-item-title>Feiertage</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item link @click="view = 'support'">
              <v-list-item-content>
                <v-list-item-title>Support</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-navigation-drawer>

        <v-container class="py-4 px-4" v-if="view === 'holiday'">
          <h2 class="text-h4 mb-8">Feiertage</h2>

          <div class="flex mb-8">
            <v-btn
              @click="prevYear"
              outlined
              color="primary"
              :style="{
                borderTopRightRadius: 0,
                borderBottomRightRadius: 0,
                minWidth: 'none',
              }"
            >
              <v-icon>arrow_left</v-icon>
            </v-btn>
            <div
              class="text-h5 mx-4 black--text"
              :style="{ display: 'inline-block', verticalAlign: 'bottom' }"
            >
              {{ selectedYear }}
            </div>
            <v-btn
              @click="nextYear"
              outlined
              color="primary"
              :style="{
                borderTopLeftRadius: 0,
                borderBottomLeftRadius: 0,
              }"
            >
              <v-icon>arrow_right</v-icon>
            </v-btn>
            <v-btn
              class="ml-8"
              color="primary"
              v-if="!holidayEdit"
              @click="setEdit"
            >
              Bearbeiten
            </v-btn>
            <v-btn
              color="primary"
              v-if="holidayEdit"
              outlined
              @click="addHoliday()"
              class="ml-8"
            >
              <v-icon left>add</v-icon>
              Feiertag hinzufügen
            </v-btn>
          </div>

          <v-row
            v-for="(holiday, index) in holidays"
            :key="index"
            class="items-center"
            :style="{ alignItems: 'center' }"
          >
            <v-col cols="auto" class="py-0">
              <date-input
                label="Datum"
                v-model="holiday.date"
                :readonly="!holidayEdit"
                v-if="holidayEdit"
              ></date-input>
              <div v-else class="text-h5 mb-2">
                {{ displayDate(holiday.date) }}
              </div>
            </v-col>
            <v-col cols="6" class="py-0">
              <text-input
                label="Bezeichnung"
                v-model="holiday.name"
                v-if="holidayEdit"
              ></text-input>
              <div v-else class="text-h5 mb-2">
                {{ holiday.name }}
              </div>
            </v-col>
            <v-col v-if="holidayEdit" @click="removeHoliday(holiday, index)">
              <v-btn text>
                <v-icon>delete</v-icon>
              </v-btn>
            </v-col>
          </v-row>

          <v-row class="py-4" v-if="holidayEdit">
            <v-col>
              <v-btn color="primary" text @click="cancel">Abbrechen</v-btn>
              <v-btn
                color="primary"
                v-if="holidayEdit"
                @click="saveHolidays()"
                class="ml-8"
              >
                Speichern
              </v-btn>
            </v-col>
          </v-row>
        </v-container>

        <v-container class="py-4 px-4" v-else-if="view === 'support'">
          <h2 class="text-h4 mb-8">Support</h2>

          <v-btn
            v-if="!supportEdit"
            @click="setSupportEdit"
            class="mb-8"
            color="primary"
          >
            Bearbeiten
          </v-btn>

          <v-row>
            <v-col sm="6" md="2" class="py-0">
              <number-input
                v-if="supportEdit"
                label="Wochentag"
                suffix="CHF"
                v-model="supportSettings.weekday"
              ></number-input>
              <div v-else>
                <div class="text-subtitle-1">Wochentag</div>
                <div class="text-h5 black--text">
                  {{ supportSettings.weekday }} CHF
                </div>
              </div>
            </v-col>
          </v-row>
          <v-row>
            <v-col sm="6" md="2" class="py-0">
              <number-input
                v-if="supportEdit"
                label="Wochenende"
                suffix="CHF"
                v-model="supportSettings.weekend"
              ></number-input>
              <div v-else>
                <div class="text-subtitle-1">Wochenende</div>
                <div class="text-h5 black--text">
                  {{ supportSettings.weekend }} CHF
                </div>
              </div>
            </v-col>
          </v-row>
          <v-row>
            <v-col sm="6" md="2" class="py-0">
              <number-input
                v-if="supportEdit"
                label="Feiertag"
                suffix="CHF"
                v-model="supportSettings.holiday"
              ></number-input>
              <div v-else>
                <div class="text-subtitle-1">Feiertag</div>
                <div class="text-h5 black--text">
                  {{ supportSettings.holiday }} CHF
                </div>
              </div>
            </v-col>
          </v-row>

          <v-row class="py-4" v-if="supportEdit">
            <v-col>
              <v-btn color="primary" text @click="cancelSupportEdit">
                Abbrechen
              </v-btn>
              <v-btn
                color="primary"
                v-if="supportEdit"
                @click="saveSupport()"
                class="ml-8"
              >
                Speichern
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import moment from "moment";
import DateInput from "@/components/forms/common/DateInput";
import TextInput from "@/components/forms/common/TextInput";
import NumberInput from "@/components/forms/common/NumberInput.vue";

export default {
  components: {
    DateInput,
    TextInput,
    NumberInput,
  },

  data() {
    return {
      view: "holiday",
      holidayEdit: false,
      selectedYear: moment().format("YYYY"),
      years: [moment().format("YYYY")],
      holidays: [],
      preEdit: null,
      supportEdit: false,
      supportSettings: {
        weekday: 0,
        weekend: 0,
        holiday: 0,
      },
    };
  },

  mounted() {
    this.fetchHolidays();
    this.fetchSupport();
  },

  methods: {
    setEdit() {
      this.preEdit = [...this.holidays];
      this.holidayEdit = true;
    },

    cancel() {
      this.holidays = [...this.preEdit];
      this.preEdit = null;
      this.holidayEdit = false;
    },

    setSupportEdit() {
      this.preSupportEdit = { ...this.supportSettings };
      this.supportEdit = true;
    },

    cancelSupportEdit() {
      this.supportSettings = { ...this.preSupportEdit };
      this.preSupportEdit = null;
      this.supportEdit = false;
    },

    displayDate(d) {
      return moment(d).format("DD.MM.YYYY");
    },

    prevYear() {
      this.selectedYear = (parseInt(this.selectedYear) - 1).toString();
      this.fetchHolidays();
    },

    nextYear() {
      this.selectedYear = (parseInt(this.selectedYear) + 1).toString();
      this.fetchHolidays();
    },

    addHoliday() {
      this.holidays.push({
        name: "",
        date: null,
      });
    },

    removeHoliday(holiday, index) {
      if (holiday && holiday.id) {
        this.$holidayService
          .deleteHoliday(holiday.id)
          .then(() => {
            this.$toast.open("Erfolgreich gelöscht.");
            this.fetchHolidays();
          })
          .catch(() => {
            this.$toast.open({
              message: "Konnte nicht gelöscht werden.",
              type: "error",
            });
          });
      } else {
        this.holidays.splice(index, 1);
      }
    },

    fetchHolidays() {
      this.holidays = [];
      this.preEdit = [];
      this.$holidayService
        .getHolidaysByYear(this.selectedYear)
        .then((res) => {
          if (res.data.length > 0) {
            this.holidayEdit = false;
            this.holidays = res.data;
          } else {
            this.holidayEdit = true;
            this.holidays = [];
            this.addHoliday();
          }
        })
        .catch((err) => console.error(err));
    },

    saveHolidays() {
      this.$holidayService
        .updateHolidays(this.holidays)
        .then(() => this.$toast.open("Erfolgreich gespeichert"))
        .catch(() =>
          this.$toast.open({
            message: "Etwas ist schiefgelaufen",
            type: "error",
          })
        )
        .finally(() => {
          this.fetchHolidays();
        });
    },

    saveSupport() {
      const payload = {
        key: "support.compensation",
        value: this.supportSettings,
      };
      this.$settingService
        .setSettings(payload)
        .then(() => {
          this.supportEdit = false;
          this.$toast.open(
            "Supportkompensationen wurden erfolgreich aktualisiert"
          );
        })
        .catch(() =>
          this.$toast.open({
            message: "Supportkompensationen konnten nicht gespeichert werden.",
            type: "error",
          })
        );
    },

    fetchSupport() {
      this.$settingService
        .getSettings("support.compensation")
        .then((res) => {
          if (res.data) {
            this.supportSettings = res.data;
          }
        })
        .catch((err) => console.error(err));
    },
  },
};
</script>

<style lang="scss" scoped>
.year {
  border: 1px solid black;
  padding: 1px 8px;
}
</style>
