<template>
  <div class="workload-employee">
    <div class="names-column">
      <div class="employee-name" v-for="employee in data" :key="employee.id">
        <div>{{ employee.name }}</div>
      </div>
    </div>
    <div class="workloads-column">
      <div
        class="employee-workloads"
        v-for="(employee, index) in data"
        :key="employee.id"
      >
        <workload-item
          v-for="workload in employee.workloads"
          :key="workload.date"
          :workload="workload"
          :employee="employee"
          :total="employee.workloads.length"
          :peak-min="employee.peak_min"
          :peak-max="employee.peak_max"
          :show-dates="index === 0"
          @showDayEmployees="showDayEmployeesDialog"
        />
      </div>
    </div>
    <day-employees-dialog ref="dayEmployeesDialog" />
  </div>
</template>

<script>
import WorkloadItem from "@/components/rp/employees/WorkloadItem";
import DayEmployeesDialog from "@/components/rp/dialogs/DayEmployeesDialog.vue";

export default {
  components: {
    WorkloadItem,
    DayEmployeesDialog,
  },

  props: {
    data: {
      required: true,
    },
  },

  methods: {
    showDayEmployeesDialog(workload, employee) {
      this.$refs.dayEmployeesDialog.open(workload, employee);
    },
  },
};
</script>

<style lang="scss" scoped>
.workload-employee {
  display: flex;
  align-items: center;

  .names-column {
    flex-basis: auto;
    padding-right: 30px;

    .employee-name {
      color: white;
      height: 40px;
      align-items: center;
      display: flex;
      margin-bottom: 5px;
    }
  }

  .workloads-column {
    flex: 1 1 auto;
    color: white;

    .employee-workloads {
      height: 40px;
      width: 100%;
      margin-bottom: 5px;
    }
  }
}
</style>
