<template>
  <v-text-field
    :label="getLabel"
    :value="value"
    @input="edit"
    type="number"
    :suffix="suffix"
    :rules="getNumberRule"
    :disabled="readonly"
    :readonly="readonly"
  ></v-text-field>
</template>

<script>
export default {
  props: {
    value: {
      required: true,
    },
    label: {
      required: true,
      type: String,
    },
    required: {
      type: Boolean,
      default: false,
    },
    suffix: {
      type: String,
      default: "",
    },
    min: {
      type: Number,
      default: 0,
    },
    max: {
      type: Number,
      default: 100,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    getLabel() {
      return this.required ? `${this.label} *` : this.label;
    },

    getNumberRule() {
      return this.required
        ? [
            (v) =>
              (!!v && parseInt(v) >= this.min && parseInt(v) <= this.max) ||
              `Die Zahl muss zwischen ${this.min} und ${this.max} liegen`,
          ]
        : [];
    },
  },

  methods: {
    edit(value) {
      this.$emit("input", value);
    },
  },
};
</script>

<style lang="scss" scoped>
.required {
  font-weight: 500;
  color: black;
}
</style>
