<template>
  <v-form ref="form" v-model="valid">
    <h3>Anstellungsinfo</h3>
    <v-row>
      <v-col cols="6" md="2">
        <date-input
          v-model="formData.start_date"
          label="Anstellungsbeginn"
          :readonly="!edit"
        ></date-input>
      </v-col>
      <v-col cols="6" md="2">
        <date-input
          v-model="formData.end_date"
          label="Anstellungsende"
          :readonly="!edit"
        ></date-input>
      </v-col>
    </v-row>

    <h3>Rollen</h3>
    <v-row class="mb-4">
      <v-col cols="auto py-0">
        <v-checkbox
          class="mr-4"
          v-model="formData.is_plannable"
          label="Relevant für Planung"
          color="primary"
          :disabled="!edit"
          hide-details
        ></v-checkbox>
      </v-col>
      <v-col cols="auto py-0">
        <v-checkbox
          class="mr-4"
          v-model="formData.is_manager"
          label="Manager"
          color="primary"
          :disabled="!edit"
          hide-details
        ></v-checkbox>
      </v-col>
      <v-col cols="auto py-0">
        <v-checkbox
          class="mr-4"
          v-model="formData.is_backoffice"
          label="Backoffice"
          color="primary"
          :disabled="!edit"
          hide-details
        ></v-checkbox>
      </v-col>
      <v-col cols="auto py-0">
        <v-checkbox
          class="mr-4"
          v-model="formData.is_support"
          label="Support"
          color="primary"
          :disabled="!edit"
          hide-details
        ></v-checkbox>
      </v-col>
    </v-row>

    <v-row class="action" v-if="edit">
      <v-col cols="auto">
        <v-btn class="cancel" text @click="cancel">Abbrechen</v-btn>
      </v-col>
      <v-col cols="auto">
        <v-btn class="commit" color="primary" :disabled="!valid" @click="save">
          Speichern
        </v-btn>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import DateInput from "@/components/forms/common/DateInput";

export default {
  components: {
    DateInput,
  },

  props: {
    employee: {
      required: true,
    },
    edit: {
      required: true,
    },
  },

  data() {
    return {
      valid: false,
      formData: {
        start_date: null,
        end_date: null,
        is_plannable: false,
        is_manager: false,
        is_backoffice: false,
        is_support: false,
      },
    };
  },

  mounted() {
    this.setEmployee(this.employee);
  },

  watch: {
    employee(value) {
      this.setEmployee(value);
    },
  },

  methods: {
    setEmployee(e) {
      this.formData = {
        start_date: e.start_date,
        end_date: e.end_date,
        is_plannable: e.is_plannable,
        is_manager: e.is_manager,
        is_backoffice: e.is_backoffice,
        is_support: e.is_support,
      };
    },

    save() {
      this.$employeeService
        .updateEmployee({
          ...this.formData,
          id: this.employee.id,
        })
        .then(() => {
          this.$toast.open("Änderungen wurden gespeichert");
          this.$emit("saved");
        });
    },

    cancel() {
      this.setEmployee(this.employee);
      this.$emit("cancel");
    },
  },
};
</script>

<style lang="scss" scoped>
.action {
  justify-content: end;

  .cancel {
    width: 100%;
  }

  .commit {
    width: 100%;
  }
}
</style>
