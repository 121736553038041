import Dashboard from "../views/Dashboard.vue";
import Login from "../views/Login.vue";
import Calendar from "../views/Calendar.vue";
import ProjectList from "../views/projects/List.vue";
import ProjectEdit from "../views/projects/Edit.vue";
import EmployeeList from "../views/employees/List.vue";
import EmployeeEdit from "../views/employees/Edit.vue";
import TimeTrack from "../views/TimeTrack.vue";
import Syncs from "../views/Syncs.vue";
import NoAccess from "../views/NoAccess.vue";
import NotFound from "../views/NotFound.vue";
import AbsenceRequests from "../views/admin/AbsenceRequests";
import AdminTasks from "../views/admin/AdminTasks";
import AdminEmployees from "../views/admin/AdminEmployees";
import ResourcePlanning from "../views/planning/ResourcePlanning";
import SupportPlanning from "../views/planning/SupportPlanning";
import AbsencePlanning from "../views/planning/AbsencePlanning";
import Settings from "../views/admin/Settings";

const routes = [
  {
    path: "/login",
    name: "login",
    component: Login,
    meta: {
      redirectAfterAuth: true,
      isFullscreen: true,
    },
  },
  {
    path: "/",
    name: "dashboard",
    component: Dashboard,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/admin/absence-requests",
    name: "absence-requests",
    component: AbsenceRequests,
    meta: {
      requiresAuth: true,
      requiresManager: true,
    },
  },
  {
    path: "/admin/tasks",
    name: "tasks",
    component: AdminTasks,
    meta: {
      requiresAuth: true,
      requiresManagerOrBackoffice: true,
    },
  },
  {
    path: "/admin/employees",
    name: "employees",
    component: AdminEmployees,
    meta: {
      requiresAuth: true,
      requiresManagerOrBackoffice: true,
    },
  },
  {
    path: "/admin/settings",
    name: "settings",
    component: Settings,
    meta: {
      requiresAuth: true,
      requiresBackoffice: true,
    },
  },
  {
    path: "/resource-planning",
    name: "resource-planning",
    component: ResourcePlanning,
    meta: {
      requiresAuth: true,
      isFullscreen: true,
    },
  },
  {
    path: "/absences",
    name: "absences",
    component: AbsencePlanning,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/support",
    name: "support",
    component: SupportPlanning,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/calendar",
    name: "calendar",
    component: Calendar,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/projects",
    name: "projects-list",
    component: ProjectList,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/projects/:id",
    name: "projects-edit",
    component: ProjectEdit,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/employees",
    name: "employees-list",
    component: EmployeeList,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/employees/:id",
    name: "employees-edit",
    component: EmployeeEdit,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/time-track",
    name: "time-track",
    component: TimeTrack,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/syncs",
    name: "syncs",
    component: Syncs,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/no-access",
    name: "no-access",
    component: NoAccess,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "*",
    name: "not-found",
    component: NotFound,
    meta: {
      requiresAuth: true,
    },
  },
];

export default routes;
