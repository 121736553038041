<template>
  <div class="time-picker">
    <v-menu
      ref="menu"
      v-model="open"
      :close-on-content-click="false"
      :nudge-right="40"
      :return-value.sync="time"
      transition="scale-transition"
      offset-y
      max-width="290px"
      min-width="290px"
    >
      <template v-slot:activator="{ on }">
        <v-text-field
          v-model="time"
          :label="label"
          :append-icon="appendIcon ? 'access_time' : null"
          :outlined="outlined"
          readonly
          v-on="on"
          :clearable="isNullable"
        >
          <template v-slot:label>
            <span :class="{ required: isRequired }"
              >{{ label }}{{ isRequired ? " *" : "" }}</span
            >
          </template>
        </v-text-field>
      </template>
      <v-time-picker
        v-if="open"
        v-model="time"
        full-width
        format="24hr"
        @click:minute="$refs.menu.save(time)"
      ></v-time-picker>
    </v-menu>
  </div>
</template>

<script>
import moment from "moment";

export default {
  props: {
    value: {
      required: true,
    },
    label: {
      default: "Zeit",
      type: String,
    },
    appendIcon: {
      type: Boolean,
      default: true,
    },
    outlined: {
      default: false,
    },
    isNullable: {
      type: Boolean,
      defaullt: false,
    },
    isRequired: {
      type: Boolean,
      default: false,
    },
  },

  data: () => ({
    open: false,
  }),

  computed: {
    time: {
      get() {
        return this.value ? moment(this.value).format("HH:mm:ss") : null;
      },
      set(value) {
        if (value) {
          this.$emit("input", moment(value, "HH:mm:ss"));
        } else {
          this.$emit("input", null);
        }
      },
    },

    timeFormatted() {
      return this.value ? moment(this.value).format("HH:mm") : "-";
    },
  },

  methods: {
    reset() {
      this.$emit("input", null);
    },
  },
};
</script>

<style lang="scss" scoped>
.time-picker {
  display: flex;
}

.clear {
  min-width: 36px !important;
  margin-left: 8px !important;
  padding: 0 8px !important;
}

.required {
  font-weight: 500;
  color: black;
}
</style>
