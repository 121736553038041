<template>
  <div class="date-picker">
    <v-menu
      v-model="open"
      :close-on-content-click="false"
      :nudge-right="40"
      transition="scale-transition"
      offset-y
      min-width="290px"
    >
      <template v-slot:activator="{ on }">
        <v-text-field
          v-model="dateFormatted"
          :label="label"
          append-icon="event"
          :outlined="outlined"
          :dense="dense"
          :hide-details="hideDetails"
          readonly
          v-on="on"
          @click:append="openPicker"
          :disabled="disabled"
          :rules="rules"
        >
          <template v-slot:label>
            <span :class="{ required: isRequired }"
              >{{ label }}{{ isRequired ? " *" : "" }}</span
            >
          </template>
        </v-text-field>
      </template>
      <v-date-picker
        v-model="date"
        :type="type"
        first-day-of-week="1"
        @input="open = false"
        :rules="rules"
        :allowed-dates="allowedDates"
      ></v-date-picker>
    </v-menu>
  </div>
</template>

<script>
import moment from "moment";

export default {
  props: {
    value: {
      required: true,
    },
    label: {
      default: "Datum",
      type: String,
    },
    outlined: {
      default: false,
    },
    dense: {
      default: false,
    },
    hideDetails: {
      default: false,
    },
    format: {
      type: String,
      default: "DD.MM.YYYY",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: "date",
    },
    rules: {
      type: Array,
      default: () => [],
    },
    allowedDates: {
      type: Function,
      default: null,
    },
    isRequired: {
      type: Boolean,
      default: false,
    },
  },

  data: () => ({
    open: false,
  }),

  computed: {
    date: {
      get() {
        return this.value ? moment(this.value).format("YYYY-MM-DD") : null;
      },
      set(value) {
        this.$emit("input", moment(value));
      },
    },

    dateFormatted() {
      return this.value ? moment(this.value).format(this.format) : "-";
    },
  },

  methods: {
    openPicker() {
      this.open = !this.open;
    },
  },
};
</script>

<style>
.required {
  font-weight: 500;
  color: black;
}
</style>
