<template>
  <v-card class="tt-card">
    <v-card-title class="header">
      <div class="grow">{{ getTitle }}</div>
      <v-btn
        v-if="mode === 'edit'"
        class="action"
        @click="mode = 'create'"
        :small="!$vuetify.breakpoint.mdAndUp"
        :fab="!$vuetify.breakpoint.mdAndUp"
      >
        <v-icon :left="$vuetify.breakpoint.mdAndUp">add</v-icon>
        {{ $vuetify.breakpoint.mdAndUp ? "Neuen Eintrag" : "" }}
      </v-btn>
    </v-card-title>
    <v-card-text class="content">
      <absence-request-form
        :mode="mode"
        :input="selectedAbsence"
        :duration="durationSelection"
        @saved="save"
        @cancel="cancel"
      ></absence-request-form>
    </v-card-text>
  </v-card>
</template>

<script>
import AbsenceRequestForm from "@/components/forms/AbsenceRequestForm";

export default {
  components: {
    AbsenceRequestForm,
  },

  props: {
    selectedAbsence: {
      default: null,
    },
    durationSelection: {
      default: null,
    },
  },

  computed: {
    getTitle() {
      if (this.mode === "create") {
        return "Absenz beantragen";
      } else if (this.mode === "edit") {
        return "Absenz bearbeiten";
      }
      return "";
    },
  },

  mounted() {
    if (this.selectedAbsence) {
      this.mode = "edit";
    }
  },

  data() {
    return {
      mode: "create", // Modes available: create, edit
    };
  },

  watch: {
    selectedAbsence(value) {
      if (value) {
        this.mode = "edit";
      }
    },
  },

  methods: {
    save() {
      this.mode = "create";
      this.$emit("saved");
    },

    cancel() {
      this.mode = "create";
      this.$emit("cancel");
    },
  },
};
</script>
