<template>
  <v-dialog v-model="isOpen" scrollable persistent width="1024">
    <v-form v-model="valid" v-if="projects.length > 0" @submit.prevent="save()">
      <v-card>
        <v-card-title class="primary white--text">
          <span v-if="mode === 'create'">Aufgabe erfassen</span>
          <span v-if="mode === 'edit'">Aufgabe bearbeiten</span>
        </v-card-title>

        <v-card-text v-if="apiLoading" class="mt-3">
          <v-progress-linear indeterminate color="primary"></v-progress-linear>
        </v-card-text>
        <v-card-text v-else class="mt-3">
          <v-row :class="{ reverse: $vuetify.breakpoint.smAndDown }">
            <v-col cols="12" md="5">
              <project-select
                v-model="selectedProjectId"
                label="Projekt"
                :items="projects"
                :required="true"
              ></project-select>
              <v-select
                v-model="selectedBudgetId"
                :items="budgets"
                item-text="name"
                item-value="id"
                label="Budget"
                :rules="textRules"
              ></v-select>
              <v-select
                clearable
                v-model="task.employee_id"
                :items="employees"
                item-text="name"
                item-value="id"
                label="Zuständiger Mitarbeiter"
              ></v-select>
              <v-select
                v-model="task.statusId"
                :items="this.configs.task_statuses"
                item-text="name"
                item-value="remote_id"
                label="Status"
                :rules="textRules"
              ></v-select>
              <v-select
                v-model="task.remote_category_id"
                :items="availableActivities"
                item-text="name"
                item-value="id"
                label="Kategorie"
                :rules="textRules"
              ></v-select>
            </v-col>
            <v-col cols="12" md="7">
              <v-text-field
                v-model="task.name"
                label="Titel"
                type="text"
                :maxlength="50"
                :rules="textRules"
              ></v-text-field>
              <v-textarea
                v-model="task.description"
                label="Beschreibung"
                type="text"
                :maxlength="255"
                :rows="1"
              ></v-textarea>
              <v-row class="py-0 mb-0">
                <v-col class="py-0">
                  <date-input
                    v-model="task.starts_at"
                    label="Start"
                    :required="true"
                  ></date-input>
                </v-col>
                <v-col class="py-0">
                  <date-input
                    v-model="task.ends_at"
                    label="Ende"
                    :required="true"
                  ></date-input>
                </v-col>
                <v-col class="py-0" cols="auto">
                  <v-chip label dark color="info" class="mt-4">
                    {{ duration }} Tage
                  </v-chip>
                </v-col>
              </v-row>
              <v-row>
                <v-col class="py-0">
                  <v-text-field
                    v-model="task.time_expected"
                    label="Geplant (Std.)"
                    type="number"
                    :max="10000"
                    :rules="textRules"
                  ></v-text-field>
                </v-col>
                <v-col class="py-0">
                  <v-text-field
                    v-model="task.time_used"
                    label="Bereits erfasst (Std.)"
                    type="number"
                    readonly
                    outlined
                    dense
                    class="mt-2"
                  ></v-text-field>
                </v-col>
              </v-row>
              <h5>Budget Übersicht</h5>
              <v-row>
                <v-col class="py-0">
                  <budget-time-diagram
                    :tracked="timeTracked"
                    :planned="timePlanned"
                    :offered="timeOffered"
                  ></budget-time-diagram>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="default" text @click="close()">Abbrechen</v-btn>
          <v-btn
            color="primary"
            type="submit"
            :disabled="!valid || !task.starts_at || !task.ends_at"
          >
            Speichern
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import moment from "moment";
import { mapState, mapMutations } from "vuex";
import DateInput from "@/components/forms/common/DateInput";
import BudgetTimeDiagram from "@/components/diagram/BudgetTimeDiagram";
import ProjectSelect from "@/components/forms/common/ProjectSelect";

export default {
  components: {
    DateInput,
    BudgetTimeDiagram,
    ProjectSelect,
  },

  data: () => ({
    isOpen: false,
    apiLoading: false,
    mode: "create",
    valid: false,
    projects: [],
    selectedProjectId: null,
    selectedBudgetId: null,
    taskId: null,
    task: {
      status: "created",
      time_expected: 0,
      time_used: 0,
      employee_id: null,
      starts_at: null,
      ends_at: null,
    },
    timeExpectedStart: 0,
    textRules: [(v) => v != null || v != undefined || "Muss ausgefüllt sein"],
    numericRules: [(v) => parseInt(v) > 0 || "Muss eine Nummer > 0 sein"],
    duration: 0,
  }),

  computed: {
    ...mapState("app", ["configs", "activities"]),

    budgets() {
      if (this.selectedProjectId && this.projects) {
        return (
          this.projects.find((project) => project.id === this.selectedProjectId)
            ?.budgets ?? []
        );
      }
      return [];
    },

    // TODO TT-28 Replace magic number with ActivityIdRoles
    availableActivities() {
      const items = [];
      this.activities.forEach((a) => {
        if (a.project_time_coverage_type === 0) {
          items.push({
            id: a.remote_id,
            name: a.description,
          });
        }
      });
      return items;
    },

    employees() {
      return this.configs.employees.filter((employee) => employee.is_plannable);
    },

    project() {
      return this.projects.find((p) => p.id === this.selectedProjectId);
    },

    budget() {
      if (this.project) {
        return this.project.budgets.find((b) => b.id === this.selectedBudgetId);
      }
      return null;
    },

    timeTracked() {
      if (this.budget) {
        return this.budget.time_used;
      }
      return 0;
    },

    timePlanned() {
      if (this.budget) {
        const planed = parseFloat(this.task.time_expected);
        const used = parseFloat(this.task.time_used);

        if (isNaN(planed) || isNaN(used)) {
          return this.budget.time_planed;
        }

        let tmp = this.budget.time_planed - this.timeExpectedStart;
        tmp += planed;
        return tmp > 0 ? tmp : 0;
      }
      return 0;
    },

    timeOffered() {
      if (this.budget) {
        return this.budget.time_offered;
      }
      return 0;
    },
  },

  watch: {
    "task.starts_at"() {
      this.setDuration();
    },
    "task.ends_at"() {
      this.setDuration();
    },
  },

  methods: {
    ...mapMutations("snackbar", ["showErrorSnackbar"]),

    open(
      mode,
      taskId = null,
      projectId = null,
      budgetId = null,
      employeeId = null
    ) {
      this.isOpen = true;
      this.mode = mode;

      this.taskId = taskId;
      this.selectedProjectId = projectId;
      this.selectedBudgetId = budgetId;
      this.task.employee_id = employeeId;

      this.fetchData(mode);
    },

    close() {
      this.resetForm();
      this.isOpen = false;
    },

    resetForm() {
      this.taskId = null;
      this.selectedProjectId = null;
      this.selectedBudgetId = null;
      this.task = {
        status: "created",
        time_expected: 0,
        time_used: 0,
        employee_id: null,
        starts_at: null,
        ends_at: null,
      };
    },

    async fetchData(mode) {
      this.apiLoading = true;

      // Fetch task if in edit mode
      if (mode === "edit" && this.taskId) {
        const taskResponse = await this.$api.http.get(
          `api/tasks/${this.taskId}`
        );
        this.task = taskResponse.data;
        this.timeExpectedStart = taskResponse.data.time_expected;
        this.task.employee_id = taskResponse.data.employee
          ? taskResponse.data.employee.id
          : null;
        this.task.starts_at = moment(taskResponse.data.starts_at).format(
          "YYYY-MM-DD"
        );
        this.task.ends_at = moment(taskResponse.data.ends_at).format(
          "YYYY-MM-DD"
        );
      }

      let projectFilter = "";
      this.configs.project_settings.time_track.forEach((s) => {
        projectFilter += `status[]=${s}&`;
      });

      // Fetch projects.
      const response = await this.$api.http.get(
        `api/projects/list?${projectFilter}`
      );
      this.projects = response.data;

      this.apiLoading = false;
    },

    save() {
      let payload = { ...this.task };
      payload.starts_at = this.task.starts_at;
      payload.ends_at = this.task.ends_at;
      payload.budget_id = this.selectedBudgetId;

      if (this.mode === "create") {
        this.$api.http
          .post(`api/tasks`, payload)
          .then((response) => {
            this.$emit("saved", response.data);
            this.close();
          })
          .catch((error) => {
            const message = error?.message ?? "Etwas ist schiefgelaufen";
            this.showErrorSnackbar(message);
          });
      } else if (this.mode === "edit") {
        this.$api.http
          .put(`api/tasks/${this.taskId}`, payload)
          .then((response) => {
            this.$emit("saved", response.data);
            this.close();
          })
          .catch((error) => {
            const message = error.response.data.message;
            this.showErrorSnackbar(message);
          });
      }
    },

    async setDuration() {
      if (this.task.starts_at && this.task.ends_at) {
        const response = await this.$api.http(
          `api/task-duration?starts_at=${this.task.starts_at}&ends_at=${this.task.ends_at}`
        );
        this.duration = response.data.duration;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.reverse {
  flex-direction: column-reverse;
}
</style>
