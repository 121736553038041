import moment from "moment";
export default class AbsenceRequestService {
  url = "api/absences";

  constructor(api) {
    this.api = api;
  }

  getAbsence(id) {
    return this.api.http.get(`${this.url}/${id}`);
  }

  getManagerLink() {
    return `${this.url}/manager`;
  }

  getYear(start) {
    return this.api.http.get(`${this.url}/year/${start}`);
  }

  getManagerTasks() {
    return this.api.http.get(`${this.url}/manager`);
  }

  changeManagerRequestState(absenceId, state) {
    const payload = {
      manager_request_state: state,
    };
    return this.api.http.put(`${this.url}/manager/state/${absenceId}`, payload);
  }

  changeOfficeTaskState(absenceId) {
    return this.api.http.put(`${this.url}/backoffice/state/${absenceId}`);
  }

  completeDeleteTask(absenceId) {
    return this.api.http.delete(`${this.url}/backoffice/${absenceId}`);
  }

  save(payload, user) {
    return this.api.http.put(`${this.url}?employee_id=${user.id}`, payload);
  }

  parseData(year, employees) {
    const events = year.map((event) => {
      const employee = this.getEmployee(employees, event.employee_id);
      const pending =
        event.manager_request_state === "pending" ? " (beantragt)" : "";
      const end = moment(event.end).add(1, "days").format("YYYY-MM-DD");
      return {
        id: event.id,
        title: employee + pending,
        start: event.start,
        end: end,
        color: this.getEventColor(event),
      };
    });

    return events;
  }

  getEmployee(employees, id) {
    const result = employees.find((e) => e.id === id);
    if (result) {
      return result.name;
    }

    return "MA nicht gefunden";
  }

  getAbsenceType(types, typeId) {
    const type = types.find((t) => t.id == typeId);
    if (type) {
      return type.name;
    }
    return "";
  }

  getEventColor(event) {
    if (event.manager_request_state === "approved") {
      return "var(--v-primary-base)";
    }
    return "var(--v-accent-base)";
  }
}
