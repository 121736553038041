<template>
  <div class="project-status-label">
    <v-chip :color="color" label :small="small" :large="large">
      {{ text }}
    </v-chip>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  props: {
    project: {
      type: Object,
      required: true,
    },
    small: {
      type: Boolean,
      default: false,
    },
    large: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    ...mapState("app", ["configs"]),

    color() {
      if (
        this.configs.project_colors.info.find(
          (id) => id === parseInt(this.project.status)
        )
      ) {
        return "info";
      }

      if (
        this.configs.project_colors.warning.find(
          (id) => id === parseInt(this.project.status)
        )
      ) {
        return "warning";
      }

      if (
        this.configs.project_colors.success.find(
          (id) => id === parseInt(this.project.status)
        )
      ) {
        return "success";
      }

      return "error";
    },

    text() {
      const status = this.configs.project_statuses.find(
        (s) => s.remote_id === parseInt(this.project.status)
      );
      return status ? status.name : "Unbekannt";
    },
  },
};
</script>

<style lang="scss"></style>
