var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',{staticClass:"tt-card",attrs:{"color":"default","outlined":""}},[_c('v-card-title',{staticClass:"header"},[_c('div',[_vm._v("Deine Aufgaben")])]),_c('v-card-text',[_c('remote-data-table',{ref:"myTasksDatatable",staticClass:"dashboard-tasks-table",attrs:{"url":("api/tasks?employee_id=" + (_vm.user.employee.id)),"headers":_vm.headers,"sort-by":'name'},on:{"dblclick":_vm.openEditTaskDialog},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('span',{staticStyle:{"white-space":"nowrap","font-weight":"bold"}},[_vm._v(" "+_vm._s(item.name)+" ")])]}},{key:"item.budget.project.name",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"text-caption",staticStyle:{"white-space":"nowrap"}},[_vm._v(" "+_vm._s(item.budget.project.name)+" ")])]}},{key:"item.starts_at",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"text-caption",staticStyle:{"white-space":"nowrap"}},[_vm._v(" "+_vm._s(_vm._f("formatDate")(item.starts_at))+" ")])]}},{key:"item.ends_at",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"text-caption",staticStyle:{"white-space":"nowrap"}},[_vm._v(" "+_vm._s(_vm._f("formatDate")(item.ends_at))+" ")])]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('v-menu',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"x-small":"","text":"","fab":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("more_vert")])],1)]}}],null,true)},[_c('v-list',[_c('v-list-item',{on:{"click":function($event){return _vm.openCreateTimeEntryDialog(item)}}},[_vm._v(" Zeit erfassen ")]),_c('v-list-item',{on:{"click":function($event){return _vm.openEditTaskDialog(item)}}},[_vm._v(" Bearbeiten ")]),(item.status !== 'completed')?_c('v-list-item',{on:{"click":function($event){return _vm.openCompleteTaskDialog(item.id, item.name)}}},[_vm._v(" Erledigt ")]):_vm._e()],1)],1)]}}],null,true)})],1)],1),_c('time-entry-dialog',{ref:"timeEntryDialog",on:{"changed":function($event){return _vm.$emit('savedTimeEntry')}}}),_c('task-dialog',{ref:"taskDialog"}),_c('complete-dialog',{ref:"completeDialog"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }