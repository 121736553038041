var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',{staticClass:"tt-card",attrs:{"color":"default","outlined":""}},[_c('v-card-title',{staticClass:"header",staticStyle:{"position":"relative"}},[_c('div',{staticClass:"title"},[_vm._v("Absenzanfragen")])]),_c('v-card-text',[_c('remote-data-table',{ref:"absenceManagerDataTable",staticClass:"table",attrs:{"url":_vm.listUrl,"headers":_vm.headers,"sort-by":'start'},on:{"dblclick":_vm.dblclick},scopedSlots:_vm._u([{key:"item.employee_id",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-caption",staticStyle:{"white-space":"nowrap"}},[_vm._v(" "+_vm._s(_vm.getEmployeeName(item.employee_id))+" ")])]}},{key:"item.start",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-caption",staticStyle:{"white-space":"nowrap"}},[_vm._v(" "+_vm._s(_vm.formatDate(item.start))+" ")])]}},{key:"item.end",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-caption",staticStyle:{"white-space":"nowrap"}},[_vm._v(" "+_vm._s(_vm.formatDate(item.end))+" ")])]}},{key:"item.type",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-caption",staticStyle:{"white-space":"nowrap"}},[_vm._v(" "+_vm._s(_vm.getAbsenceType(item.type))+" ")])]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [(_vm.overviews.length === 0)?_c('v-progress-circular',{staticClass:"mr-6",attrs:{"indeterminate":"","color":"primary","width":3,"size":20}}):_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){return _vm.handleRequestAction(item)}}},[_c('v-icon',[_vm._v("task")])],1)]}}],null,true)})],1)],1),_c('absence-request-action-dialog',{ref:"absenceRequestActionDialog",on:{"done":_vm.refresh}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }